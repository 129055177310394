//delete this, is a commit trial
body {
	font-family: $primary-font;
	@include font-rem($font-md, $font-md + 8);
	font-weight: 400;
}

h1,
.headline1 {
	@include font-rem(28px, 24px);
	letter-spacing: 0;

	@include media-breakpoint-up(lg) {
		@include font-rem(42px, 46px);
		letter-spacing: 0;
	}
}

.headline1-large {
	@include font-rem(50px, 60px);
	letter-spacing: -1.6px;

	@include media-breakpoint-up(lg) {
		@include font-rem(73px, 73px);
		letter-spacing: -2px;
	}
}

bat-headline-default{
	&.pdp-hyper-pro{
		.headline2 {
			@include media-breakpoint-up(lg) {
				@include font-rem(30px, 40px);
			}
		}
	}

	&.pdp-hyper-core{
		.headline2 {
			color: var(--glo-2024-Black, #000);
			font-feature-settings: 'liga' off, 'clig' off;
			font-family: Mont;
			font-size: 28px;
			font-style: normal;
			font-weight: 600;
			line-height: 120%; /* 33.6px */
			@include media-breakpoint-up(md) {
				font-size: 30px;
			}
		}
	}
}

h2,
.headline2 {
	@include font-rem(24px, 28px);

	@include media-breakpoint-up(lg) {
		@include font-rem(34px, 40px);
	}
}

h3,
.headline3 {
	@include font-rem(22px, 28px);
	letter-spacing: 0;

	@include media-breakpoint-up(lg) {
		@include font-rem(26px, 30px);
		letter-spacing: 0;
	}
}

h4,
.headline4 {
	@include font-rem(18px, 28px);

	@include media-breakpoint-up(lg) {
		@include font-rem(20px, 28px);
	}
}

h5,
.headline5 {
	@include font-rem(12px, 14px);
	letter-spacing: 1.2px;

	@include media-breakpoint-up(lg) {
		@include font-rem(12px, 14px);
	}
}

h6,
.headline6,
.headline5-small {
	@include font-rem(12px, 12px);
	letter-spacing: 1.2px;
}

h1,
.headline1,
.headline1-large,
h2,
.headline2,
h3,
.headline3,
h4,
.headline4,
h5,
.headline5,
h6,
.headline6 {
	font-family: $heading-font;
	font-weight: 700;
	margin-bottom: 0;

	&.center {
		text-align: center;
	}
}

p {
	@include font-rem($font-md, $font-md + 2);
	font-weight: 400;
	margin-bottom: 5px;
	margin-top: 0;

	.sm,
	&.sm {
		@include font-rem($font-sm, $font-sm + 2);
		font-weight: 400;
		letter-spacing: -0.5px;
		margin-bottom: 5px;
	}

	.lg,
	&.lg {
		@include font-rem($font-lg, $font-lg + 2);
		font-weight: 500;
		letter-spacing: -0.7px;
		margin-bottom: 8px;
	}

	.xl,
	&.xl {
		@include font-rem($font-xlg, $font-xlg + 2);
		font-weight: 500;
		letter-spacing: 1px;
		margin-bottom: 10px;
	}

	.subtitle,
	&.subtitle {
		@include font-rem($font-lg, $font-xlg);
		font-weight: 700;
		letter-spacing: 1px;
	}
}

b {
	font-weight: 700;
}

hr {
	border-top: 1px solid $off-white;
	margin: 10px 0;
}

ol,
ul {
	@include font-rem($font-md, $font-md + 2);
	margin-bottom: 5px;
	margin-top: 0;
	padding-left: 18px;

	&.sm {
		margin-bottom: 5px;
		padding-left: 16px;
	}

	&.lg {
		margin-bottom: 8px;
		padding-left: 20px;
	}

	&.xl {
		margin-bottom: 10px;
		padding-left: 24px;
	}

	.sm,
	&.sm {
		@include font-rem($font-sm, $font-sm + 2);
		font-weight: 400;
		letter-spacing: -0.5px;
	}

	.lg,
	&.lg {
		@include font-rem($font-lg, $font-lg + 2);
		font-weight: 500;
		letter-spacing: -0.7px;
	}

	.xl,
	&.xl {
		@include font-rem($font-xlg, $font-xlg + 2);
		font-weight: 500;
		letter-spacing: 1px;
	}

	li {
		margin-bottom: 5px;
	}
}

// Can be applied directly on <a> element
.cta-link {
	@include font-rem(15px, 15px);
	color: $blackish;
	font-family: $primary-font;
	font-style: normal;
	font-weight: 700;
	letter-spacing: 1px;
	line-height: 28px;

	&:hover {
		color: $blackish;
		text-decoration: none;

		span {
			color: $blackish;
			text-decoration: none;
		}
	}
}

// Contact card styling for text component, i.e. below forms
.contact-card {
	border: 1px solid currentColor;
	// sass-lint:disable no-important
	margin-left: 0 !important;
	padding: 1em 1em 1em 5em;
	position: relative;
	width: 30ch;

	span {
		.lg {
			display: block;
			// sass-lint:disable no-important
			margin-bottom: 0.5em !important;
		}
	}

	&:before {
		-moz-osx-font-smoothing: grayscale;
		-webkit-font-smoothing: antialiased;
		content: "\e957";
		font-family: "icomoon" !important;
		font-size: 3em;
		font-style: normal;
		font-variant: normal;
		font-weight: normal;
		left: 0.33em;
		line-height: 1;
		position: absolute;
		speak: none;
		text-transform: none;
		top: 0.25em;
	}
}
