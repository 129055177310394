.bat-navigation {
	align-items: center;
	display: flex;
	max-width: 700px;
	width: 100%;

	&-group {
		height: 100%;
		width: 100%;

		&-list {
			height: 100%;
			justify-content: space-between;

			&-item {
				flex: none;
				height: 100%;
				padding: 0;
				position: relative;

				&:after {
					background-color: $white;
					bottom: 0;
					content: "";
					height: 1px;
					left: 0;
					margin: 0 20px;
					opacity: 0.1;
					position: absolute;
					right: 0;
				}

				@include media-breakpoint-up(lg) {
					&:after {
						display: none;
					}
				}

				& a, button {
					@include font-rem(12px, 12px);
					color: $white;
					font-weight: 700;
					justify-content: flex-start;
					padding: 20px;
					width: 100%;
					display: inline-flex;

					i {
						height: 10px;
						margin-left: auto;
						width: 10px;

						&:before {
							@include font-rem(10px, 10px);
						}

						svg {
							fill: $white;
						}
					}

					@include media-breakpoint-up(lg) {
						@include font-rem(12px, 12px);
						background-color: transparent;
						font-weight: 700;
						height: 100%;
						padding: 20px 5% 23px;
						transition: background-color 0.1s ease-in;
						width: max-content;

						span {
							color: $white;
							transition: color 0.1s ease-in;
						}

						&:hover {
							span {
								color: $white;
								text-decoration: none;
							}
						}

						i {
							display: none;
						}
					}
				}

				&--menu {
					.bat-navigation-header-sub-group {
						display: none;
					}

					background-color: transparent;

					& > a, button {
						position: relative;

						&:after {
							color: $white;
							content: "\e959";
							font-family: "icomoon";
							font-size: 28px;
							font-weight: normal;
							position: absolute;
							right: 0;
							top: 50%;
							transform: translateY(-50%);
						}
					}

					&.open {
						& > a, button {
							@include media-breakpoint-up(lg) {
								span {
									color: $white;
								}
							}

							&:after {
								content: none;

								@include media-breakpoint-up(lg) {
									color: $white;
								}

								@include media-breakpoint-down(md) {
									content: "\e94d";
								}
							}
						}

						.bat-navigation-header-sub-group {
							display: block;
						}
					}

					@include media-breakpoint-up(lg) {
						position: static;

						&.open {
							background-color: transparent;
						}

						& > a, button {
							position: relative;

							span {
								text-decoration: none;
							}

							&:after {
								color: $white;
								content: "\e920";
								font-family: "icomoon";
								font-size: 14px;
								left: 0;
								position: absolute;
								right: 0;
								top: 75%;
								transition: color 0.1s ease-in;
							}
						}

						&:focus,
						&:hover {
							& > a, button {
								position: relative;
							}
						}
					}
				}
			}
		}
	}

	&-sub-group {
		padding:20px;
		padding-top: 0px;
		padding-bottom: 0px;
		

		.open & {
			background-color: #5B5B5B;
		}

		@include media-breakpoint-up(lg) {
			background-color: $charcoal;
			padding: 40px 0 130px;
		}

		&-list {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;

			@include media-breakpoint-up(lg) {
				max-width: 50%;
			}
			
			&-item {
				margin: 0;
				text-align: left;
				width: 100%;

				@include media-breakpoint-up(lg) {
					width: 50%;
				}
				background: #5B5B5B;
				border-bottom: 1px solid #747474;
				padding-top: 10px;
				padding-bottom: 10px;
				padding-left: 5px;
				
				a, button {
					@include font-rem(12px, 12px);
					border-bottom: solid 2px transparent;
					color: $white;
					display: inline;
					font-weight: 700;
					justify-content: flex-start;
					padding: 0;
					transition: border-bottom 0.1s ease-in;

					&:hover {
						span {
							color: #fff;
							text-decoration: none;
						}
					}
				}
			}

			li:last-child{
				border-bottom: none
			}
		}

		&-close {
			display: none;

			@include media-breakpoint-up(lg) {
				color: $white;
				display: block;
				position: absolute;
				right: $gutter * 2;
				top: 0;

				i {
					svg {
						fill: $white;
						transition: fill 0.1s ease-in;
					}
				}

				&:hover {
					i {
						svg {
							fill: $white;
						}
					}
				}
			}
		}

		&-wrapper {
			@include media-breakpoint-up(lg) {
				margin: 0 auto;
				max-width: $max-width-xl;
				padding: 0 $gutter * 2;
				position: relative;
			}
		}
	}

	&-submenu{
		position: relative;
		z-index: 10 !important;
	}

	&-header-sub-group{
		display: block;
		border: 0.5px solid #000;
		background-color: #fff;
		margin: 0;
		padding: 0px 0px;
		position: absolute;
		transform: translate(0%, 0%);
		width: 185px;
		z-index: 1000;
		overflow-x: hidden;
		overflow-y: auto;	

	
		

		ul.no-auth-links{
			list-style: none;
			font-size:11px;
			padding: 0;
			a{
				text-align: left;
				text-decoration: none;
				font-family: "Mont", sans-serif;
				font-weight: 800;
				color: #000;
				padding: 0px;
				width:100%;
				justify-content: center;
				
				span{
					width: 85%;
					color: #000;
					border-bottom: 1px solid #aaa;
					padding-top: 8px;
					padding-bottom: 8px;
					text-decoration: none;
				}
			}

			

			li:first-child{
				span{
					margin-top: 3px;
				}
			}

			li:last-child{
				span{
					border-bottom: 0;
				}
			}
		}
		
	}
	
}
