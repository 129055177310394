.bat-signin-gloit {
    margin-inline: auto;
    margin-top: 80px;
    width: 500px;


    @include media-breakpoint-down(md) {
        margin-inline: 20px;
        width: auto;
    }

    &-form {

        .or-text{
            text-align: center;
            font-feature-settings: 'clig' off, 'liga' off;
            /* font-family: Montserrat; */
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: 150%;
            letter-spacing: 0.7px;
            text-transform: uppercase;
            margin-bottom: 10px;
        }

        &-loading-circle{
            &.active {
                &:after {
                    -webkit-animation: spin 2s linear infinite; /* Safari */
                    animation: spin 2s linear infinite;
                    border: 10px solid $light-gray;
                    border-radius: 50%;
                    border-top: 10px solid $purple;
                    bottom: 0;
                    content: "";
                    height: 60px;
                    left: 0;
                    margin: auto;
                    position: absolute;
                    right: 0;
                    top: -150px;
                    width: 60px;
                    z-index: 2;
                }
            }
        }

        .bat-form-step {

            &.opacity {
                opacity: 0.4;
                pointer-events: none;
            }

            .bat-form-heading {
                text-align: center;

                .bat-headline-style {
                    margin-bottom: 10px;
                    @include font-rem(30px, 34px);

                    @include media-breakpoint-down(md) {
                        @include font-rem(24px, 28px);
                    }
                }
            }
        }

        .bat-form-block {
            margin-bottom: 0px;
        }

        .bat-form--signin-email {
            input {
                border-radius: 0px;
            }
        }

        .bat-form--signin-password {
            margin-bottom: 3px;

            input {
                border-radius: 0px;
            }

            .error-msg {
                display: none;
            }

            .bat-icon {
                margin-right: 10px;
                margin-left: auto;
                margin-top: -34px;
                cursor: pointer;
            }
        }

        .bat-form--signin-submit {
            text-align: center;
            margin-bottom: 20px;

            .error-msg {
                display: none;
            }

            .bat-message {
                color: red;
                margin-bottom: 10px;
            }

            button {
                width: 300px;

                @include media-breakpoint-down(md) {
                    width: 100%;
                    max-width: 100%;
                }
            }
        }

        .forgotPdw {
            text-transform: uppercase;
            margin-bottom: 30px;
            margin-top: 10px;
        }

        .uk-flex {
            flex-direction: row;
            align-items: center;
            gap: 10px;

            @include media-breakpoint-down(sm) {
                flex-direction: column;
            }
            &.social-buttons {

                &.opacity  {
                    opacity: 0.4 !important;
                    pointer-events: none;
                }
                
                &.opacity-zero {
                    opacity: 0;
                }

                &.opacity-zero.transition {
                    opacity: 1;
                    transition: all 1s;
                    -webkit-transition: all 1s;
                }

                @include media-breakpoint-down(md) {
                    max-width: 310px;
                    margin: 0 auto;
                }

                
            }

            .g_id_signin {
                margin-bottom: 20px;
                @include media-breakpoint-down(md) {
                    width: 100%;
                    max-width: 100%;
                }
            }

            .bat-button--facebook {
                width: 300px;
                margin-bottom: 20px;

                @include media-breakpoint-down(md) {
                    width: 100%;
                    max-width: 100%;
                }

                .fa-brands {
                    position: absolute;
                    left: 15px;
                    height: 18px;
                    width: 18px;
                }
            }
           
            .bat-button--google {
                width: 300px;
                margin-bottom: 20px;

                @include media-breakpoint-down(md) {
                    width: 100%;
                    max-width: 100%;
                }

                .fa-brands {
                    position: absolute;
                    left: 15px;
                    height: 18px;
                    width: 18px;
                }
            }

            .google-button {
                padding: 0;
                margin-bottom: 20px;
                overflow: visible;
                @include media-breakpoint-down(md) {
                    width: 100%;
                    max-width: 100%;
                }
            }

            .fb-login-button {
                padding: 0;
                margin-bottom: 20px;
                @include media-breakpoint-down(md) {
                    width: 100%;
                    max-width: 100%;
                }
            }
        }
    }

    &-signupCta {
        text-align: center;
        border-top: 2px solid #DEDEDE;
        margin-bottom: 50px;
        background-color: #2F539F;
        border-radius: 40px;
        color: white;

        .title {
            color: white;
            @include font-rem(22px, 28px);
            margin-bottom: 10px;
            margin-top: 40px;

            @include media-breakpoint-down(md) {
                @include font-rem(20px, 24px);
            }
        }

        .subtitle {
            color: white;
            @include font-rem(18px, 28px);
            margin-bottom: 15px;
            margin-top: 10px;

            @include media-breakpoint-down(md) {
                @include font-rem(16px, 24px);
            }
        }

        .cta{
            margin-bottom: 30px;
        }

        .info {
            >ul {
                list-style: none;
                padding: 0px;
                @include font-rem(14px, 20px);
                >li{
                    margin-bottom: 12px;
                    img{
                        margin-right: 10px;
                    }
                }
            }

            width: 300px;
            text-align: left;
            margin-top: 10px;
            margin-bottom: 20px;
            margin-left: auto;
            margin-right: auto;

            @include media-breakpoint-down(md) {
                width: 275px;
                max-width: 100%;
            }

            .additional-info {
                margin-top: 10px;
                @include font-rem(11px, 28px);
            }
        }

        .cta {
            width: 300px;
            font-size: 13px;

            @include media-breakpoint-down(md) {
                width: 275px;
                max-width: 100%;
            }

            &:hover {
                color: $brand-black;
            }
        }
    }

    &.register-device {
        margin-top: 0;

        @include media-breakpoint-down(md) {
            background: $white;
            margin: 0;
            padding: 20px;
        }
        
        .bat-form-step {
            .bat-form-heading {
                text-align: left;
            }

            .bat-form-field {
                input {
                    background-color: $white !important;
                    box-shadow: 0 0 0 30px $white inset !important;
                    -webkit-box-shadow: 0 0 0 30px $white inset !important;

                    @include media-breakpoint-down(md) {
                        background-color: #efefef !important;
                        box-shadow: 0 0 0 30px #efefef inset !important;
                        -webkit-box-shadow: 0 0 0 30px #efefef inset !important;
                    }
                }
            }
        }

        .cta-flex {
            flex-wrap: nowrap;
            align-items: center;
            justify-content: space-between;
            margin-top: 15px;

            @include media-breakpoint-down(md) {
                flex-wrap: wrap;
                justify-content: flex-end;
            }

            .bat-form--signin-submit {
                width: auto;

                @include media-breakpoint-down(md) {
                    width: 100%;
                }
            }

            .forgotPdw {
                text-transform: none;
                text-decoration: underline;
                font-size: 10px;
                line-height: 14px;
            }
        }

        .bat-signin-gloit-signupDevice {
            font-size: 14px;
            line-height: 20px;

            .orangeLink {
                color: #E35205;
                font-weight: 700;
                text-decoration: underline;
            }
        }
    }
}

.bat-signin-gloit-mobile {
    margin-inline: 0;
    margin-top: 0;
    width: auto;
    background: linear-gradient(135deg, #5F73BA 0%, #000AE3 100%);

    @include media-breakpoint-down(md) {
        margin-inline: 0;
        width: auto;
    }

    .bat-signin-gloit-mobile-logo {
        width: 68px;
        margin: 0 auto;
        padding: 48px 0 30px 0;
    }

    .bat-signin-gloit-form {
        background-color: $white;
        padding: 32px 20px 0 20px;
        border-radius: 35px 35px 0 0;
        display: flex;
        flex-direction: column;    

    }

    form {
        display: flex;
        flex-direction: column;

        .forgotPdw {
            align-self: flex-end;
            margin: 17px 23px 25px 0;
            font-size: 14px !important;
            line-height: 21px !important;
        }
    }

    .social-buttons {
        width: 100%;
        max-width: unset !important;
    }

    .signupCta {
        font-size: 14px !important;
        line-height: 21px !important;
        text-transform: uppercase;
        margin: 0 auto;
    }

    &-splash {
        background-color: $white;
        position: fixed;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 99999;
        pointer-events: none;

        img {
            border-radius: 20px;
            width: 100px;
            height: 100px;
        }
    }
}
// Potected sign in form on Veo oand Neo stick pages
.protected-signin {
    padding-top: 10px;
    .bat-signin-gloit{
        @include media-breakpoint-up(md) {
            width: 694px;
        }
    }
    .bat-signin-gloit-form .bat-form-step .bat-form-heading {
        margin-bottom: 30px;
    }
    
    #step1Form {
        padding: 25px;
        background: #fff;
        border-radius: 18px;
        position: relative;

        .forgotPdw {
            position: absolute;
            bottom: -10px;
            left: 0;
            font-size: 14px;
            font-weight: 400;
            width: 100%;
            display: inline-block;
            text-align: center;
            text-decoration: underline;
            z-index: 2;
            text-transform: none;
            letter-spacing: normal;
        }
        .arrow-link-dark::after{
            content: '';
        }
        .bat-form-field input {
            box-shadow: 0 0 0 30px #f8f8f8 inset !important;
            border-bottom: solid 4px rgba(39, 38, 38, 0.24);
            border-radius: 7px;
            padding: 16px 8px;

            &::placeholder{
                font-size: 14px;
                opacity: 24%;
            }

            &:focus {
                border: 0;
                border-bottom: solid 4px rgba(39, 38, 38, 0.24);
                outline: 0;
            }

            &:active,
            &:focus,
            &:hover {
                box-shadow: 0 0 0 30px #f8f8f8 inset !important;
            }
        }
        .bat-button {
            &--dark {
                background: #FF5100;
                max-width: 100%;
                width: 100%;
                margin: 40px 0 0 0;
                color: #333;
            }
        }
    }
    .bat-signin-gloit-signupCta{
        background: none;
        border: none;
        .cta{
            font-size: 18px;
            letter-spacing: normal;
        }
        .bat-button {
            &--dark {
                background: none;
                color: #333;
                width: auto;
            }

            &::after{
                content: "\e910";
                font-family: icomoon;
                font-size: 26px;
                position: absolute;
                right: 0;
            }
        }
    }

    .social-buttons{
        display: none;
    }

}