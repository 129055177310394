.bat-card-gloitnewsletterpopup{
    background-color: $concrete;

    .uk-flex{
        @include media-breakpoint-down(sm){
            display: block;
        }
    }
    

     &-title{
         @include font-rem(22px, 28px);
         font-family: $primary-font;
         font-weight: 800;

         @include media-breakpoint-down(sm){
            @include font-rem(20px, 24px);
            margin-bottom: 7px;
         }
     }

     &-subtitle{
        @include font-rem(14px, 20px);
        font-family: $primary-font;
     }

     &-cta{

        .bat-cta-style{
            &.button-secondary-light:hover span{
                color: $brand-black;
            }
        }

        .bat-cta-style{
            &.button-secondary-light{
                background-color: $white;           
            }
        }

         span{
            color: $brand-black;
        }

        @include media-breakpoint-down(sm){
            margin-top: 30px;
            width: fit-content;
            margin-left: auto;
            margin-right: auto;
        }

    }
}