// sass-lint:disable no-duplicate-properties
@font-face {
	font-display: block;
	font-family: "icomoon";
	font-style: normal;
	font-weight: normal;
	src: url("../fonts/icomoon/icomoon.eot?sqs6qy");
	src: url("../fonts/icomoon/icomoon.eot?sqs6qy#iefix")
			format("embedded-opentype"),
		url("../fonts/icomoon/icomoon.ttf?sqs6qy") format("truetype"),
		url("../fonts/icomoon/icomoon.woff?sqs6qy") format("woff"),
		url("../fonts/icomoon/icomoon.svg?sqs6qy#icomoon") format("svg");
}

.icon,
[class^="icon-"],
[class*=" icon-"] {
	&::before {
		/* use !important to prevent issues with browser extensions that change fonts */
		// sass-lint:disable no-important
		font-family: "icomoon" !important;
		font-style: normal;
		font-variant: normal;
		font-weight: normal;
		line-height: 1;
		speak: none;
		text-transform: none;

		/* Better Font Rendering =========== */
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}
}

// sass-lint:disable force-pseudo-nesting

.icon-facebook:before {
	content: "\e900";
}

.icon-account:before {
	content: "\e902";
}

.icon-bag-add:before {
	content: "\e904";
}

.icon-alternative_payment_method:before {
	content: "\e906";
}

.icon-arrow-down:before {
	content: "\e908";
}

.icon-arrow-left:before {
	content: "\e90c";
}

.icon-arrow-right:before {
	content: "\e910";
}

.icon-arrow-up:before {
	content: "\e914";
}

.icon-bag:before {
	content: "\e918";
}

.icon-bag-empty:before {
	content: "\e91a";
}

.icon-calendar:before {
	content: "\e91c";
}

.icon-chat:before {
	content: "\e91e";
}

.icon-chevron-down:before {
	content: "\e920";
}

.icon-chevron-left:before {
	content: "\e924";
}

.icon-chevron-right:before {
	content: "\e928";
}

.icon-chevron-up:before {
	content: "\e92c";
}

.icon-close:before {
	content: "\e930";
}

.icon-credit-card:before {
	content: "\e932";
}

.icon-cross:before {
	content: "\e935";
}

.icon-delivery:before {
	content: "\e937";
}

.icon-device:before {
	content: "\e939";
}

.icon-error:before {
	content: "\e93b";
}

.icon-eye-off:before {
	content: "\e93d";
}

.icon-eye-on:before {
	content: "\e93f";
}

.icon-filter:before {
	content: "\e941";
}

.icon-flexibility:before {
	content: "\e943";
}

.icon-glo:before {
	content: "\e945";
}

.icon-heart:before {
	content: "\e947";
}

.icon-lock:before {
	content: "\e949";
}

.icon-menu:before {
	content: "\e94b";
}

.icon-minus:before {
	content: "\e94d";
}

.icon-new:before {
	content: "\e94f";
}

.icon-no-search:before {
	content: "\e951";
}

.icon-offers:before {
	content: "\e953";
}

.icon-personalisation:before {
	content: "\e955";
}

.icon-phone:before {
	content: "\e957";
}

.icon-plus:before {
	content: "\e959";
}

.icon-products:before {
	content: "\e95b";
}

.icon-quality:before {
	content: "\e95d";
}

.icon-radio-off:before {
	content: "\e97e";
}

.icon-radio-on:before {
	content: "\e97f";
}

.icon-recycling:before {
	content: "\e95f";
}

.icon-search:before {
	content: "\e961";
}

.icon-shield:before {
	content: "\e963";
}

.icon-sort:before {
	content: "\e965";
}

.icon-star:before {
	content: "\e967";
}

.icon-map-pin:before {
	content: "\e969";
}

.icon-subscriptions:before {
	content: "\e96b";
}

.icon-trophy:before {
	content: "\e96f";
}

.icon-instagram:before {
	content: "\e971";
}

.icon-twitter:before {
	content: "\e973";
}

.icon-youtube:before {
	content: "\e981";
}

.icon-tick:before {
	content: "\e96d";
}

.icon-checkbox-on:before {
	content: "\e979";
}

.icon-checkbox-off:before {
	content: "\e977";
}

.icon-info:before {
	content: "\e982";
}