// File:           _form.scss
// Description:    Form Styles
.bat-form {
	margin: 0 auto;
	max-width: 768px;

	&-step-head {
		display: flex;
		flex-direction: row;
		margin: 20px 0 50px;

		span {
			&.number {
				&.active {
					background-color: $blackish;
					color: $white;
				}
			}

			&.divider {
				&.active {
					background-color: $blackish;
				}
			}
		}
	}

	&-field {
		&-tip {
			right: 18px;
			top: 26px;

			i {
				&:before {
					color: $blackish;
					content: "\e947";
					font-family: "icomoon";
					font-size: 20px;
				}
			}
		}

		&--read-only {
			position: relative;

			input {
				border: solid 2px $blackish;
			}

			&:before {
				color: $blackish;
				content: "\e949";
				font-family: "icomoon";
				font-size: 18px;
				font-weight: 700;
				height: 20px;
				left: 10px;
				pointer-events: none;
				position: absolute;
				top: 8px;
			}
		}

		.bat-message {
			p {
				padding: 0;
			}

			&:before {
				display: none;
			}
		}

		label {
			background-color: transparent;
			color: $blackish;
			left: 0;
			margin: 0;
			position: relative;
			top: 0;

			&.blurred {
				color: $blackish;
				top: 0;
			}
		}
	}

	&--login {
		.bat-cta-style {
			@include media-breakpoint-down(xs) {
				display: block;
				margin-top: 1rem;
			}
		}
	}
	//-----------------------------------------------------------------| Contact Us
	&--contact-us {
		max-width: 512px;

		.bat-form-heading {
			margin-bottom: 20px;
			text-align: center;
		}

		.bat-form-text {
			margin-bottom: 20px;
			text-align: center;

			p {
				color: $dark-gray;
				line-height: 1.4rem;
			}

			a {
				color: $blackish;
				font-weight: 700;
				text-decoration: none;

				&:hover {
					text-decoration: underline;
				}
			}
		}

		.bat-form-block-text {
			margin-bottom: 0;
			.submit-status {
				padding-bottom: 20px;
			}
		}

		.bat-form-field {
			label {
				display: flex;
				flex-direction: row;
				align-items: flex-end;
				flex-wrap: wrap;
				span.label-optional {
					margin-left: auto;
				}
			}
		}

		.bat-form--contact-us-email-submit {
			text-align: center;

			button {
				max-width: 276px;
				width: 100%;
				@include media-breakpoint-down(xs) {
					max-width: unset;
				}
			}
		}

		.submit-status {
			display: none;
			margin: 30px auto 0;
			position: relative;
			width: 40px;

			&:after {
				-webkit-animation: spin 2s linear infinite; /* Safari */
				animation: spin 2s linear infinite;
				border: 10px solid $light-gray;
				border-radius: 50%;
				border-top: 10px solid $purple;
				bottom: 0;
				content: "";
				height: 40px;
				left: 0;
				margin: auto;
				position: absolute;
				right: 0;
				top: 0;
				width: 40px;
				z-index: 1;
			}
		}

		.contact-us-error,
		.contact-us-success {
			background-color: $concrete;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			margin-top: 20px;
			min-height: 300px;
			padding: 0 20px 67px;
			text-align: center;

			h4 {
				margin-bottom: 10px;
				position: relative;

				&:before {
					background-color: $blackish;
					border-radius: 70px;
					color: $white;
					display: flex;
					flex-direction: column;
					font-family: "icomoon";
					font-size: 40px;
					height: 70px;
					justify-content: center;
					left: 0;
					margin: auto;
					pointer-events: none;
					position: absolute;
					right: 0;
					top: -80px;
					width: 70px;
				}
			}

			p {
				color: $blackish;
				@include font-rem(15px, 20px);
				margin: 0 auto;
				max-width: 323px;
			}
		}

		.contact-us-success {
			h4 {
				&:before {
					content: "\e96d";
				}
			}
		}

		.contact-us-error {
			h4 {
				&:before {
					content: "\e935";
				}
			}
		}

		.bat-form--contact-us-email-tos {
			border: solid 1px $light-gray;
			padding: 30px;
			position: relative;

			label {
				height: 122px;
				overflow: hidden;
				padding-bottom: 42px;
				padding-left: 1px;

				&.open {
					height: auto;
				}

				p {
					line-height: 1.2rem;
					margin-bottom: 10px;

					.error {
						color: $red;
					}
				}

				a {
					color: $blackish;
					font-weight: 700;
					text-decoration: none;

					&:hover {
						text-decoration: underline;
					}
				}

				.label-toggle {
					background-color: $white;
					bottom: 0;
					color: $blackish;
					cursor: pointer;
					display: block;
					left: 0;
					margin: auto;
					padding: 13px;
					position: absolute;
					right: 0;
					text-align: center;
					text-decoration: underline;
					width: 100%;

					&:hover {
						text-decoration: none;
					}
				}
			}
		}
	}
	//-----------------------------------------------------------------| News Letter
	&--newsletter {
		max-width: 512px;
		padding-top: 60px;
		position: relative;

		.velo-logo {
			left: 0;
			margin: 10px auto;
			position: absolute;
			right: 0;
			top: 0;
			width: 65px;
		}

		.bat-form-heading {
			font-weight: 700;
			margin-bottom: map-get($sizes, xsmall);
			margin-top: 30px;
			text-align: center;
		}

		.bat-form-field {
		}

		.bat-form-text {
			margin-bottom: 20px;
			text-align: center;

			p {
				color: $blackish;
				@include font-rem(20px, 28px);
			}

			a {
				color: $blackish;
				font-weight: 700;
				text-decoration: none;

				&:hover {
					text-decoration: underline;
				}
			}
		}

		.bat-form--newsletter-email-submit {
			margin-bottom: 10px;
			text-align: center;

			button {
				max-width: 276px;
				width: 100%;
				@include media-breakpoint-down(xs) {
					max-width: unset;
				}
			}
		}

		&-email-first-name,
		&-email-last-name {
			width: 100%;

			@include media-breakpoint-up(md) {
				width: 47%;
			}
		}

		.newsletter-error,
		.newsletter-success {
			background-color: $concrete;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			margin-top: 20px;
			min-height: 300px;
			padding: 0 20px 67px;
			text-align: center;

			h4 {
				margin-bottom: 10px;
				position: relative;

				&:before {
					background-color: $blackish;
					border-radius: 70px;
					color: $white;
					display: flex;
					flex-direction: column;
					font-family: "icomoon";
					font-size: 40px;
					height: 70px;
					justify-content: center;
					left: 0;
					margin: auto;
					pointer-events: none;
					position: absolute;
					right: 0;
					top: -80px;
					width: 70px;
				}
			}

			p {
				color: $blackish;
				@include font-rem(15px, 20px);
				margin: 0 auto;
				max-width: 323px;
			}
		}

		&-email-consent-message {
			.bat-message {
				display: block;

				p {
					color: $dark-gray;
					margin-bottom: 10px;
					width: 100%;
				}
			}
		}

		&-email-age-verified,
		&-email-data-handling,
		&-email-consent-message,
		&-email-email-opt-in,
		&-email-mobile-opt-in,
		&-email-social-media-opt-in,
		&-email-trial-information-opt-in {
			height: 0;
			margin-bottom: 0;
			overflow: hidden;

			&.open {
				height: auto;
				margin-bottom: 20px;
				overflow: visible;
			}
		}

		.consent-text {
			.bat-form-block-text {
				align-items: center;
				display: flex;
				flex-direction: column;
				flex-wrap: wrap;

				p {
					color: $dark-gray;
					margin-bottom: 10px;
					width: 100%;
				}
			}

			.label-toggle {
				background-color: $white;
				color: $blackish;
				cursor: pointer;
				display: block;
				margin: auto;
				padding: 13px;
				text-align: center;
				text-decoration: underline;
				width: 100%;

				&:hover {
					text-decoration: none;
				}
			}

			.submit-status {
				display: none;
				margin-top: 30px;
				position: relative;
				width: 40px;

				&:after {
					-webkit-animation: spin 2s linear infinite; /* Safari */
					animation: spin 2s linear infinite;
					border: 10px solid $light-gray;
					border-radius: 50%;
					border-top: 10px solid $purple;
					bottom: 0;
					content: "";
					height: 40px;
					left: 0;
					margin: auto;
					position: absolute;
					right: 0;
					top: 0;
					width: 40px;
					z-index: 1;
				}
			}
		}

		.newsletter-success {
			h4 {
				&:before {
					content: "\e96d";
				}
			}
		}

		.newsletter-error {
			h4 {
				&:before {
					content: "\e935";
				}
			}
		}

		.newsletter-disclaimer {
			p {
				color: $dark-gray;
			}

			a {
				color: $blackish;
				font-weight: 700;
				text-decoration: none;

				&:hover {
					text-decoration: underline;
				}
			}
		}

		.bat-form-field-set {
			border: 2px solid $light-gray;
			padding: 20px;

			label {
				margin-bottom: 0;
				> input[type="checkbox"] + span,
				> input[type="checkbox"]:checked + span {
					padding-bottom: 0;
				}
			}
		}

		&.mobile-app {
			padding: 20px 0;

			.step-1 {
				padding: 20px;

				input {
					max-width: 60%;
				}

				button {
					padding: 10px;
				}

				.uk-flex {
					align-items: center;
					gap: 8px;
				}
			}
			
			.step-2 {
				padding: 20px;

				input {
					max-width: 60%;
				}

				button {
					padding: 10px;
				}

				.uk-flex {
					align-items: center;
					gap: 8px;
				}
			}

			.bat-form-field {
				margin: 0;
			}

			.bat-form-field-set {
				border: unset;
				margin: 0;
				padding: 10px 20px;

				label > input[type=checkbox] + span {
					color: $black;

					a {
						text-decoration: underline;
					}
				}
			}

			.bat-form--newsletter-email-submit {
				margin: 0;
				position: fixed;
				bottom: 0;
				left: 0;
				padding: 20px;
				background: white;
			}
		}
	}

	// Magento remote navigation overrides
	form {
		fieldset {
			label {
				span {
					@include font-rem(15px, 15px);
					color: $dark-gray;
					font-weight: normal;
					letter-spacing: normal;
					text-transform: initial;
				}

				& > input {
					&[type="checkbox"] {
						overflow: visible;
					}

					+ label {
						display: none;
					}
				}

				&:before {
					content: none;
				}
			}
		}
	}
}

/* Safari */
@-webkit-keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
