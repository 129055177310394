.bat-card-gloitnewsletterbanner{
    background:#EC7B2E;
    ;
    margin-top: 30px;
    z-index: 3;

    &.uk-active{
        top: 133px !important;
        @include media-breakpoint-down(lg) {
            top: 125px !important;
        }
        @include media-breakpoint-down(md) {
            top: 70px !important;
        }
    }

    @include media-breakpoint-down(sm){
        padding: 10px 10px;
    }

    &.close{
        display: none;
    }

    &-title{

        padding: 10px 10px;

        .title,
        .subtitle{
            color: $white;
        }

        .title{
            @include font-rem(21px, 28px);
            font-weight: 800;

            @include media-breakpoint-down(sm){
                @include font-rem(17px, 28px)
            }    
        }

        .subtitle{
            @include font-rem(12px, 18px);
            font-weight: 500
        }
    }

    &-cta{
        align-items: center;

        .cta{
            background-color: $white;
            border: none;
            transition: none;
            padding: 10px 30px;
            margin-right: 100px;
            
            @include media-breakpoint-down(sm){
                padding: 15px 14px;
                margin-right: 0px;
                width: 50px;
            }

            .icon-mail{
                margin-right: 15px;

                @include media-breakpoint-down(sm){
                    width: 30px;
                    margin-right: 0px;
                }
            }

            .cta-label{
                color: #D04D07;

                @include media-breakpoint-down(sm){
                    display: none;
                }
            }
           
            &:hover{
                background-color: $white;
                border: none;

                .cta-label{
                    color: #D04D07;
                }
            }
        }
    }
    &-container{
        margin: 0 auto;
        max-width: 1200px;
        width: 100%;

        .bat-card-gloitnewsletterbanner-first-panel{
            display: flex;
            gap:10px;
        }
    }

   

    .icon{
        align-self: center;
        margin-right: 35px;

        &::before{
            color: $white;
        }

        @include media-breakpoint-down(sm){
            margin-right: 0px;
            align-self: baseline;
        }
    }
}

.arrow-link-orange{

    .bat-cta-style {
        &.arrow-link-dark::after {
            color: #EC7B2E;
        }
    }
}
