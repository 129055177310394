.bat-icon {
	align-items: center;
	display: flex;
	height: 28px;
	justify-content: center;
	width: 28px;
	
	&::before, .bat a &::before {
		font-size: 28px;
	}
	
	svg {
		fill: $dark-gray;
		width: 100%;
	}

	&.icon-arrow-right::before {
		margin-top: -6px;
	}
}
