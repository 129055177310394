.bat-hero {
	&-content {
		ul {
			margin-top: 1rem;
		}

		.bat-button {
			margin-top: 1.5rem;
		}

		&.light {
			p,
			h1,
			h2,
			h3,
			h4,
			h5,
			h6 {
				color: $white;
			}
		}

		@include media-breakpoint-down(sm) {
			.bat-cta-style {
				max-width: 100%;
				width: 100%;
			}
		}
	}

	//-----------------------------------------------------------------| Product Detail Page
	&--pdp {
		align-items: flex-start;
		background-color: $dark-blue;
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		height: auto;
		justify-content: flex-end;
		padding: 0;
		position: relative;
		width: 100%;

		&-bottomsubheader {
			p {
				line-height: 1.25;
				margin-bottom: 0;
			}
		}

		&-content {
			margin-top: calc(
				-75px + ((-200) - (-75)) * ((100vw - 375px) / (768 - 375))
			);
			padding: 0 5% 5%;
			position: relative;

			&.light {
				h1,
				h2,
				h3,
				h4,
				h5,
				h6,
				li,
				p {
					color: $white;
				}
			}

			.bat-button {
				margin-top: 1.5rem;
			}

			p {
				margin-bottom: 0;

				& + p {
					margin-top: 10px;
				}
			}
		}

		&-cta {
			display: flex;
			width: 100%;

			&.right {
				justify-content: flex-end;
			}
		}

		&-headline {
			margin-bottom: 1.75rem;
		}

		&-topsubheader {
			ol {
				counter-reset: listCounter;
				list-style: none;
				margin-bottom: 1.75rem;
				padding: 0;
				li {
					margin-bottom: 48px;
					&:last-child {
						margin-bottom: 0;
					}
					span {
						align-items: center;
						display: flex;
						&[class*="bullet"] {
							&::before {
								flex-grow: 0;
								flex-shrink: 0;
								line-height: 1;
								margin-right: 2.25rem;
							}
						}
						&.bullet--numbered {
							&::before {
								background-color: $white;
								border-radius: 50%;
								color: $dark-blue;
								content: counter(listCounter);
								counter-increment: listCounter;
								font-size: 2.25rem;
								font-weight: 700;
								height: 64px;
								padding: 16px;
								text-align: center;
								width: 64px;
							}
						}
						&.bullet--icon-place {
							&::before {
								content: url("/content/dam/glo/images/pdp-hero/velo_lozenges_place-icon.png");
							}
						}
						&.bullet--icon-embrace {
							&::before {
								content: url("/content/dam/glo/images/pdp-hero/velo_lozenges_embrace-icon.png");
							}
						}
					}
				}
			}
			p {
				line-height: 1.25;
				margin-bottom: 3.375rem;
			}
		}

		.headline6-color1 {
			@extend .bat-headline-style.headline6-color1;
		}

		img {
			clip-path: polygon(0 0, 100% 0, 100% 80%, 0 55%);
			left: auto;
			position: static;
			top: auto;
			transform: none;
		}

		@include media-breakpoint-up(md) {
			flex-direction: row;
			height: 672px;
			justify-content: flex-start;

			&-content {
				flex: 1 2 670px;
				margin-top: 0;
				max-width: none;
				min-width: 490px;
				& > * {
					max-width: 490px;
				}
			}

			&-headline {
				margin-bottom: 1.75rem;
			}

			&-topsubheader {
				ol {
					margin-bottom: 3rem;
					max-width: 370px;
					li {
						margin-bottom: 40px;
					}
				}
				p {
					line-height: 1.25;
					margin-bottom: 3.375rem;
				}
			}

			& .padding-left-medium {
				padding-left: 44px;
			}

			& .padding-right-small {
				padding-right: 32px;
			}

			& .padding-top-large {
				padding-top: 80px;
			}

			picture {
				flex: 0 1 830px;
				height: 100%;
				max-width: 830px;
				img {
					// The calc() function makes that the clip-path scale and remain at an angle; otherwise, @ 769px, the angle is vertical
					// For more info about the formula/equation see: https://css-tricks.com/snippets/css/fluid-typography/
					clip-path: polygon(
						0 0,
						100% 0,
						calc(
								145px + (639 - 145) *
									((100vw - 769px) / (1920 - 769))
							)
							100%,
						0 100%
					);

					left: auto;
					position: static;
					top: auto;
					transform: none;
				}
			}
		}
	}
}

bat-hero-default,
bat-hero-gloitbackground {
	// text-right class causes text to begin at center of component instead of left
	&.text-right {
		.bat-hero-content {
			@include media-breakpoint-up(md) {
				padding-left: 50%;
			}
		}
	}
	//masthead-right class causes text to align more to the right side
	&.masthead-right {
		.bat-hero {
			height: 501px;

			@include media-breakpoint-up(md) {
				height: 537px;
			}
		}
		.bat-hero-content {
			bottom: 5%;
			padding: 20px;
			position: absolute;

			.bat-headline-style {
				padding-bottom: 20px;
			}

			@include media-breakpoint-up(md) {
				bottom: auto;
				padding-left: 75%;
				top: 10%;
			}
			@include media-breakpoint-up(lg) {
				padding-left: 67%;
			}
		}
	}

	&.direction-column {
		flex-direction: column;
		align-items: flex-start;

		.logo-hyper-pro {
			position: static;
			width: 350px;
			margin-top: 10px;
			transform: unset;
			object-fit: contain;
		}
	}
}

bat-hero-glo {
	&.lilac .bat-hero {
		background-color: $lilac;
		color: $brand-black;
		.bat-hero-disclaimer p {
			color: $brand-black;
		}
	}
	.bat-hero {
		background-color: $concrete;
		color: $brand-black;
		&.dark {
			background-color: $brand-black;
			color: $white;
			.bat-hero-subheader p,
			.bat-hero-disclaimer p {
				color: $white;
			}
		}

		&-img {
			height: 100%;
			max-width: 612px;
			position: absolute;
			top: 0;
			width: 50%;
			@include media-breakpoint-down(sm) {
				max-width: none;
				width: 100%;
			}
			picture {
				height: 100%;
				width: 100%;
				img {
					height: 100%;
					left: 0;
					min-width: unset;
					object-fit: contain;
					object-position: top left;
					top: 0;
					transform: none;
					width: 100%;
				}
			}
		}

		&-content {
			.bat-headline,
			.bat-hero-subheader {
				max-width: 450px;
				p {
					color: $charcoal;
					@include font-rem(14px, 20px);
				}
			}
			.bat-headline {
				margin-bottom: 20px;
				.bat-headline-style {
					@include font-rem(34px, 39px);
					font-family: $primary-font;
					font-weight: 700;
				}
			}

			padding-bottom: 40px;
			padding-left: 51.5%;
			padding-right: 20px;
			padding-top: 80px;
			@include media-breakpoint-down(sm) {
				padding-bottom: 20px;
				padding-left: 20px;
				padding-right: 20px;
				padding-top: 66%;

				.bat-hero-subheader p {
					line-height: 21px;
				}

				.bat-headline .bat-headline-style {
					@include font-rem(24px, 28px);
				}
			}
			.bat-cta-style {
				@include media-breakpoint-down(sm) {
					width: fit-content;
				}
				&.arrow-link-dark,
				&.arrow-link-light {
					margin-top: 12px;
				}
			}
			img {
				left: unset;
				min-width: unset;
				max-width: unset;
				object-fit: unset;
				position: unset;
				top: unset;
				transform: none;
				height: unset;
				width: unset;
			}
		}
		&-disclaimer {
			margin-top: 50px;
			p {
				@include font-rem(10px, 14px);
				color: $charcoal;
			}
			@include media-breakpoint-down(sm) {
				margin-top: 30px;
			}
		}
	}
}
