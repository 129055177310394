.bat-newhyper-card{
    //add background remove bck color
    background: url("/content/dam/glo-it/images/hyper-page/background-pro-banner.png") no-repeat center center fixed;
    background-size: cover;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    padding: 50px 0 54px;

    .device-img {
        position: absolute;
        box-sizing: content-box;
    }

    .content {
        font-size: 50px;
        line-height: 60px;
        font-weight: 500;
        color: $white;
        width: fit-content;
        margin-left: auto;
        margin-right: 9vw;

        .text-content {
            margin-bottom: 45px;
            margin-top: 25px;
        }

        .buttons-container {
            display: flex;
            gap: 30px;
            .white-border{
                border: solid 2px $white !important;
            }
        }
    }
}

@media only screen and (max-width: 1300px) and (min-width: 769px){
    .bat-newhyper-card {
        padding: 40px 0px 0px;
        background: url("/content/dam/glo-it/images/hyper-page/background-pro-banner-mobile.png") no-repeat center center fixed;
        background-size: cover;

        .device-img {
            min-width: 100%;
            left: -25%;
            top: 28%;
        }

        .content {
            display: flex;
            flex-direction: column;
            height: 685px;
            gap: 18px;
            padding: 0;
            text-align: center;
            align-items: center;
            font-size: 30px;
            line-height: 30px;
            width: 100%;
            margin: 0;

            .title {
                width: 303px;
            }

            .text-content {
                margin: 0;
                margin-bottom: 400px;
            }

        }
    }
}

@media (max-width: 768px) {
    .bat-newhyper-card {
        padding: 40px 0px 0px;
        background: url("/content/dam/glo-it/images/hyper-page/background-pro-banner-mobile.png") no-repeat center center fixed;
        background-size: cover;

        .device-img {
            min-width: 160%;
            left: -65%;
            top: 28%;
        }

        .content {
            display: flex;
            flex-direction: column;
            height: 685px;
            gap: 18px;
            padding: 0;
            text-align: center;
            align-items: center;
            font-size: 30px;
            line-height: 30px;
            width: 100%;
            margin: 0;

            .title {
                width: 303px;
            }

            .text-content {
                margin: 0;
                margin-bottom: 400px;
            }

        }
    }
}

@media (max-width: 376px) {
    .device-img {
        left: -65%;
    }
}
