.bat-pagination {
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: center;

	&-label {
		@include font-rem(14px, 14px);
		color: $brand-black;
		margin: 10px 0;
	}
	
	&-buttons {
		align-items: center;
		display: flex;
		flex-wrap: wrap;
	}
	
	&-button-wrapper {
	
		button {
			@include font-rem(14px, 14px);
			border-radius: 52px;
			color: $dark-gray;
			height: 30px;
			transition: color .1s ease-in;
			width: 30px;
			
			&.active {
				background-color: $orange;
				color: $white;
			}
			
			&:hover {
				color: $brand-black;
				
				i {
					
					&:before {
						color: $brand-black;
					}
				}
			}
			
			i {
				height: 30px;
				width: 30px;
				
				&:before {
					color: $dark-gray;
					font-size: 20px;
					transition: color .1s ease-in;
				}
			}
		}
	}
}
