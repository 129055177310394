/*---------------------------------
CONFIG
---------------------------------*/
$site-max-width: 1400px;
$site-gutter-size: 20px;
$grid-gutter-size: 7px;


/*---------------------------------
GLOBAL
---------------------------------*/
@import '../../core/themes/global/variables';
@import '../../core/themes/global/reset-src';

/*---------------------------------
VENDOR
---------------------------------*/
@import './styles/vendor/font-awesome';
@import '../../core/themes/vendor/slick';
@import '../../core/themes/vendor/bootstrap-display';
@import '../../../node_modules/uikit/dist/css/uikit';

/*---------------------------------
CORE MIXINS
---------------------------------*/
@import '../../core/themes/mixins/breakpoints';
//@import '../../core/themes/mixins/font-size';
@import './styles/mixins/font-size';
@import './styles/mixins/variable';
@import '../../core/themes/mixins/bevel';
@import '../../core/themes/mixins/modifers';
@import '../../core/themes/mixins/background';
@import '../../core/themes/mixins/box-shadow';

/*---------------------------------
BASE
---------------------------------*/
@import '../../core/themes/base/typography';

/*---------------------------------
CORE PARTIALS
---------------------------------*/
@import '../../core/themes/partials/links';
@import '../../core/themes/partials/forms';
@import '../../core/themes/partials/headline';
@import '../../core/themes/partials/backgrounds';
@import '../../core/themes/partials/buttons';
@import '../../core/themes/partials/icon';
@import '../../core/themes/partials/navigation';
@import '../../core/themes/partials/picture';
@import '../../core/themes/partials/quantity';
@import '../../core/themes/partials/messages';
@import '../../core/themes/partials/modal';
@import '../../core/themes/partials/text';

/*---------------------------------
CORE COMPONENTS 
---------------------------------*/
@import '../../core/themes/components/agegate';
@import '../../core/themes/components/card';
@import '../../core/themes/components/cart';
@import '../../core/themes/components/cta';
@import '../../core/themes/components/carousel';
@import '../../core/themes/components/expander';
@import '../../core/themes/components/footer';
@import '../../core/themes/components/footer-large';
@import '../../core/themes/components/footer-small';
@import '../../core/themes/components/form';
@import '../../core/themes/components/header';
@import '../../core/themes/components/headline';
@import '../../core/themes/components/hero';
@import '../../core/themes/components/minicart';
@import '../../core/themes/components/messagebar';
@import '../../core/themes/components/offers';
@import '../../core/themes/components/productcard';
@import '../../core/themes/components/productfeatures';
@import '../../core/themes/components/producthero';
@import '../../core/themes/components/productlisting';
@import '../../core/themes/components/productsearch';
@import '../../core/themes/components/reviews';
@import '../../core/themes/components/sortfilter';
@import '../../core/themes/components/search';
@import '../../core/themes/components/section';
@import '../../core/themes/components/tabs';
@import '../../core/themes/components/video';

/*--------------------------------
LAYOUT
---------------------------------*/
@import '../../core/themes/layout/container';
@import '../../core/themes/layout/margin-modifiers';
@import '../../core/themes/layout/padding-modifiers';

/*---------------------------------
STYLES
---------------------------------*/
@import './styles/main';