.bat-agegate {
	//-----------------------------------------------------------------| Default
	&--default {
		.bat-modal {
			align-items: center;
			background-color: $blackish;
			background-image: url("/content/dam/glo/images/age-gate/glo-age-gate-mobile.jpg");
			background-position: center;
			background-repeat: no-repeat;
			@include media-breakpoint-up(sm) {
				background-image: url("/content/dam/glo/images/age-gate/glo-age-gate-desktop.jpg");
			}
			background-size: cover;
			height: calc(100% - #{$warning-height-mobile});
			@include media-breakpoint-up(md) {
				height: calc(100% - #{$warning-height});
			}
			.bat-modal-content {
				position: unset;
				top: 0;
			}
			justify-content: center;
		}

		&-content {
			padding: 18px 30px;
			position: relative;
			text-align: center;

			@include media-breakpoint-up(sm) {
				max-width: 668px;
			}

			@include media-breakpoint-up(sm) {
				padding: 40px 50px;
			}

			&-image {
				height: auto;
				left: 0;
				margin: 1% auto 20px;
				position: relative;
				top: 0;
				width: 70px;
				@include media-breakpoint-up(sm) {
					width: 66px;
				}
				z-index: 1;
			}

			&-dob-input {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				> * {
					flex-grow: 1;
				}
				> div {
					display: flex;
					flex-direction: column;
					max-width: 30%;
					label {
						margin-bottom: 6px;
						text-align: left;
						width: 100%;
					}
					&:last-child {
						margin-right: 0;
					}
				}
				margin-bottom: 20px;
				@include media-breakpoint-up(sm) {
					margin-bottom: 34px;
				}
			}

			&-greeting {
				position: relative;
				z-index: 2;

				&-headline {
					margin-bottom: $gutter;

					h2 {
						font-family: $age-gate-font;
						@include font-rem(26px, 26px);

						@include media-breakpoint-up(sm) {
							@include font-rem(32px, 32px);
						}
					}
				}

				&-text {
					color: $brand-black;
					margin-bottom: 25px;

					p {
						@include font-rem(14px, 20px);
					}
				}

				&-cta-list {
					align-items: center;
					display: flex;
					flex-direction: column-reverse;
					justify-content: center;
					@include media-breakpoint-up(sm) {
						flex-direction: row;
						margin: 0 10px;
						min-width: 158px;
						padding: 0 13px 38px;
					}

					margin-bottom: 26px;

					.bat-button {
						margin: 5px;
						min-width: 120px;
						padding: 13px 26px;

						@include media-breakpoint-up(sm) {
							margin: 5px 10px;
							min-width: 158px;
							padding: 13px 38px;
						}

						@include media-breakpoint-down(xs) {
							width: 100%;
						}
					}
				}

				&-footnote {
					margin: 0 auto;
					width: 100%;
					@include media-breakpoint-up(sm) {
						width: 70%;
					}

					p {
						@include font-rem(12px, 14px);
					}
				}
			}

			&-error-message {
				margin-top: 45px;
				position: relative;
				z-index: 2;
			}
		}
	}
}

@supports (
	(-webkit-backdrop-filter: blur(0.5em)) or (backdrop-filter: blur(0.5em))
) {
	.agegate-blurred {
		.bat-modal {
			-webkit-backdrop-filter: blur(0.5em);
			backdrop-filter: blur(0.5em);
			background-color: $dark-blue-80;
		}
	}
}
