.bat-indicator {
	align-items: center;
	border: solid 1px $light-gray;
	display: flex;
	justify-content: flex-start;
	margin: 5px;

	span {
		color: $dark-gray;
	}

	ul {
		color: $dark-gray;
		display: flex;
		flex: 1;
		justify-content: flex-end;
		list-style: none;
		margin: 0;
		padding: 0;

		li {
			@include font-rem(10px, 10px);
			border: solid 1px $dark-gray;
			border-radius: 10px;
			display: inline-block;
			height: 10px;
			margin: 0 1px;
			overflow: hidden;
			text-indent: -9999px;
			width: 10px;
		}
	}

	&.strength-4mg,
	&.strength-mild,
	&.strength-1 {
		li {
			&:nth-child(1) {
				background-color: $dark-gray;
			}
		}

		&.active {
			li {
				&:nth-child(1) {
					background-color: $white;
				}
			}
		}
	}

	&.strength-6mg,
	&.strength-medium,
	&.strength-2 {
		li {
			&:nth-child(-n + 2) {
				background-color: $dark-gray;
			}
		}

		&.active {
			li {
				&:nth-child(-n + 2) {
					background-color: $white;
				}
			}
		}
	}

	&.strength-10mg,
	&.strength-strong,
	&.strength-3 {
		li {
			&:nth-child(-n + 3) {
				background-color: $dark-gray;
			}
		}

		&.active {
			li {
				&:nth-child(-n + 3) {
					background-color: $white;
				}
			}
		}
	}

	&.strength-16mg,
	&.strength-x-strong,
	&.strength-4 {
		li {
			&:nth-child(-n + 4) {
				background-color: $dark-gray;
			}
		}

		&.active {
			li {
				&:nth-child(-n + 4) {
					background-color: $white;
				}
			}
		}
	}

	&.strength-18mg,
	&.strength-ultra,
	&.strength-5 {
		li {
			&:nth-child(-n + 5) {
				background-color: $dark-gray;
			}
		}

		&.active {
			li {
				&:nth-child(-n + 5) {
					background-color: $white;
				}
			}
		}
	}

	&.strength-20mg,
	&.strength-max,
	&.strength-6 {
		li {
			&:nth-child(-n + 6) {
				background-color: $dark-gray;
			}
		}

		&.active {
			li {
				&:nth-child(-n + 6) {
					background-color: $white;
				}
			}
		}
	}

	&--large {
		min-width: 150px;
		padding: 14px 13px;

		span {
			@include font-rem(12px, 12px);
			font-weight: 700;
			margin-right: 10px;
		}
	}

	&--small {
		align-items: center;
		flex-direction: column;
		justify-content: center;
		min-width: 104px;
		padding: 7px 6px;

		span {
			@include font-rem(10px, 10px);
			font-weight: 700;
			margin-bottom: 3px;
		}
	}

	&-bar {
		display: flex;
		flex-wrap: wrap;
	}

	// active button
	&.active {
		background-color: $dark-blue;
		border: solid 1px $dark-blue;

		span {
			color: $white;
		}

		ul {
			color: $white;

			li {
				border: solid 1px $white;
			}
		}
	}
}
