.portaunamico-hero{
    .bat-hero-gloit{
        background-color: $white;

        .bat-hero-gloit-content-container{
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
            margin: 0px;
            max-width: none;

            @include media-breakpoint-down(sm){
                display: block;
                .bat-hero-gloit-img{
                    padding: 0px;
                }
            }

            .bat-hero-gloit-img{
                position: initial;
            }
        }

        .bat-hero-gloit-content{
            padding: 0px;
            margin: auto;
            padding-left: 100px;

            @include media-breakpoint-down(sm){
                margin: 20px;
                margin-bottom: 0px;
                padding-left: 0px;
            }

            .bat-headline{
                margin-left: 0px;
                h1{
                    color: $black;
                    text-transform: uppercase;
                    font-weight: 800;
                    @include font-rem(34px, 39px);
                    width: 75%;
                    margin-bottom: 20px;

                    @include media-breakpoint-down(sm){
                        @include font-rem(26px, 32px);
                        width: 100%;
                        margin-bottom: 10px;
                    }
                }
            }

            .bat-hero-gloit-subheader{
                @include font-rem(20px, 20px);
                font-weight: 500;
                width: 45%;
                margin-left: 0px;

                @include media-breakpoint-down(sm){
                    @include font-rem(14px, 20px);
                    width: 75%;
                }
            }
        }
    }

    &.no-reverse .bat-hero-gloit-content-container {
        flex-direction: row;
    }
}