.gloitdiscover-hero{
	
		.bat-hero-gloit{
			.bat-hero-gloit-content-container{
				background-color: $white;
				display: flex;
				justify-content: space-between;
				margin: 0px;
				max-width: none;
	
				@include media-breakpoint-down(sm){
					display: block;
					padding-bottom: 0px;
					.bat-hero-gloit-img{
						padding: 0px;
					}
	
					.bat-cta-style{
						width: 100%;
						justify-content: center;
					}
				}
	
				.bat-hero-gloit-img{
					position: initial;
	
					picture{
						img{
							width: 980px;
							@include media-breakpoint-down(sm){
								width: 100%;
							}
						}
					}
				}
			}
	
			.bat-hero-gloit-content{
				display:none;
			}
		}
}