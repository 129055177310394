.artandmore-hero{
    .bat-hero-gloit{
        background-color: $brand-black;

        .bat-hero-gloit-content-container{
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
            margin: 0px;
            max-width: none;

            @include media-breakpoint-down(sm){
                display: block;
                .bat-hero-gloit-img{
                    padding: 0px;
                }
            }

            .bat-hero-gloit-img{
                position: initial;
            }
        }

        .bat-hero-gloit-content{
            padding: 0px;
            margin-left: 60px;
            margin-top: 100px;
            margin-right: 100px;

            @include media-breakpoint-down(sm){
                margin: 20px;
                margin-bottom: 0px;

                .title-content{
                    display: flex;

                    picture{
                        align-self: center;
                    }

                    .title-content-tile{
                        margin-left: 20px;
                        width: 100%;
                    }
                }
            }

            .bat-headline-top{
                color: $white;
                margin-top: 20px;
                @include font-rem(26px, 34px);

                @include media-breakpoint-down(sm){
                    @include font-rem(17px, 34px);
                    margin: 0px;
                }
            }

            .bat-headline{
                margin-left: 0px;
                h1{
                    color: $white;
                    @include font-rem(34px, 34px);

                    @include media-breakpoint-down(sm){
                        @include font-rem(23px, 34px);
                    }
                }
            }

            .infos-container{
                margin-top: 60px;
                &-info{
                    margin-right: 120px;

                    @include media-breakpoint-down(sm){
                        margin-right: 0px;
                        margin-bottom: 20px;
                        width: 50%;
                    }


                    .info{
                        color: $white;
                        display: block;
                        @include font-rem(24px, 28px);
                        font-weight: 800;

                        @include media-breakpoint-down(sm){
                            @include font-rem(18px, 20px);
                        }

                        p{
                            color: $white;
                            display: block;
                            @include font-rem(24px, 28px);
                            font-weight: 800;

                            @include media-breakpoint-down(sm){
                                @include font-rem(18px, 20px);
                            }
                        }
                    }
                }
            }
        }
    }
}