.bat-offers {
	
	&-content {
		
		&-login {
			background-color: $concrete;
			
			&-savings {
				
				&-count {
					
					color: $purple;
				}
			}
		}
	}
}
