// Live chat styling
.bat-livechat {
	:not(.authorMode) & {
		cursor: pointer;
		max-width: 1440px;
		position: fixed;
		z-index: 999;

		[id^=liveagent_button_online] {
			background: transparent;
			bottom: 0;
			cursor: pointer;
			height: 80px;
			margin-bottom: 111px;
			position: fixed;
			right: 8px;
			width: 80px;

			@include media-breakpoint-down(sm) {
				margin-bottom: 70px;
				right: 0;
			}

			[id^=liveagent_button_offline] {
				display: none;
			}
		}
	}
}
