//Links
.bat {
	// AEM text editor
	p {
		a {
			text-decoration: underline;

			&:hover {
				text-decoration: none;
			}
		}
	}

	// all other links
	a,
	.bat-link {
		color: inherit;
		display: inline-flex;
		font-size: inherit;
		text-decoration: none;
		transition: color 0.5s ease;

		@include media-breakpoint-up(lg) {
			&:hover {
				span {
					color: $purple;
					text-decoration: underline;
				}
			}
		}

		&--dark {
			color: $dark-blue;

			i {
				svg {
					fill: $dark-blue;
				}

				&:before {
					font-size: 13px;
				}
			}
		}

		&--light {
			color: $white;

			i {
				svg {
					fill: $white;
				}
			}
		}

		&--arrow--dark,
		&--arrow--light {
			@include font-rem(15px, 15px);
			font-family: $primary-font;
			font-weight: 700;
			letter-spacing: 1px;
			position: relative;

			&:after {
				content: "\e910";
				font-family: "icomoon";
				font-size: 26px;
				position: absolute;
				right: 0;
				transform: translateX(100%);
			}

			&:hover {
				&:after {
					-webkit-animation: bounce-arrow 1s infinite ease-in-out;
					-moz-animation: bounce-arrow 1s infinite ease-in-out;
					animation: bounce-arrow 1s infinite ease-in-out;
				}
			}
		}

		&--arrow--dark {
			color: $brand-black;

			&:hover {
				span {
					color: $brand-black;
					text-decoration: none;
				}
			}

			&:after {
				color: $brand-black;
			}
		}

		&--arrow--light {
			color: $white;

			&:hover {
				span {
					color: $white;
					text-decoration: none;
				}
			}

			&:after {
				color: $white;
			}
		}

		&.center {
			justify-content: center;
		}

		&.left {
			justify-content: flex-start;
		}

		&.right {
			justify-content: flex-end;
		}

		i {
			height: 20px;
			width: 20px;

			&:before {
				font-size: 13px;
			}
		}
	}
}

@-webkit-keyframes bounce-arrow {
	0% {
		transform: translateX(100%);
	}
	50% {
		transform: translateX(110%);
	}
	100% {
		transform: translateX(100%);
	}
}

@-moz-keyframes bounce-arrow {
	0% {
		transform: translateX(100%);
	}
	50% {
		transform: translateX(110%);
	}
	100% {
		transform: translateX(100%);
	}
}

@keyframes bounce-arrow {
	0% {
		transform: translateX(100%);
	}
	50% {
		transform: translateX(110%);
	}
	100% {
		transform: translateX(100%);
	}
}
