//TEXT
p {
	.dark,
	&.dark {
		color: $dark-blue;
	}

	.dark-gray,
	&.dark-gray {
		color: $dark-gray;
	}

	.light,
	&.light {
		color: $white;
	}
}

ol,
ul {
	.dark,
	&.dark {
		color: $dark-blue;
	}

	.dark-gray,
	&.dark-gray {
		color: $dark-gray;
	}

	.light,
	&.light {
		color: $white;
	}

	.no-style,
	&.no-style {
		list-style: none;
		margin: none;
		padding: none;
	}

	.arrow-bullets,
	&.arrow-bullets {
		list-style-type: none;
		margin: none;
		padding: none;

		li {
			padding-left: 34px;
			position: relative;

			&:before {
				color: $light-blue;
				content: "\e903";
				font-family: "icomoon";
				font-size: 24px;
				left: 0;
				position: absolute;
			}
		}
	}
}

.arrow-bullets {
	ul {
		list-style-type: none;
		margin: 0;
		padding: 0;
	}

	li {
		padding-left: 34px;
		position: relative;

		&:before {
			color: $light-blue;
			content: "\e903";
			font-family: "icomoon";
			font-size: 24px;
			left: 0;
			position: absolute;
		}
	}
}

.list-item-ticks {
	li {
		padding-bottom: 17px;
		list-style: url("/content/dam/glo-it/images/infoglo-card/icon-tick.svg");
	}
}

.list-circle-number {
	ol {
		list-style: none;
		counter-reset: my-awesome-counter;
		padding: 0;
	}

	li {
		counter-increment: my-awesome-counter;
		margin-bottom: 50px;
		padding-left: 60px;
		position: relative;
		font-size: 16px;
		line-height: 22px;
	}

	li::before {
		content: counter(my-awesome-counter);
		background: #e35205;
		width: 34px;
		height: 34px;
		border-radius: 100%;
		display: inline-block;
		line-height: 34px;
		font-size: 24px;
		font-weight: 700;
		color: white;
		text-align: center;
		margin-right: 24px;
		position: absolute;
		left: 0;
	}

	li::after {
		content: "";
		position: absolute;
		left: 16px;
		top: 42px;
		width: 0;
		height: 100%;
		border: 2px solid #aaaaaa;
	}

	li:last-child::after {
		border: unset;
	}
}

.solid-grey {
	border-top: 2px solid #dedede;
}

table {
	@include media-breakpoint-down(sm) {
		overflow-x: auto;
		display: block;
	}
	td {
		padding: 8px 10px;
		vertical-align: top;
		border: 1px solid $black;
	}
}

.image-in-text {
	h2 {
		img {
			max-height: 30px;
			@include media-breakpoint-down(sm) {
				max-height: 25px;
			}
		}
	}
}

p.price {
	@include font-rem(30px, 32px);
	font-weight: 800;
	color: $white;
	background-color: $orange;
	padding: 5px;
	width: fit-content;
}

p.price-full {
	@include font-rem(22px, 28px);
	font-weight: 600;
	color: $black;
	text-decoration: line-through;
	padding: 5px;
	width: fit-content;
}

p.price-full-white {
	@include font-rem(22px, 28px);
	font-weight: 600;
	color: $black;
	text-decoration: line-through;
	padding: 5px;
	width: fit-content;
	filter: invert(100%);
}

.personages {
	background-color: #010C57;
	.bat-text p {
		margin: 0;
		padding: 1%;
		@include media-breakpoint-down(md) {
			padding: 0;
			padding-bottom: 7%;
		}
	}
}

.bat-text {
	.modified-success {
		width: 100%;

		&-content {
			width: 100%;
			padding: 16px;
			gap: 10px;
			background: #dbffea;
			border-radius: 8px;
			font-size: 16px;
			line-height: 24px;
			align-items: flex-start;
		}

		&.error {
			.modified-success-content {
				background: #fdf3f5;
			}
		}

		&.info {
			.modified-success-content {
				background: #ebf2ff;
			}
		}

		&.warn {
			.modified-success-content {
				background: #fff2eb;
			}
		}
	}
}
