//BACKGROUND COLORS
.bg-light-blue {
	background: $light-blue;
}

.bg-purple {
	background: $purple;
}

.bg-green {
	background: $green;
}

.bg-red {
	background: $red;
}

.bg-tint-error {
	background: $tint-error;
}

.bg-tint-points {
	background: $tint-points;
}

.bg-tint-multibuy {
	background: $tint-multibuy;
}

.bg-dark-blue {
	background: $dark-blue;
}

.bg-extra-dark-blue {
	background: $extra-dark-blue;
}

.bg-concrete {
	background: $concrete;
}

.bg-light-gray {
	background: $light-gray;
}

.bg-gray {
	background: $gray;
}

.bg-dark-gray {
	background: $dark-gray;
}

.bg-black {
	background: $black;
}

.bg-white {
	background: $white;
}

.bg-bronze-gradient {
	background: $bronze-gradient;
	background-color: $bronze;
}

.bg-silver-gradient {
	background: $silver-gradient;
	background-color: $silver;
}

.bg-gold-gradient {
	background: $gold-gradient;
	background-color: $gold;
}

.bg-main-gradient {
	background: $main-gradient;
}

.bg-tooltip-stroke-gradient {
	background: $tooltip-stroke-gradient;
}

.bg-accent {
	background: $teal;
}

.bg-dark-gray-50 {
	background: $dark-gray-50;
}

.bg-orange {
	background: $orange;
}

.bg-brand-black {
	background: $brand-black;
}

.bg-charcoal {
	background: $charcoal;
}

.bg-blue {
	background: $blue;
}

.bg-blackish {
	background: $blackish;
}
