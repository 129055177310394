.plp-hero-gloit {

	.bat-hero-gloit {
		background-color: $brand-black;
		&-content {
			ul {
				margin-top: 1rem;
			}
	
			.bat-button {
				margin-top: 1.5rem;
			}
	
			&.light {
				p,
				h1,
				h2,
				h3,
				h4,
				h5,
				h6 {
					color: $white;
				}
			}
	
			@include media-breakpoint-down(sm) {
				.bat-cta-style {
					max-width: 100%;
					width: 100%;
				}
			}
		}
	
		&-content-container {
			max-width: none;
			margin: 0 auto;
			position: relative;
		}
	
		//-----------------------------------------------------------------| Product Detail Page
		&--pdp {
			align-items: flex-start;
			background-color: $dark-blue;
			box-sizing: border-box;
			display: flex;
			flex-direction: column;
			height: auto;
			justify-content: flex-end;
			padding: 0;
			position: relative;
			width: 100%;
	
			&-bottomsubheader {
				p {
					line-height: 1.25;
					margin-bottom: 0;
				}
			}
	
			&-content {
				margin-top: calc(
					-75px + ((-200) - (-75)) * ((100vw - 375px) / (768 - 375))
				);
				padding: 0 5% 5%;
				position: relative;
	
				&.light {
					h1,
					h2,
					h3,
					h4,
					h5,
					h6,
					li,
					p {
						color: $white;
					}
				}
	
				.bat-button {
					margin-top: 1.5rem;
				}
	
				p {
					margin-bottom: 0;
	
					& + p {
						margin-top: 10px;
					}
				}
			}
	
			&-cta {
				display: flex;
				width: 100%;
	
				&.right {
					justify-content: flex-end;
				}
			}
	
			&-headline {
				margin-bottom: 1.75rem;
			}
	
			&-topsubheader {
				ol {
					counter-reset: listCounter;
					list-style: none;
					margin-bottom: 1.75rem;
					padding: 0;
					li {
						margin-bottom: 48px;
						&:last-child {
							margin-bottom: 0;
						}
						span {
							align-items: center;
							display: flex;
							&[class*="bullet"] {
								&::before {
									flex-grow: 0;
									flex-shrink: 0;
									line-height: 1;
									margin-right: 2.25rem;
								}
							}
							&.bullet--numbered {
								&::before {
									background-color: $white;
									border-radius: 50%;
									color: $dark-blue;
									content: counter(listCounter);
									counter-increment: listCounter;
									font-size: 2.25rem;
									font-weight: 700;
									height: 64px;
									padding: 16px;
									text-align: center;
									width: 64px;
								}
							}
							&.bullet--icon-place {
								&::before {
									content: url("/content/dam/glo/images/pdp-hero/velo_lozenges_place-icon.png");
								}
							}
							&.bullet--icon-embrace {
								&::before {
									content: url("/content/dam/glo/images/pdp-hero/velo_lozenges_embrace-icon.png");
								}
							}
						}
					}
				}
				p {
					line-height: 1.25;
					margin-bottom: 3.375rem;
				}
			}
	
			.headline6-color1 {
				@extend .bat-headline-style.headline6-color1;
			}
	
			img {
				clip-path: polygon(0 0, 100% 0, 100% 80%, 0 55%);
				left: auto;
				position: static;
				top: auto;
				transform: none;
			}
	
			@include media-breakpoint-up(md) {
				flex-direction: row;
				height: 672px;
				justify-content: flex-start;
	
				&-content {
					flex: 1 2 670px;
					margin-top: 0;
					max-width: none;
					min-width: 490px;
					& > * {
						max-width: 490px;
					}
				}
	
				&-headline {
					margin-bottom: 1.75rem;
				}
	
				&-topsubheader {
					ol {
						margin-bottom: 3rem;
						max-width: 370px;
						li {
							margin-bottom: 40px;
						}
					}
					p {
						line-height: 1.25;
						margin-bottom: 3.375rem;
					}
				}
	
				& .padding-left-medium {
					padding-left: 44px;
				}
	
				& .padding-right-small {
					padding-right: 32px;
				}
	
				& .padding-top-large {
					padding-top: 80px;
				}
	
				picture {
					flex: 0 1 830px;
					height: 100%;
					max-width: 830px;
					img {
						// The calc() function makes that the clip-path scale and remain at an angle; otherwise, @ 769px, the angle is vertical
						// For more info about the formula/equation see: https://css-tricks.com/snippets/css/fluid-typography/
						clip-path: polygon(
							0 0,
							100% 0,
							calc(
									145px + (639 - 145) *
										((100vw - 769px) / (1920 - 769))
								)
								100%,
							0 100%
						);
	
						left: auto;
						position: static;
						top: auto;
						transform: none;
					}
				}
			}
		}
	}
}
	
bat-hero-default.plp-hero-gloit {
	// text-right class causes text to begin at center of component instead of left
	&.text-right {
		.bat-hero-content {
			@include media-breakpoint-up(md) {
				padding-left: 50%;
			}
		}
	}
	//masthead-right class causes text to align more to the right side
	&.masthead-right {
		.bat-hero {
			height: 501px;

			@include media-breakpoint-up(md) {
				height: 537px;
			}
		}
		.bat-hero-content {
			bottom: 5%;
			padding: 20px;
			position: absolute;

			.bat-headline-style {
				padding-bottom: 20px;
			}

			@include media-breakpoint-up(md) {
				bottom: auto;
				padding-left: 75%;
				top: 10%;
			}
			@include media-breakpoint-up(lg) {
				padding-left: 67%;
			}
		}
	}
	
}

bat-hero-gloit.plp-hero-gloit {
	&.lilac .bat-hero-gloit {
		background-color: $brand-black;
		color: $brand-black;
		.bat-hero-gloit-disclaimer p {
			color: $brand-black;
		}
	}
	.bat-hero-gloit {
		background-color: $brand-black;
		color: $brand-black;
		&.dark {
			background-color: $brand-black;
			color: $white;
			.bat-hero-gloit-subheader p,
			.bat-hero-gloit-disclaimer p {
				color: $white;
			}
		}

		.bat-hero-gloit-subheader {
			color: #fff;
			margin-top: 15px;
			@include media-breakpoint-up(lg) {
				margin-left: 15px;
			}
			
		}

		&-img {
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			overflow: hidden;
			@include media-breakpoint-down(sm) {
				padding: 0;
				max-width: 100%;
				right: 0;
			}
			picture {
				width: fit-content;
				img {
					height: 20%;
					left: 0;
					min-width: unset;
					object-fit: contain;
					object-position: top left;
					top: 0;
					transform: none;
				}
			}
		}

		&-content {
			.bat-topSubheader{
				margin-left: 15px;
				align-items: center;
				color: $brand-black;
				font-size: 18px;
				font-weight: 900;
				line-height: 23px;
				font-family: $primary-font;

				&-image{
					padding-left: 15px;
				}

				@include media-breakpoint-down(sm){
					margin-left: 0px;
					&-text{
						width: 35%;
						font-size: 14px;
						line-height: 18px;
					}
				}
			}

			.bat-headline {
				max-width: 450px;
				padding: 25px 0px 0px;
				margin-left: 15px;

				.bat-headline-style {
					@include font-rem(34px, 39px);
					font-family: $primary-font;
					font-weight: 900;
					width: 90%;
					@include media-breakpoint-down(sm) {
						margin-top: -60px;
					}
					color: $white;
				}

				@include media-breakpoint-down(sm){
					margin-left: 0px;
					@include font-rem(24px, 28px);
					width: 75%;
				}
			}

			padding: 80px 20px 80px 50%;

			@include media-breakpoint-down(sm) {
				padding: 66% 20px 40px;

				.bat-hero-subheader p {
					line-height: 21px;
				}

				.bat-headline .bat-headline-style {
					@include font-rem(24px, 28px);
				}
			}
			.bat-cta-style {
				margin-left: 15px;

				@include media-breakpoint-down(sm) {
					margin-left: 0px;
					width: fit-content;
				}
				&.arrow-link-dark,
				&.arrow-link-light {
					margin-top: 12px;
				}
			}
		}
		&-disclaimer {
			margin-top: 100px;
			font-weight: 600;
			line-height: 20px;
			margin-left: 15px;
			p {
				@include font-rem(12px, 20px);
				color: $charcoal;
			}
			@include media-breakpoint-down(sm) {
				margin-left: 0px;
				p{
					font-size: 10px;
				}
				margin-top: 30px;
			}
		}
	}
}
