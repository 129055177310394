.bat-minicart {
	background-color: $concrete;
	border: 1px solid $dark-gray-50;
	bottom: 0;
	height: auto;
	margin-bottom: 0;
	max-height: none;
	padding: 0 12px;
	position: fixed;
	right: 0;
	top: 0;
	z-index: $modal-zindex;

	@include media-breakpoint-up(md) {
		max-width: 540px;
		padding-inline: 48px;
	}

	&-count {
		@include font-rem(22px, 26px);
		padding: 30px 0 22px;
		text-align: center;
		@include media-breakpoint-up(md) {
			text-align: left;
		}
	}

	&-close {
		left: 12px;
		position: absolute;
		top: 28px;

		i {
			&:before {
				color: $dark-blue;
				transition: color 0.5s ease;
			}
		}

		&:hover {
			i {
				&:before {
					color: $light-blue;
				}
			}
		}
	}

	&-wrapper {
		background-color: $concrete;
		padding: 0 0 60px;

		hr {
			border: 0;
			border-top: 1px solid $dark-blue;
			margin-bottom: 20px;
		}
	}

	&-buttons {
		display: block;

		@include media-breakpoint-up(sm) {
			display: flex;
		}
	}

	&-button-edit {
		margin-block: 25px 0;
		width: 100%;
		.bat-button {
			&.bat-cta-style {
				&.button-dark {
					max-width: 100%;
					width: 100%;
				}
			}
		}
	}

	&-subtotal-value,
	&-subtotal-label {
		@include font-rem(16px, 20px);
		font-weight: 700;
		width: 49%;
	}

	&-subtotal-label {
		@include font-rem(12px, 12px);
		letter-spacing: 1px;
		position: relative;
		top: -2px;
	}

	&-subtotal-value-quantity {
		@include font-rem(14px, 20px);
		font-weight: normal;
		margin-left: 0.5em;
	}

	&-cart-item-container {
		margin-top: 0;
		position: relative;

		&.active {
			&:after {
				-webkit-animation: spin 2s linear infinite; /* Safari */
				animation: spin 2s linear infinite;
				border: 10px solid $light-gray;
				border-radius: 50%;
				border-top: 10px solid $purple;
				bottom: 0;
				content: "";
				height: 80px;
				left: 0;
				margin: auto;
				position: absolute;
				right: 0;
				top: 0;
				width: 80px;
				z-index: 1;
			}

			.bat-minicart-cart-item {
				opacity: 0.3;
				pointer-events: none;
			}
		}
	}

	&-cart-item {
		align-items: center;
		background-color: $white;
		margin-bottom: 5px;
		opacity: 1;
		transition: opacity 0.5s ease;

		&-product-container {
			&-title {
				@include font-rem(14px, 17px);
				color: $dark-blue;
				display: flex;
				font-weight: normal;
				margin-bottom: 0.5em;

				.remove-item {
					display: none;
					height: 28px;
					margin-left: auto;
					margin-top: -5px;
					width: 28px;

					i {
						@extend .icon;
						@extend .icon-cross;
						color: $purple;
						font-size: 28px;
						transition: color 0.5s ease;
					}

					&:hover {
						i {
							color: $purple-hover;
						}
					}
				}
			}

			&-strength {
				@include font-rem(14px, 17px);
				color: $dark-gray;
			}

			&-nicotine-level,
			&-flavor,
			&-delivery {
				@extend p;
			}

			&-unit-price {
				@include font-rem(16px, 16px);
				bottom: 0;
				color: $dark-blue;
				margin: 0;
				position: absolute;
				right: 1rem;
			}

			&-quantity-label {
				@include font-rem(12px, 12px);
				color: $dark-blue;
				display: block;
				font-weight: bold;
				margin-bottom: 2px;
				width: 100%;
			}

			&-quantity {
				margin-top: 10px;

				.update-item {
					margin-left: 20px;
					margin-top: 0;
					width: auto;

					span {
						@include font-rem(12px, 12px);
						color: $purple;
						transition: color 0.5s ease;
					}

					&:hover {
						span {
							color: $purple-hover;
						}
					}
				}
			}

			&-delivery {
				@include font-rem(12px, 12px);
				margin-bottom: 20px;

				span {
					font-weight: 700;
				}
			}

			&-option {
				@include font-rem(14px, 14px);
				color: $dark-gray;
				margin-bottom: 10px;

				h6 {
					@include font-rem(12px, 12px);
				}

				p {
					@include font-rem(12px, 12px);
				}
			}
		}
	}
}

/* Safari */
@-webkit-keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
