.videotext{
	display: flex;
    flex-direction: row;
    max-width: 100%;
    margin: auto;
    height: 780px;
    padding: 0px 48px;
    gap: 74px;
    margin-left: 5%;
    margin-right: 5%;
    width: 100%;
}

.videotext .videoContainer{
	width: 50%;
    align-self: center;
    height: 65%;
}
.videotext .videoContainer iframe{
	max-width: 100%;
    max-height: 100%;
    min-height: 100%;
    width: 100%;
    height: auto;
    margin: auto;
}
.videotext .textContainer{
    display: flex;
    flex-direction: column;
    width: 50%;
    align-self: center;
    justify-content: start;
    gap: 40px;
	margin-top: 10%;
	margin-right: 1%;

}
.videotext .textContainer h3{
    color: #FFF;
    font-feature-settings: 'liga' off, 'clig' off;
    text-shadow: 0px 4px 35px #000;
    font-family: Mont;
    font-size: 50px;
    font-style: normal;
    font-weight: 400;
    line-height: 60px; /* 120% */
    
}
.videotext .textContainer p{
    color: #FFF;
    text-shadow: 0px 4px 35px #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 120% */
}
.videotext .bottomText{
	display: none;
}
@media screen and (max-width:780px) {
	.videotext{
		display: flex;
		flex-direction: column-reverse;
		height: unset;
		gap: 0px;
		margin-left: 0px;
		padding: 0px;
	}
	.videotext .textContainer{
		width: 100%;
        height: unset;
        padding: 48px 0px;
        align-items: center;
        text-align: center;
	}
	.videotext .textContainer h3{
		color: #FFF;
        font-feature-settings: 'liga' off, 'clig' off;
        font-family: Mont;
        font-size: 26px;
        font-style: normal;
        font-weight: 400;
        line-height: 31px;
		
	}
	.videotext .textContainer p{
		display: none;
	}
	.videotext .bottomText{
		display: flex;
		margin: auto;
		justify-content: center;
	}
	.videotext .bottomText p{
		color: #FFF;
        text-shadow: 0px 4px 35px #000;
        font-size: 14px !important;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        padding-bottom: 48px;
        text-align: center;
        letter-spacing: normal;
	}
	.videotext .videoContainer{
		width: 100%;
		padding-bottom: 48px;
	}
	.videotext .videoContainer iframe{
		width:100%;
		height: 100%;
	}
}
