.gloitlep.bat-carousel{


	max-width: unset !important;
	.slick-side{
		float: unset;
		width: 100% !important;
	}
	.bat-carousel-slides{
		max-width: unset;
		width: 100% !important;

		.slick-track{
			width: unset;
		}
	}
}
bat-carousel-gloitlep{
	.bat-carousel{
		max-width: unset;
		width: 100%;
	}
	.bat-carousel-slides {
		scroll-behavior: smooth;
	}
	
	.bat-carousel-slide {
		opacity: 0;
		transform: translateY(100px);
	}
	
	.bat-carousel-slide.slick-active {
		transform: translateY(0);
	}
	.slick-vertical .slick-slide{
		border: none !important;
	}
	.slick-dots{
		position: relative; 
		display: flex !important;
		flex-direction: column !important;

	}
	@media screen and (max-width:780px) {
		.slick-dots{
			display: none !important;
		}
	}
	
}
