.sidecard-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include media-breakpoint-down(xl){
        display: block;
    }
}

bat-card-gloitside {
    height: 100%;
    position: relative;
}

.bat-sidecard-gloit{
    background-color: $concrete;
    margin-left: 15px;
    justify-content: space-between;
    height: 100%;

    @include media-breakpoint-down(xl){
        margin: 5px 0px;
    }

    .zoom-animation {
        pointer-events: none;
        transition: transform 2s;
    }

    &:hover {
        .zoom-animation {
            transform: scale(1.2);
        }
    }

    &-cta-hover {
        position: absolute;
        height: 100%;
        width: 100%;
        &:hover~.bat-sidecard-gloit {
            .zoom-animation {
                transform: scale(1.2);
            }
        }
    }

    &-container{
        &-title{
            @include font-rem(18px, 23px);
            text-transform: uppercase;
            font-weight: 800;
            margin-top: 20px;
            margin-bottom: 20px;
        }

        h3 {
            @include font-rem(18px, 23px);
            text-transform: uppercase;
            font-weight: 800;
            margin-top: 20px;
            margin-bottom: 20px;
        }

        &-subtitle{
            @include font-rem(14px, 20px);

            @include media-breakpoint-down(xl){
                display: block
            }
        }

        &-cta{
            @include font-rem(15px, 28px);
            letter-spacing: 0.4px;
            margin-top: 20px;
        }

        &-price{
            .promo-price{
                @include font-rem(19px, 24px); 
                font-weight: 800;
                background-color: $orange;
                color: $white;
                padding: 8px;
                margin-right: 10px
            }

            .full-price{
                @include font-rem(13px, 17px);
                font-weight: 700;
                text-decoration: line-through;
            }
        }

        .price{
            @include font-rem(19px, 24px); 
            font-weight: 800;
            background-color: $orange;
            color: $white;
            padding: 8px;
            margin-right: 10px
        }

        .price-full{
            @include font-rem(13px, 17px);
            font-weight: 700;
            text-decoration: line-through;
        }

        &-ctas {
            @include media-breakpoint-up(sm){
                transition: transform .5s ease-out;
                &:hover {
                    transform: translateX(50px);
                }
            }			
        }
    }

    &.double-card{
        padding: 23px 15px;

        @include media-breakpoint-down(xl){
            padding: 15px 30px;
        }
        
        .bat-sidecard-gloit-container{
            &-image{
                picture{
                    margin-left: auto;
                    width: 130px;

                    @include media-breakpoint-down(xl){
                        margin-left: auto;
                        max-width: none;
                    }
                }
            }
        }
    }

    &.single-card{
        
        padding: 49px 15px;
        flex-direction: column-reverse;
    
        @include media-breakpoint-down(xl){
            flex-direction: row;
            padding: 15px 30px;
        }
    
        .bat-sidecard-gloit-container{
            &-image{
                picture{
                    margin-left: auto;
                    max-width: fit-content;
                    width: 156px;

                    @include media-breakpoint-down(xl){
                        margin-left: auto;
                        max-width: none;
                    }
                }
            }
        }
    }
}