.bat-newhyper-hero {
    display: flex;
    gap: 40px;
    padding-inline: 20px;
    padding-top: 30px;
    background-position-y: 80%;

    .zoom-animation {
        transition: transform 2s;
        width: 50%;
        @include media-breakpoint-down(sm){
        width: 100%;
        }
        &:hover {
            transform: scale(1.1);
        }
    }

    .content {
        font-size: 50px;
        line-height: 50px;
        font-weight: 500;
        color: $white;
        padding-top: 30px;

        div{
            sup{
                font-size: 0.3em;
                vertical-align: super;
            }
        }

        .title {

            .logo{
                margin-bottom: 40px;
            }

        }


        div {
            margin-bottom: 25px;
        }
        .description{
            color: #FFF;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px; /* 120% */
            span{
                font-weight: 600;
            }
        }
    }

    .mobile-only {
        display: none;
    }

}

@include media-breakpoint-down(md) {
    .bat-newhyper-hero {
        flex-direction: column;
        align-items: center;

        img {
            order: 0;
            max-width: 356px;
            position: relative;
            top: -30px;
            margin-bottom: -40px;
        }

        .logo{
            margin-bottom: -10px;
            margin-top: 20px;
        }

        .content {
            order: 0;
            font-size: 28px;
            line-height: 30px;
            font-weight: 400;
            text-align: center;
            padding-top: 0;

            div {
                margin-bottom: 13px;
            }

            .description{
                color: #FFF;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 19px;
                span{
                    font-weight: 600;
                }
            }
        }

        .mobile-only {
            display: flex;
        }
    }
}