
h1,
h2 {
  font-weight: 400;
  max-width: none;
}

.comparisonSection {
position: relative;
overflow: hidden; /* Nascondi il contenuto in eccesso */
height: 100vh; /* Imposta l'altezza a 100% della viewport */
width: 100%; /* Assicurati che la larghezza sia al 100% */
}


.comparisonImage {
  width: 100%;
  height: 100%;
}

.middleImage, .afterImage {
  position: absolute;
  overflow: hidden;
  top: 0;
  transform: translate(0, 100%);
}

.middleImage .slide-card, .afterImage .slide-card {
  transform: translate(0, -10%);
}

.comparisonImage .slide-card {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}

.panel {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 90vh;
}

.slide-card {
  display: flex;
  flex-direction: column;
  background-size: cover;
  overflow: hidden !important;
  padding-top: 7%;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  min-width: 100%;

	  h2{
		  color: #FFF;
		  text-align: center;
		  font-feature-settings: 'liga' off, 'clig' off;
		  font-family: Mont;
		  font-size: 185px;
		  font-style: normal;
		  font-weight: 400;
		  line-height: 24px; /* 12.973% */
		  padding-bottom: 2%;
	  }
	  h5{
		  color: #FFF;
		  text-align: center;
		  font-feature-settings: 'liga' off, 'clig' off;
		  font-family: Mont;
		  font-size: 20px;
		  font-style: normal;
		  font-weight: 400;
		  line-height: 24px; /* 120% */
	  }
	  button{
		  display: flex;
		  width: 179px;
		  height: 40px;
		  flex-shrink: 0;
		  border-radius: 100px;
		  background: var(--Glo-CTA-Gradient, linear-gradient(268deg, #CD4A04 0.65%, #ED7D30 100%));
		  border: none;
		  color: white;
		  align-items: center;
		  justify-content: center;
		  margin: auto;
		  cursor: pointer;
		  margin-top: 48px;
	  }

	  button span{
		  text-wrap: nowrap;
	  }

	  img{
		  align-content: center;
		  margin-top: 200%;
		  position: unset;
		  margin: auto;
		  margin-right: 45%;
		  max-width: 600.243px;
		  max-height: 600.243px;
		  transform: rotate(6.799deg);
		  position: relative;
		  bottom: -200px;
		  margin-bottom: -3px;
	  }
	  .hidden{
		  display: none;
	  }
	  
}






  
	  
  

.bat-section {
	.border-all & {
		border: solid 1px $light-gray;
	}
	
	.border-top & {
		border-top: solid 1px $light-gray;
	}
	
	.border-bottom & {
		border-bottom: solid 1px $light-gray;
	}
}

#webseriesSection{
	.headline2{
		font-family: Mont;
		font-size: 30px;
		font-style: italic;
		font-weight: 900;
		line-height: 34px;
		letter-spacing: 0px;
		text-align: left;
		color: #FFFFFF;
	}

	.bat-text p {
		font-family: Mont;
		font-size: 14px;
		font-weight: 600;
		line-height: 20px;
		letter-spacing: 0px;
		text-align: left;
		color: #FFFFFF;
	}
}

#registerDeviceLogged{
	.bat-section-content{
		max-width: initial;
	}

	.hero-container{
		.bat-hero-gloit-content{
			height: 400px;
			@include media-breakpoint-down(md) {
				height: 400px;
				padding-top: 72%;
			}
		}

		
		img{
			height: 360px;
    		margin-left: -25px;
			@include media-breakpoint-down(md) {
				height: auto;
				margin-left: 0;
			}
		}
		.bat-headline{
			@include media-breakpoint-down(md) {
				width: 90%;
			}
			.bat-headline-style{
				width: 87%;
				@include media-breakpoint-down(md) {
					width: 90%;
				}
			}
		}
	}
}


#registerDevice{

	.hero-container{
		.bat-hero-gloit-content{
			@include media-breakpoint-down(sm) {
				padding-top: 72%;
			}
		}

		
		img{
			height: 300px;
    		margin-left: 40px;
			@include media-breakpoint-down(md) {
				height: auto;
				margin-left: 0;
			}
		}
		.bat-headline{
			@include media-breakpoint-down(md) {
				width: 90%;
			}
			.bat-headline-style{
				width: 87%;
				@include media-breakpoint-down(md) {
					width: 90%;
				}
			}
		}
	}
}
body {
	.bat-wrapper {
		bat-section-default,
		bat-section-gloitimagegrid,
		bat-section-gloitthreegrid {
			height: 100%;

			// Nested
			&.steps {
				.responsivegrid {
					.responsivegrid {
						
						@include media-breakpoint-up(md) {
							border-right: 1px solid $white;
						}
						
						&:last-child {
							border: 0;
						}
					}
				}
			}
			&.steps-inverted {
				.responsivegrid {
					.responsivegrid {
						
						@include media-breakpoint-up(md) {
							border-right: 1px solid $black;
						}
						
						&:last-child {
							border: 0;
						}
					}
				}
			}
			&.tabs-section {
				margin-bottom: 0;
		
				@include media-breakpoint-up(md) {
					height: 525px;
					margin-right: -3.5px;
					min-height: 500px;
				}
				
				.bat-section-content {
					@include media-breakpoint-down(md) {
						padding: 40px 25px;
					}
				}
			}
			// News Section HP
			&.bat-news-wrapper {
				margin-bottom: 45px;
				padding-top: 62px;
			}
			// Article / Blog Styling
			&.blog-wrapper {
				.bat-section-content {
					@include media-breakpoint-up(md) {
						background-color: $white;
						margin: 0 auto;
						max-width: 50%;
						padding: 3.5rem 100px;
					}
				}
				p {
					color: $dark-gray;
					font-size: 15px;
					line-height: 22px;
				}
			
			}
				
			
			&.blog-wrapper-with-image {
				.bat-section {
					background-color: $concrete;
					padding-top: 260px;

					@include media-breakpoint-down(sm) {
						padding-top: 0;
					}
				}
				.bat-section-content {
					background-color: $white;
					margin: -428px auto 0;
					max-width: 50%;
					padding: 3.5rem 100px;
					
					@include media-breakpoint-down(lg) {
						margin: -368px auto 0;
					}
					@include media-breakpoint-down(md) {
						margin: -344px auto 0;
						max-width: 56%;
						padding: 3.5rem 20px;
					}
					@include media-breakpoint-down(sm) {
						margin: 0 auto;
						max-width: 100%;
						padding: 3.5rem 20px;

					}
				}
				p {
					color: $dark-gray;
					font-size: 15px;
					line-height: 22px;
				}
			}

			&.inner-full-width {
				.bat-section-content {
					max-width: none;
					> .responsivegrid {
						max-width: none !important;
					}
				}
			}

			&.bat-section-vertical-center {
				.aem-Grid {
					align-items: center;
				}
			}
			&.bat-section-mobile-inverted {
				@include media-breakpoint-down(sm) {
					>.bat-section > .bat-section-content > .responsivegrid > .aem-Grid {
						flex-direction: column-reverse;
					}
				}
			}
			&.bat-section-tablet-inverted {
				@include media-breakpoint-down(lg) {
					>.bat-section > .bat-section-content > .responsivegrid > .aem-Grid {
						flex-direction: column-reverse;
						@media (min-width: $breakpoint-md) and (max-width: ($breakpoint-xl - 1)) {
							>.aem-GridColumn {
								width: 100%;
							}
						}
					}
				}
			}
			&.bat-section-tablet-column {
				@include media-breakpoint-down(lg) {
					>.bat-section > .bat-section-content > .responsivegrid > .aem-Grid {
						flex-direction: column;
						@media (min-width: $breakpoint-md) and (max-width: ($breakpoint-xl - 1)) {
							>.aem-GridColumn {
								width: 100%;
							}
						}
					}
				}
			}
			&.bat-section-desktop-column {
				>.bat-section > .bat-section-content > .responsivegrid > .aem-Grid {
					flex-direction: column; 
				}
			}
			&.bat-section-image-flex {
				div.image.aem-GridColumn {
					display: flex;
				}
			}
			&.height-unset {
				height: unset;
			}
			&.mobile-boxed {
				@include media-breakpoint-down(sm) {
					margin-left: 20px;
					margin-right: 20px;

					&.margin-small,
					.margin-small {
						margin-bottom: 20px;
					}
				}
			}
			&.news-filter-grid {
				.bat-section > .bat-section-content > .responsivegrid > .aem-Grid {
					row-gap: 50px;
				}

				bat-card-gloitnews {
					height: 100%;
					width: 90%;

					.bat-cardnews-gloit--news-img {
						max-height: unset;
					}

					.bat-cardnews-gloit--news-main {
						padding-left: 20px;
    					padding-right: 20px;
						
						.bat-cardnews-gloit--news-title {
							padding-top: 0;
						}
					}
				}
			}

			.bat-three-grid-content {
				max-width: unset;
			}

			.bat-three-grid-content-carousel {
				
				.slick-track {
					display: flex !important;
				}

				.slick-slide {
					height: inherit !important;
					padding-left: 20px;

					&:last-child {
						padding-right: 20px;
					}
				}

				&.mobile-app {

					.slick-slide {
						padding-left: 8px;

						&:nth-child(1) {
							padding-left: 20px;
						}
					}
				}
			}
			&.thr-carousel-desktop-bkg{
				background-image: url('/content/dam/glo-it/images/thr_pages/new-thr/thrCarouselDesktopBg.png');
				background-repeat: no-repeat;
				background-size: cover;
				.bat-section-content{
					max-width: 100% !important;
    				width: 100% !important;
				}
				.responsivegrid.rootTemplateGrid{
                    max-width: unset;

                    .carousel {
                        display: flex;
                        justify-content: center;

                        bat-carousel-gloitthrslider {
                            @media (min-width: 991px) {
                                max-width: 1200px;
                            }
                            width: 100%;
                        }
                    }

					&:nth-child(2) {
						max-width: 100% !important;
					}
				}
			}
			&.thr-carousel-mobile-bkg{
				background-image: url('/content/dam/glo-it/images/thr_pages/new-thr/BAT_HyperPro_KV_PhaseI_Boost_Mode_Master2.png');
				background-repeat: no-repeat;
				background-size: cover;
				.responsivegrid.rootTemplateGrid{
					margin-left:2% !important;
				}
			}
			&.thr-video-bkg{
				background-image: url('/content/dam/glo-it/images/thr_pages/new-thr/videoSectionBkg.png');
				background-repeat: no-repeat;
				background-size: cover;
			}
			&.thr-video-bkg{
				background-image: url('/content/dam/glo-it/images/thr_pages/new-thr/videoSectionBkg.png');
				background-repeat: no-repeat;
				background-size: cover;
			}
		}
	}
}
//-----------------------------------------------------------------| Error PAge
.error-page-section {
	background-image: url('/content/dam/glo/za/en/500page/500error_background.png');
	background-repeat: no-repeat;
	color: $white;

	@include media-breakpoint-down(md) {
		background-position: 100% 95%;
		// sass-lint:disable no-important
		background-size: 75% !important;
		margin-bottom: 90%;
	}

	@include media-breakpoint-down(sm) {
		$centering-adjustment: 10%;
		background-position: #{100% - $centering-adjustment} 100%;
		// sass-lint:disable no-important
		background-size: #{100% + (2 * $centering-adjustment)} !important;
		margin-bottom: 90%;
	}

	@include media-breakpoint-up(md) {
		align-items: center;
		background-position: 100% 70%;
		// sass-lint:disable no-important
		background-size: 50% !important;
		display: flex;
		min-height: 600px;
	}

	@media (min-width: 1024px) {
		.bat-section {
			padding-left: 16.66%;
		}
	}
}

bat-section-default.full-height {
	min-height: 100vh;
}

bat-section-default.bat-section-horizontal-center {
	.aem-Grid {
		justify-content: center;
	}
}

bat-section-default.max-width-content {
	.bat-section-content{
		max-width: 100% !important;
	}
}

bat-section-default.light-background {
	background-image: url("/content/dam/glo-it/images/hyper-range-background-light.png");
	background-size: cover;
	background-repeat: no-repeat;
}

.authorMode {
	bat-section-default.full-height {
		min-height: 100%;
	}
}

//-----------------------------------------------------------------| BlogHub
.bat-blog-hub {
	.padding-left-medium {
		padding-left: 0;
	}
	.padding-right-medium {
		padding-right: 0;
	}
	.margin-medium {
		@include media-breakpoint-down(xs) {
			margin-bottom: 0;
		}
	}
}

bat-section-gloitimagegrid {
	img {
		object-fit: cover;
	}
}

bat-section-gloitgrid {
	background-color: #e9edf5;
	margin-top: 80px;
    margin-bottom: 80px;

	.bat-section-gloit {
		max-width: 880px;
		margin: 0 auto;
		padding-top: 100px;
		padding-bottom: 100px;
		position: relative;

		@include media-breakpoint-down(md) {
			margin-left: 20px;
			margin-right: 20px;
			padding-top: 318px;
			padding-bottom: 318px;
		}

		.upper-element {
			margin-bottom: 30px;
    		margin-right: -5%;
			position: absolute;
			top: -10%;

			@include media-breakpoint-down(md) {
				margin-right: 0;
				top: -2%;
			}
		}

		.lower-element {
			margin-top: 30px;
    		margin-left: -7%;
			position: absolute;
			bottom: -10%;

			@include media-breakpoint-down(md) {
				margin-left: 0;
				bottom: -2%;
			}
		}
	}
}

bat-section-gloitgridlanding {
	background-color: white;
	//margin-top: 80px;
    //margin-bottom: 80px;

	.bat-section-gloit {
		margin: 0 auto;
		//padding-top: 100px;
		//padding-bottom: 100px;
		position: relative;
		left: 18px;
    	max-width: 1350px;

		@include media-breakpoint-down(md) {
			margin-left: 20px;
			margin-right: 20px;
			//padding-top: 318px;
			//padding-bottom: 318px;
			left: 0px;
		}

		.uk-grid-margin{
			@include media-breakpoint-down(md) {
				margin-bottom: 0px;
			}
		}

		.upper-element {
			margin-bottom: 30px;
    		margin-right: -5%;
			position: absolute;
			top: -10%;

			@include media-breakpoint-down(md) {
				margin-right: 0;
				top: -2%;
			}
		}

		.lower-element {
			margin-top: 30px;
    		margin-left: -7%;
			position: absolute;
			bottom: -10%;

			@include media-breakpoint-down(md) {
				margin-left: 0;
				bottom: -2%;
			}
		}
	}
}

.padding-for-video {
	padding: 0 40px;

	@include media-breakpoint-down(md) {
		padding: 0;
	}
}

.video-mobile-extended {
	@include media-breakpoint-down(md) {
		.bat-video-content {
			padding: 30px 15px;
		}
	}
}
