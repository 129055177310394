// sass-lint:disable no-duplicate-properties
//-----------------------------------------------------------------| DIN OT

/**
 * @license
 * MyFonts Webfont Build ID 3859487, 2020-01-23T13:10:28-0500
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: FF DIN Std Light by FontFont
 * URL: https://www.myfonts.com/fonts/fontfont/ff-din/ot-light/
 *
 * Webfont: FF DIN Std Regular by FontFont
 * URL: https://www.myfonts.com/fonts/fontfont/ff-din/ot-regular/
 *
 * Webfont: FF DIN Std Medium by FontFont
 * URL: https://www.myfonts.com/fonts/fontfont/ff-din/ot-medium/
 *
 * Webfont: FF DIN Std Black Italic by FontFont
 * URL: https://www.myfonts.com/fonts/fontfont/ff-din/ot-black-italic/
 *
 *
 * Licensed pageviews: 1,000,000
 * Webfonts copyright: 2016 published by Monotype GmbH
 *
 * © 2020 MyFonts Inc
*/

@font-face {
	font-family: "DIN";
	font-style: normal, italic;
	font-weight: 400;
	src: url("../fonts/din/3AE41F_1_0.eot");
	src: url("../fonts/din/3AE41F_1_0.eot?#iefix") format("embedded-opentype"),
		url("../fonts/din/3AE41F_1_0.woff2") format("woff2"),
		url("../fonts/din/3AE41F_1_0.woff") format("woff"),
		url("../fonts/din/3AE41F_1_0.ttf") format("truetype");
	font-display: swap;
}

@font-face {
	font-family: "DIN";
	font-style: normal;
	font-weight: 700;
	src: url("../fonts/din/3AE41F_2_0.eot");
	src: url("../fonts/din/3AE41F_2_0.eot?#iefix") format("embedded-opentype"),
		url("../fonts/din/3AE41F_2_0.woff2") format("woff2"),
		url("../fonts/din/3AE41F_2_0.woff") format("woff"),
		url("../fonts/din/3AE41F_2_0.ttf") format("truetype");
	font-display: swap;
}

@font-face {
	font-family: "DIN";
	font-style: italic;
	font-weight: 700;
	src: url("../fonts/din/3AF91A_0_0.eot");
	src: url("../fonts/din/3AF91A_0_0.eot?#iefix") format("embedded-opentype"),
		url("../fonts/din/3AF91A_0_0.woff2") format("woff2"),
		url("../fonts/din/3AF91A_0_0.woff") format("woff"),
		url("../fonts/din/3AF91A_0_0.ttf") format("truetype");
	font-display: swap;
}

@font-face {
	font-family: "DIN";
	font-style: italic;
	font-weight: 700;
	src: url("../fonts/din/3AE41F_3_0.eot");
	src: url("../fonts/din/3AE41F_3_0.eot?#iefix") format("embedded-opentype"),
		url("../fonts/din/3AE41F_3_0.woff2") format("woff2"),
		url("../fonts/din/3AE41F_3_0.woff") format("woff"),
		url("../fonts/din/3AE41F_3_0.ttf") format("truetype");
	font-display: swap;
}

@import url("//fonts.googleapis.com/css?family=Lato&subset=latin,latin-ext&display=swap"); // sass-lint:disable-line no-url-protocols

//-----------------------------------------------------------------| VELOSans FONT

@font-face {
	font-family: "VELOSans";
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url("../fonts/velo-sans/VELOSans-Bold.eot"); /* IE9 Compat Modes */
	src: local("VELOSans Bold"), local("VELOSans-Bold"),
		url("../fonts/velo-sans/VELOSans-Bold.eot?#iefix")
			format("embedded-opentype"),
		/* IE6-IE8 */ // scss-lint:no-duplicate-properties
			url("../fonts/velo-sans/VELOSans-Bold.woff2") format("woff2"),
		/* Super Modern Browsers */ // scss-lint:no-duplicate-properties
			url("../fonts/velo-sans/VELOSans-Bold.woff") format("woff"),
		/* Modern Browsers */ // scss-lint:no-duplicate-properties
			url("../fonts/velo-sans/VELOSans-Bold.ttf") format(
				"truetype"
			); /* Safari, Android, iOS */ // scss-lint:no-duplicate-properties
	// url('../fonts/velo-sans/VELOSans-Bold.svg#VELOSans') format('svg'); /* Legacy iOS */
}

@font-face {
	font-family: "VELOSans";
	font-style: normal;
	font-weight: 500;
	font-display: swap;
	src: url("../fonts/velo-sans/VELOSans-Medium.eot"); /* IE9 Compat Modes */
	src: local("VELOSans Medium"), local("VELOSans-Medium"),
		url("../fonts/velo-sans/VELOSans-Medium.eot?#iefix")
			format("embedded-opentype"),
		/* IE6-IE8 */ url("../fonts/velo-sans/VELOSans-Medium.woff2")
			format("woff2"),
		/* Super Modern Browsers */
			url("../fonts/velo-sans/VELOSans-Medium.woff") format("woff"),
		/* Modern Browsers */ url("../fonts/velo-sans/VELOSans-Medium.ttf")
			format("truetype"); /* Safari, Android, iOS */
	// url('../fonts/velo-sans/VELOSans-Medium.svg#VELOSans') format('svg'); /* Legacy iOS */
}

@font-face {
	font-family: "VELOSans";
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url("../fonts/velo-sans/VELOSans-Regular.eot"); /* IE9 Compat Modes */
	src: local("VELOSans Regular"), local("VELOSans-Regular"),
		url("../fonts/velo-sans/VELOSans-Regular.eot?#iefix")
			format("embedded-opentype"),
		/* IE6-IE8 */ url("../fonts/velo-sans/VELOSans-Regular.woff2")
			format("woff2"),
		/* Super Modern Browsers */
			url("../fonts/velo-sans/VELOSans-Regular.woff") format("woff"),
		/* Modern Browsers */ url("../fonts/velo-sans/VELOSans-Regular.ttf")
			format("truetype"); /* Safari, Android, iOS */
	// url('../fonts/velo-sans/VELOSans-Regular.svg#VELOSans') format('svg'); /* Legacy iOS */
}

@font-face {
	font-family: "Gotham";
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: local("Gotham Bold"),
		url("../fonts/gotham/Gotham-Bold.otf") format("opentype");
}

@font-face {
	font-family: "Gotham";
	font-style: normal;
	font-weight: 300;
	font-display: swap;
	src: local("Gotham Light"),
		url("../fonts/gotham/Gotham-Light.otf") format("opentype");
}

@font-face {
	font-family: "Mont";
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: local("Mont Bold"),
		url("../fonts/mont/Mont-Bold.ttf") format("truetype");
}

@font-face {
	font-family: "Mont";
	font-style: normal;
	font-weight: 200;
	font-display: swap;
	src: local("Mont ExtraLight"),
		url("../fonts/mont/Mont-ExtraLight.ttf") format("truetype");
}

@font-face {
	font-family: "Mont";
	font-style: normal;
	font-weight: 900;
	font-display: swap;
	src: local("Mont Heavy"),
		url("../fonts/mont/Mont-Heavy.ttf") format("truetype");
}

@font-face {
	font-family: "Mont";
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: local("Mont Regular"),
		url("../fonts/mont/Mont-Regular.ttf") format("truetype");
}

@font-face {
	font-family: "Trim Poster";
	font-style: normal;
	font-weight: 900;
	font-display: swap;
	src: local("Trim Poster Hefty"),
		url("../fonts/trim-poster/TrimPoster-Hefty.otf") format("truetype");
}
