.bat-mobilenavcta-gloit{
	width: 100%;
	background: #2A2C2C;
	box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.15);
	border-radius: 20px 20px 0px 0px;
    position: fixed;
    bottom: 0;
	left: 0;
	z-index: 999;

	&-container {
		align-items: center;
		padding: 20px;

		&-item {
			position: relative;
			opacity: 0.5;
			width: 100%;
			padding-bottom: 25px;

			&.active {
				opacity: 1;

				.inactive-icon {
					display: none;
				}

				.active-icon {
					display: block;
				}
			}

			&-icons {
				margin: 0 auto;

				.inactive-icon {
					display: block;
				}

				.active-icon {
					display: none;
				}
			}

			&-text {
				position: absolute;
				color: $white;
				font-weight: 500;
				font-size: 12px;
				line-height: 15px;
				bottom: 0;
				width: 100%;
				text-align: center;
			}
		}
	}

	.bat-cta-style {
		margin: 0;
	}

	&-placeholder {
		height: 87px;
		width: 100%;
	}
}