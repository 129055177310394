.gloitmclaren-hero{
    .bat-hero-gloit{
        background-color: $brand-black;

        .bat-hero-gloit-content-container{
            display: flex;
            justify-content: space-between;
            margin: 0px;
            max-width: none;

            @include media-breakpoint-down(sm){
                display: block;
                padding-bottom: 10px;
                .bat-hero-gloit-img{
                    padding: 0px;
                }

                .bat-cta-style{
                    width: 100%;
                    justify-content: center;
                }
            }

            .bat-hero-gloit-img{
                position: initial;
                //margin-top: 35px;

                picture{
                    img{
                        width: 700px;
                        @include media-breakpoint-down(sm){
                            width: 95%;
                        }
                    }
                }
            }
        }

        .bat-hero-gloit-content{
            padding: 0px;
            margin-top: 100px;
            margin: auto;
            margin-left: 150px;
            margin-right: auto;

            @include media-breakpoint-down(sm){
                margin: 20px 20px;
                text-align: center;
            }

            .title-content{
                picture{
                    margin-bottom: 20px;
                }

                @include media-breakpoint-down(sm){
                    text-align: left;
                }
            }

            .bat-headline{
                margin-left: 0px;

                @include media-breakpoint-down(sm){
                    width: 85%;
                }
              
                h2{
                    text-align: left;
                    text-transform: uppercase;
                    color: $white;
                    font-weight: 800;
                    @include font-rem(34px, 39px);
                    //margin-bottom: 20px;
                    //width: 100%;

                    @include media-breakpoint-down(sm){
                        @include font-rem(26px, 32px);
                        width: 100%;
                    }
                }
            }

            .bat-hero-gloit-disclaimer{
                color: $white;
                margin-left: 0px;
                margin-top: 40px;
                font-weight: 500;
                @include font-rem(12px, 20px);

                @include media-breakpoint-down(sm){
                    @include font-rem(10px, 20px);
                    text-align: left;
                }
            }
        }
    }
}