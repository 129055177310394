.bat-hero-gloit {
	&-content {
		ul {
			margin-top: 1rem;
		}

		.bat-button {
			margin-top: 1.5rem;
		}

		&.light {
			p,
			h1,
			h2,
			h3,
			h4,
			h5,
			h6 {
				color: $white;
			}
		}

		@include media-breakpoint-down(sm) {
			.bat-cta-style {
				max-width: 100%;
				width: 100%;
			}
		}
	}

	&-content-container {
		max-width: $site-max-width;
		margin: 0 auto;
		position: relative;

		&.left {
			width: 100%;
		}
	}

	//-----------------------------------------------------------------| Product Detail Page
	&--pdp {
		align-items: flex-start;
		background-color: $dark-blue;
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		height: auto;
		justify-content: flex-end;
		padding: 0;
		position: relative;
		width: 100%;

		&-bottomsubheader {
			p {
				line-height: 1.25;
				margin-bottom: 0;
			}
		}

		&-content {
			margin-top: calc(
				-75px + ((-200) - (-75)) * ((100vw - 375px) / (768 - 375))
			);
			padding: 0 5% 5%;
			position: relative;

			&.light {
				h1,
				h2,
				h3,
				h4,
				h5,
				h6,
				li,
				p {
					color: $white;
				}
			}

			.bat-button {
				margin-top: 1.5rem;
			}

			p {
				margin-bottom: 0;

				& + p {
					margin-top: 10px;
				}
			}
		}

		&-cta {
			display: flex;
			width: 100%;

			&.right {
				justify-content: flex-end;
			}
		}

		&-headline {
			margin-bottom: 1.75rem;
		}

		&-topsubheader {
			ol {
				counter-reset: listCounter;
				list-style: none;
				margin-bottom: 1.75rem;
				padding: 0;
				li {
					margin-bottom: 48px;
					&:last-child {
						margin-bottom: 0;
					}
					span {
						align-items: center;
						display: flex;
						&[class*="bullet"] {
							&::before {
								flex-grow: 0;
								flex-shrink: 0;
								line-height: 1;
								margin-right: 2.25rem;
							}
						}
						&.bullet--numbered {
							&::before {
								background-color: $white;
								border-radius: 50%;
								color: $dark-blue;
								content: counter(listCounter);
								counter-increment: listCounter;
								font-size: 2.25rem;
								font-weight: 700;
								height: 64px;
								padding: 16px;
								text-align: center;
								width: 64px;
							}
						}
						&.bullet--icon-place {
							&::before {
								content: url("/content/dam/glo/images/pdp-hero/velo_lozenges_place-icon.png");
							}
						}
						&.bullet--icon-embrace {
							&::before {
								content: url("/content/dam/glo/images/pdp-hero/velo_lozenges_embrace-icon.png");
							}
						}
					}
				}
			}
			p {
				line-height: 1.25;
				margin-bottom: 3.375rem;
			}
		}

		.headline6-color1 {
			@extend .bat-headline-style.headline6-color1;
		}

		img {
			clip-path: polygon(0 0, 100% 0, 100% 80%, 0 55%);
			left: auto;
			position: static;
			top: auto;
			transform: none;
		}

		@include media-breakpoint-up(md) {
			flex-direction: row;
			height: 672px;
			justify-content: flex-start;

			&-content {
				flex: 1 2 670px;
				margin-top: 0;
				max-width: none;
				min-width: 490px;
				& > * {
					max-width: 490px;
				}
			}

			&-headline {
				margin-bottom: 1.75rem;
			}

			&-topsubheader {
				ol {
					margin-bottom: 3rem;
					max-width: 370px;
					li {
						margin-bottom: 40px;
					}
				}
				p {
					line-height: 1.25;
					margin-bottom: 3.375rem;
				}
			}

			& .padding-left-medium {
				padding-left: 44px;
			}

			& .padding-right-small {
				padding-right: 32px;
			}

			& .padding-top-large {
				padding-top: 80px;
			}

			picture {
				flex: 0 1 830px;
				height: 100%;
				max-width: 830px;
				img {
					// The calc() function makes that the clip-path scale and remain at an angle; otherwise, @ 769px, the angle is vertical
					// For more info about the formula/equation see: https://css-tricks.com/snippets/css/fluid-typography/
					clip-path: polygon(
						0 0,
						100% 0,
						calc(
								145px + (639 - 145) *
									((100vw - 769px) / (1920 - 769))
							)
							100%,
						0 100%
					);

					left: auto;
					position: static;
					top: auto;
					transform: none;
				}
			}
		}
	}
}

bat-hero-default,
bat-hero-gloitbackground {
	&.house-of-gloers-black-friday {
		.bat-hero {
			@include media-breakpoint-down(md) {
				height: 408px !important;
				max-width: 348px;
				img {
					max-height: 408px;
					max-width: 336px;
					margin-inline: auto;
				}
			}
			height: 309px !important;
			max-width: 1140px;
			margin-inline: auto;
			margin-bottom: 60px;
			margin-top: 40px;
			.bat-hero-content {
				@include media-breakpoint-down(md) {
					padding-left: 24px !important;
				}
				padding-left: 53% !important;
				.subtitle {
					margin-top: 10px;
				}
			}
		}
		.bat-cta-style.button-secondary-dark {
			@include media-breakpoint-down(md) {
				width: 295px !important;
			}
			background: white !important;
			border: white !important;
			width: 216px !important;
			span {
				@include media-breakpoint-down(md) {
					font-size: 16px !important;
				}
				color: black !important;
				color: var(--glo-2024-Black, #000);
				font-size: 13px;
				font-style: normal;
				font-weight: 800;
				line-height: 140%; /* 18.2px */
			}
			&:hover {
				background: white;
				border: white;
				opacity: 0.7;
			}
		}
	}
	// text-right class causes text to begin at center of component instead of left
	&.text-right {
		.bat-hero-content {
			@include media-breakpoint-up(md) {
				padding-left: 50%;
			}
		}
	}
	//masthead-right class causes text to align more to the right side
	&.masthead-right {
		.bat-hero {
			height: 100%;

			@include media-breakpoint-up(md) {
				height: 537px;
			}

			img {
				@include media-breakpoint-down(md) {
					position: relative;
					left: 0;
					top: 0;
					transform: unset;
				}
			}
		}
		.bat-hero-content {
			bottom: -5%;
			padding: 20px;
			position: absolute;

			.bat-headline-style {
				padding-bottom: 20px;

				@include media-breakpoint-down(md) {
					padding-bottom: 0;
				}
			}

			.headline1-large-light {
				@include font-rem(65px, 75px);

				@include media-breakpoint-down(md) {
					@include font-rem(6vw, 7vw);
					letter-spacing: 0.4px;
				}
			}

			@include media-breakpoint-up(md) {
				padding-left: 75%;
				display: flex;
				top: 0;
				bottom: 0;
				margin: auto 0;
				align-items: center;
			}
			@include media-breakpoint-up(lg) {
				padding-left: 60%;
			}
		}

		&.fit-to-image {
			.bat-hero {
				height: 100%;

				@include media-breakpoint-up(md) {
					height: 100%;
				}

				img {
					position: relative;
					left: 0;
					top: 0;
					transform: unset;
				}
			}
		}
	}

	&.align-hero-content {
		.bat-hero-content {
			display: flex;
			flex-direction: column;
			justify-content: center;
		}
	}

	&.direction-column {
		.bat-hero-content {
			flex-direction: column;
			align-items: flex-start;
			justify-content: center;

			@include media-breakpoint-down(md) {
				bottom: 0;
			}
		}

		h1 {
			@include media-breakpoint-down(md) {
				line-height: 34px;
			}
		}

		p {
			display: inline-block;
		}

		.logo-hyper-pro {
			position: static;
			width: 350px;
			margin-top: 10px;
			transform: unset;
			object-fit: contain;

			@include media-breakpoint-down(md) {
				width: 240px;
				position: static;
			}
		}

		.bat-hero-content-container {
			gap: 15px;

			.bat-cta-style {
				width: 200px;
				display: flex;
				justify-content: center;

				@include media-breakpoint-down(md) {
					padding: 10px 20px;
					width: 159px;
				}
			}

			.button-secondary-dark {
				& span {
					color: $white;
				}
			}
		}
	}

	&.hero-hog-contest {
		.bat-hero-content {
			padding-left: 50% !important;
		}
	}
}

bat-hero-gloit {
	&.lilac .bat-hero-gloit {
		background-color: $lilac;
		color: $brand-black;
		.bat-hero-gloit-disclaimer p {
			color: $brand-black;
		}
	}

	&.fit-to-image .bat-hero-gloit-content {
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	.bat-hero-gloit {
		background-color: $concrete;
		color: $brand-black;
		&.dark {
			background-color: $brand-black;
			color: $white;
			.bat-hero-gloit-subheader p,
			.bat-hero-gloit-disclaimer p {
				color: $white;
			}
		}

		&-img {
			position: absolute;
			top: 0;
			left: 0;
			right: 57%;
			@include media-breakpoint-down(sm) {
				max-width: 100%;
				right: 0;
			}
			&-hero1 {
				width: 100%;
			}
			picture {
				width: 100%;

				img {
					height: 20%;
					left: 0;
					min-width: unset;
					object-fit: contain;
					object-position: top left;
					top: 0;
					transform: none;
				}
			}
		}

		&-content {
			.bat-topSubheader {
				margin-left: 15px;
				margin-bottom: 25px;
				align-items: center;
				color: $brand-black;
				font-size: 18px;
				font-weight: 900;
				line-height: 23px;
				font-family: $primary-font;

				&-image {
					padding-left: 15px;
				}

				@include media-breakpoint-down(sm) {
					margin-left: 0px;
					&-text {
						width: 35%;
						font-size: 14px;
						line-height: 18px;
					}
				}
			}

			.bat-hero-gloit-subheader {
				margin-left: 15px;
				align-items: center;
				color: $brand-black;
				font-size: 18px;
				font-weight: 900;
				line-height: 23px;
				font-family: $primary-font;

				@include media-breakpoint-down(sm) {
					margin-left: 0px;
					&-text {
						width: 35%;
						font-size: 14px;
						line-height: 18px;
					}
				}
			}

			.bat-headline {
				max-width: 450px;
				margin-left: 15px;

				.bat-headline-style {
					@include font-rem(34px, 39px);
					font-family: $primary-font;
					font-weight: 900;
					width: 90%;
				}

				@include media-breakpoint-down(sm) {
					margin-left: 0px;
					@include font-rem(24px, 28px);
					width: 75%;
				}
			}

			padding: 80px 20px 50px 50%;

			@include media-breakpoint-down(sm) {
				padding: 80% 20px 20px;

				.bat-hero-subheader p {
					line-height: 21px;
				}

				.bat-headline .bat-headline-style {
					@include font-rem(24px, 28px);
				}
			}
			.bat-cta-style {
				margin-left: 15px;

				@include media-breakpoint-down(sm) {
					margin-left: 0px;
					width: fit-content;
				}
				&.arrow-link-dark,
				&.arrow-link-light {
					margin-top: 12px;
				}
			}
		}
		&-disclaimer {
			margin-top: 100px;
			font-weight: 600;
			line-height: 20px;
			margin-left: 15px;
			p {
				@include font-rem(12px, 20px);
				color: $charcoal;
			}
			@include media-breakpoint-down(sm) {
				margin-left: 0px;
				p {
					font-size: 10px;
				}
				margin-top: 30px;
			}
		}
	}
}

.herobanner-container {
	@include media-breakpoint-down(xl) {
		display: block;
	}

	.hero-container {
		width: 100%;

		.bat-hero-gloit {
			height: 100%;
			&-img {
				&.with-sidecard {
					right: 46%;
					@include media-breakpoint-down(sm) {
						right: 0;
					}
					& + .bat-hero-gloit-content {
						padding-left: 56%;
						@include media-breakpoint-down(sm) {
							padding: 80% 20px 20px;
						}
					}
				}
				picture {
					margin-left: auto;
					@include media-breakpoint-down(xl) {
						margin-left: 0px;
					}
					.plp-hero-gloit & {
						margin-left: 0;
					}
				}
			}
		}
	}

	@keyframes uk-slide-left-small {
		0% {
			transform: translateX(-15%);
		}
		100% {
			transform: translateX(0);
		}
	}
}
