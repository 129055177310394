bat-card-gloitlepgloers{
	.gloers{
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		background-size: 100% 100%;
		height: 50vw;
		.content{
			display: flex;
			flex-direction: column;
			margin-right: auto;
			margin-left: 5%;
			width: 40%;
			gap: 32px;
			h2{
				color: #FFF;
				font-feature-settings: 'liga' off, 'clig' off;
				text-shadow: 0px 4px 35px #000;
				font-family: Mont;
				font-size: 50px;
				font-style: normal;
				font-weight: 400;
				line-height: 50px; /* 100% */
			}
			p{
				color: #FFF;
				font-feature-settings: 'liga' off, 'clig' off;
				text-shadow: 0px 4px 35px #000;
				font-family: Mont;
				font-size: 20px;
				font-style: normal;
				font-weight: 400;
				line-height: 24px; /* 120% */
				color: white;
			}
			a{
				text-wrap: nowrap;
				height: 42px;
				width: 187px;
			}
		}
		@media screen and (max-width: 780px){
			.content{
				margin: auto;
				text-align: center;
				display: flex;
				flex-direction: column;
				width: 80%;
				gap: 32px;
				padding-block: 20px;
				max-height: 100%;
				position: relative;
				h2{
					margin-bottom: 15%;
					color: #FFF;
					font-feature-settings: 'liga' off, 'clig' off;
					text-shadow: 0px 4px 35px #000;
					font-family: Mont;
					font-size: 26px;
					font-style: normal;
					font-weight: 400;
					line-height: 26px;
				}
				p{
					margin-top: 50%;
					color: #FFF;
					font-feature-settings: 'liga' off, 'clig' off;
					text-shadow: 0px 4px 35px #000;
					font-family: Mont;
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: 19px;
					color: white;
				}
				a{
					text-wrap: nowrap;
					margin: auto;
					height: 42px;
					width: 172px;
				}
			}
		}
		@media screen and (max-width: 380px){
			.content{
				text-wrap: nowrap;
				align-self: center;
				justify-content: center;
				text-align: center;
				display: flex;
				flex-direction: column;
				width: 100%;
				gap: 32px;
				padding: 0px 8px;
				padding-block: 20px;
				max-height: 100%;
				position: relative;

				h2{
					color: #FFF;
					font-feature-settings: 'liga' off, 'clig' off;
					text-shadow: 0px 4px 35px #000;
					font-family: Mont;
					font-size: 26px;
					font-style: normal;
					font-weight: 400;
					line-height: 26px;
				}
				p{
					margin-top: 50%;
					color: #FFF;
					font-feature-settings: 'liga' off, 'clig' off;
					text-shadow: 0px 4px 35px #000;
					font-family: Mont;
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: 19px;
					color: white;
				}
				a{
					text-wrap: nowrap;
					margin: auto;
					height: 42px;
					width: 172px;
				}
			}
		}
	}
	@media screen and (max-width: 780px){
		.gloers{
			height: fit-content;
		}
	}
	@media screen and (max-width: 380px){
		.gloers{
			height: fit-content;
		}
	}
}