.bat-logo {
	&--top {
		@include media-breakpoint-down(sm) {
			display: none;
		}

		@include media-breakpoint-up(md) {
			display: inline-flex;
		}
	}

	&--bottom {
		@include media-breakpoint-down(sm) {
			display: block;
		}

		@include media-breakpoint-up(md) {
			display: none;
		}
	}
}

.bat-footer-gloit{
	// main content
	&-main {
		color: $gray;
		padding: 40px 20px 154px;
		position: relative;

		@include media-breakpoint-down(sm) {
			overflow: hidden;
			padding-bottom: 0;
		}

		@include media-breakpoint-up(lg) {
			padding: 42px 0 40px;
		}
	}

	background-color: $brand-black;

	&--row {
		display: flex;
		justify-content: space-between;
		margin-left: auto;
		margin-right: auto;
		max-width: $max-width-xxl;
		position: relative;

		@include media-breakpoint-down(sm) {
			flex-direction: column;
		}

		&:last-child {
			margin-top: 40px;
			z-index: 10;
		}
	}
	&-top {
		background-color: $charcoal;
		padding: 12px 62px;

		@include media-breakpoint-down(sm) {
			padding: 0 20px;
		}
		&-content {
			align-items: center;
			display: flex;
			height: 100%;
			justify-content: space-between;
			margin-left: auto;
			margin-right: auto;
			max-width: $max-width-xxl;

			a.bat-footer-glo-top-content-logo {
				display: none;
			}

			@include media-breakpoint-up(md) {
				padding-left: 20px;
				a.bat-footer-glo-top-content-logo {
					display: unset;
				}
			}

			@include media-breakpoint-down(xxl) {
				padding-left: 0;
			}

			p {
				color: $white;
				margin: 0;
			}
		}
	}

	&-location-indicator {
		align-items: center;
		color: $white;
		display: flex;
		flex-direction: row;
		@include font-rem(14px, 16px);

		&-location {
			color: $concrete;
			font-weight: 700;
			padding-left: 20px;
		}

		i {
			display: none;
		}

		@include media-breakpoint-down(sm) {
			justify-content: space-between;
			padding-bottom: 0;
			padding-top: 0;
			width: 100%;

			a i {
				display: inherit;
				order: 0;
				&::before {
					font-size: 30px;
				}
			}

			&-location {
				order: 1;
				padding-left: 0;
			}

			&-descriptor {
				order: 2;
				visibility: hidden;
				width: 20px;
			}
		}

		&-descriptor {
			color: $light-gray;
			text-transform: capitalize;
		}
	}
	&-copyright {
		@include media-breakpoint-up(md) {
			display: none;
		}
		margin-left: auto;
		margin-right: auto;
		margin-top: 27px;
		width: fit-content;
	}

	&-logo {
		left: 0;
		margin: 5% auto 0;
		max-width: 100px;
		padding: 0;
		position: relative;
		right: 0;
		width: 100%;
		z-index: 1;
		a {
			width: 100%;
		}

		@include media-breakpoint-up(md) {
			left: 0;
			margin: 0;
			position: absolute;
			top: 45px;
		}

		@include media-breakpoint-up(lg) {
			left: 60px;
		}

		&.footer-logo {
			&--bottom {
				@include media-breakpoint-down(sm) {
					display: block;
				}

				@include media-breakpoint-up(md) {
					display: none;
				}
			}

			&--top {
				@include media-breakpoint-down(sm) {
					display: none;
				}

				@include media-breakpoint-up(md) {
					display: block;
				}
			}
		}
	}

	&-nav {
		display: flex;
		flex: none;
		flex-direction: column;
		flex-wrap: wrap;
		justify-content: space-between;
		padding: 0;
		position: relative;
		width: 100%;

		@include media-breakpoint-down(sm) {
			flex-direction: column;
		}

		@include media-breakpoint-up(md) {
			flex-direction: row;
		}

		// .bat-footer-glo-nav-menu
		&-menu {
			display: flex;
			flex-direction: column;
			flex-wrap: wrap;
			width: 100%;

			@include media-breakpoint-down(sm) {
				min-width: unset;
				width: 100%;
			}

			@include media-breakpoint-up(md) {
				width: 28.3%;
			}

			@include media-breakpoint-up(lg) {
				padding-right: 83px;
			}

			@include media-breakpoint-down(sm) {
				&:first-of-type {
					.bat-footer-glo-nav-menu-title {
						&:before {
							background-color: $gray;
							content: "";
							height: 2px;
							left: 0;
							position: absolute;
							top: 0;
							width: 100%;

							@include media-breakpoint-down(sm) {
								height: 1px;
							}
						}
					}
				}
			}

			// .bat-footer-glo-nav-menu-title
			&-title {
				@include font-rem(16px, 32px);

				color: $white;

				display: flex;
				font-family: $primary-font;
				font-weight: 700;
				letter-spacing: 20%;
				padding: 10px $gutter 5px 0;
				position: relative;
				text-align: left;

				@include media-breakpoint-down(sm) {
					@include font-rem(13px, 26px);
					justify-content: space-between;
					order: 1;
					padding: 10px 10px 5px 0;
				}

				&:after {
					background-color: $gray;
					bottom: 0;
					content: "";
					height: 2px;
					left: 0;
					position: absolute;
					width: 100%;

					@include media-breakpoint-down(sm) {
						height: 1px;
					}
				}

				i {
					&:before {
						content: "\e94d";
						font-family: "icomoon";
						transition: content 0.5s ease-in-out;
					}

					&.icon-plus {
						&:before {
							content: "\e959";
						}
					}

					@include media-breakpoint-down(sm) {
						display: inline-flex;
					}

					@include media-breakpoint-up(md) {
						display: none;
					}
				}
			}
		}

		ul {
			margin-bottom: 30px;
			padding-left: 0;

			@include media-breakpoint-up(lg) {
				margin-bottom: 8px;
			}

			li {
				display: flex;
				list-style-type: none;
				margin-bottom: 5px;
				text-align: left;

				.opening-hours {
					font-weight: 700;
					margin-top: 10px;
				}

				a {
					&,
					.no-link {
						@include font-rem(14px, 27px);

						color: $gray;
						font-family: $primary-font;
						font-weight: 400;
						padding: 0;
						transition: color 0.1s ease-in;
					}
					&:hover {
						text-decoration: underline;
					}
				}

				i {
					&:before {
						font-size: 20px;
						line-height: 20px;
					}
				}
			}
		}
	}

	// bat-footer-glo-submenu
	&-submenu {
		display: none;
		margin-top: 15px;

		@include media-breakpoint-down(sm) {
			display: none;
			order: 2;
		}

		@include media-breakpoint-up(md) {
			display: block;
		}

		.submenu-content {
			align-items: flex-start;
			flex-direction: row;

			.bat-icon {
				display: inline-flex;

				&:before {
					content: "\e920";
				}

				&.icon-chevron-up {
					content: "\e92c";
				}
			}
		}

		.glo-certificate{
			margin-top: 15%;
			width: 50%;

			@include media-breakpoint-down(sm) {
				display: none;
			}
		}

		li {
			flex-direction: column;
			position: relative;
		}

		// bat-footer-glo-submenu-child
		&-child {
			display: none;
			margin: 15px 0;
			position: absolute;
			top: 30px;

			@include media-breakpoint-down(md) {
				top: 25px;
			}

			li {
				position: relative;
			}
		}
	}

	&-fixed-spacer {
		height: $warning-height-mobile;

		@include media-breakpoint-up(md) {
			height: $warning-height;
		}
	}

	&-disclaimer {
		@include media-breakpoint-down(sm) {
			margin-top: 5%;
		}
		@include media-breakpoint-up(md) {
			padding-left: 0;
			padding-right: 20px;
		}
		@include media-breakpoint-down(xxl) {
			padding-left: 0;
			padding-right: 0;
		}

		p {
			@include font-rem(11px, 14px);
			color: $gray;
			margin: 0 auto 15px;
			max-width: $max-width-xxl;
		}
	}

	.bat-footer-social-nav {
		display: block;
		margin-top: 48px;
		width: 100%;

		@include media-breakpoint-down(sm) {
			margin-top: 0;
			order: 0;
		}

		ul {
			li {
				&:first-child {
					display: none;
				}
				a {
					&:hover {
						text-decoration: none;
					}
					i {
						height: 24px;
						margin-right: 16px;
						width: 24px;
						font-family: 'Font Awesome 5 Free';
						&:before {
							color: $gray;
							font-size: 24px;
						}
					}
				}
				&:last-child a i {
					margin-right: 0;
				}
			}

			@include media-breakpoint-down(sm) {
				justify-content: center;
			}
		}
	}

	.bat-footer-payment-icons {
		align-items: center;
		background: $charcoal;
		color: $white;
		height: 54px;
		justify-content: center;
		margin-top: 40px;

		.payment-icons & {
			display: flex;
		}

		svg,
		img {
			height: auto;
			margin: 0 18px;
		}

		.visa &--visa {
			display: block;
			width: 40px;
		}

		.mastercard &--mastercard {
			display: block;
			width: 29px;
		}

		.paypal &--paypal {
			display: block;
			width: 54px;
		}
	}
}

.ava-nav-menu {
	&:last-child {
		padding-right: 0;

		i {
			@include media-breakpoint-down(md) {
				display: none;
			}
		}

		.menu-title {
			&::after {
				@include media-breakpoint-down(sm) {
					background-color: transparent;
				}
			}
		}

		.submenu {
			@include media-breakpoint-down(md) {
				display: block;
			}
		}
	}
}

.modal_popup_merch {
	margin: 110px auto !important;
}