.bat-label {
	background-color: $charcoal;
	display: inline-block;
	padding: 5px 20px;

	p {
		@include font-rem(10px, 10px);
		color: $white;
		font-weight: 700;
		letter-spacing: -0.2px;
		margin: 0;
	}

	&--subscription {
		background-color: transparent;
		border-radius: 10px;
		min-width: 128px;
		overflow: hidden;
		padding: 11px 20px;
		position: relative;

		p {
			@include font-rem(12px, 12px);
			color: $blackish;
			letter-spacing: 0.1em;
			position: relative;
			text-align: center;
			z-index: 2;
		}

		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			@include font-rem(34px, 34px);
			color: $blackish;
			position: relative;
			text-align: center;
			z-index: 2;
		}

		&:after {
			-ms-transform: skew(-45deg);
			-ms-transform-origin: 100% 0;
			-webkit-transform: skew(-45deg);
			-webkit-transform-origin: 100% 0;
			content: "";
			height: 100%;
			position: absolute;
			right: -30px;
			top: 0;
			transform: skew(-45deg);
			transform-origin: 100% 0;
			width: 60%;
			z-index: 1;
		}

		&-lg {
			background-color: transparent;
			border-radius: 10px;
			min-width: 128px;
			overflow: hidden;
			padding: 18px 20px;
			position: relative;
			width: 100%;

			&:after {
				right: -70px;
			}
		}

		&-bronze {
			background-color: $bronze;

			&:after {
				background: $bronze-gradient;
			}
		}

		&-silver {
			background-color: $silver;

			&:after {
				background: $silver-gradient;
			}
		}

		&-gold {
			background-color: $gold;

			&:after {
				background: $gold-gradient;
			}
		}
	}
}
