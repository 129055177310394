.gloit-notification-card-modal-content{
	overflow-x: hidden;
	.mobile-delete{
		display: flex;
		background-color: white;
		width: 100%;
		button.bat-header-gloit-delete{
			margin-left: auto;
			margin-right: 2%;
			margin-bottom: 8px;
			display: flex;
			height: 24px;
			justify-content: center;
			align-items: center;
			gap: 8px;
			color: var(--Primary-Orange, #CD4A04);
			text-align: center;
			font-feature-settings: 'liga' off, 'clig' off;
			font-size: 14px;
			font-style: normal;
			font-weight: 900;
			line-height: 120%;
			letter-spacing: 0.7px;
			text-transform: uppercase;
		}
	}
}