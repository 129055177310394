// sass-lint:disable no-important
.bat-header-gloitstepper {
	background-color: $brand-black;
	font-size: 16px;

	header {
		justify-content: space-between;
		align-items: center;
		height: 93px;
		padding: 0 0 0 20px;
		position: relative;
		z-index: $header-z-index - 2;

		@include media-breakpoint-up(lg) {
			margin: 0 auto;
			max-width: $max-width-xl;
			padding: 0 20px;
		}
	}

	.bat-header-logo{
		display: flex;
		flex: 0 0 94px;
		align-items: center;

		@include media-breakpoint-down(md) {
			flex: 0 0 auto;
		}

		img {
			width: 74px;
			height: 70px;
		}
	}

	.bat-header-menu {
		background-color: $charcoal;
		height: 0;
		top: 93px !important;
		//transition: height .2s ease;

		@include media-breakpoint-up(lg) {
			height: 0;
			left: 0;
			flex: 1 1 auto;
			flex-direction: column;
			justify-content: flex-start;
			align-items: unset;
			max-height: 0;
			overflow: hidden;
			position: absolute;
			width: 100%;
		}

		&.open {
			top: 93px !important;
			height: auto;
			overflow-y: auto;
			max-height: calc(100vh - #{$warning-height-mobile * 2});

			@include media-breakpoint-up(lg) {
				height: auto;
				overflow-y: auto;
			}
		}
		&-button {
			padding: 15px;
			@include media-breakpoint-up(sm) {
				padding: 20px;
			}
		}

		.uk-search{
			border-bottom: 1px solid $gray;
			margin-right: 5px;
			position: relative;

			&-input {
				font-size: 10px;
				background-color: $brand-black;
				height: 35px;
				padding-left: 0;
			}
		}

		.bat-navigation-group-list {
			@include media-breakpoint-up(lg) {
				flex-direction: column;
				align-items: unset;
			}
		}

		.bat-navigation-group-list-item {
			margin: 12px 0 12px 0;

			&:last-child:after {
				background-color: transparent;
				bottom: 0;
				content: "";
				height: 1px;
				left: 0;
				margin: 0 20px;
				opacity: 0.1;
				position: absolute;
				right: 0;
			}

			&:after {
				bottom: -10px;
				@include media-breakpoint-up(lg) {
					display: block;
				}
			}

			&-progress {
				position: absolute;
				height: 100%;
				width: 1px;
				left: 35px;
				display: flex;
				align-items: center;

				&-line {
					background-color: #D4D5D5;
					height: 70%;
					width: 100%;
				}
			}

			&-container {
				position: relative;

				&-main-circle {
					height: 21px;
					width: 21px;
					position: absolute;
					border-radius: 100%;
					border: 1px solid #D4D5D5;
					left: 25px;
					top: 20%;
					display: flex;
					align-items: center;
					justify-content: center;
					background-color: #3A3A3A;

					@include media-breakpoint-up(sm) {
						height: 26px;
						width: 26px;
						top: 24%;
						left: 23px;
					}

					&-fill {
						display: none;
						width: 11px;
						height: 11px;
						border-radius: 100%;
						background-color: #D4D5D5;

						@include media-breakpoint-up(sm) {
							height: 16px;
							width: 16px;
						}

						&.active {
							display: block;
						}
					}
				}

				&-secondary-circle {
					width: 13px;
					height: 13px;
					position: absolute;
					border-radius: 100%;
					border: 1px solid #D4D5D5;
					left: 29px;
					top: 30%;
					background-color: #3A3A3A;

					@include media-breakpoint-up(sm) {
						height: 16px;
						width: 16px;
						top: 32%;
						left: 28px;
					}

					&.active {
						background-color: #D4D5D5;
					}
				}
			} 
		}

		.bat-navigation-group-list-item a {
			padding: 8px 20px 8px 20px;

			@include media-breakpoint-up(sm) {
				padding: 13px 20px 13px 20px;
			}

			@include media-breakpoint-up(lg) {
				@include font-rem(12px, 12px);
				color: $white;
				font-weight: 700;
				justify-content: flex-start;
				padding: 13px 20px 13px 20px;
				width: 100%;
				display: inline-flex;
			}
		}

		.bat-navigation-group-list-item-link { 
			margin-left: 47px;
			span {
				@include font-rem(20px, 25px);
				font-weight: 700;

				@include media-breakpoint-up(sm) {
					@include font-rem(26px, 32px);
				}
			}
		}

		.bat-navigation-sub-item-link {
			margin-left: 47px;
			span{
				@include font-rem(17px, 21px);
				font-weight: 300;

				@include media-breakpoint-up(sm) {
					@include font-rem(20px, 25px);
				}
			}
		}
	}

	.bat-header-nav {
		display: block;
		flex: 0;
		padding: 20px 0;

		.bat-navigation {
			margin: 0 auto;
			max-width: 820px;
			&-group-list-item--velo-mobile {
				@include media-breakpoint-up(lg) {
					display: none;
				}
				a {
					i {
						margin-left: 0;
						margin-right: 2em;
						transform: scale(2);
						transform-origin: left center;
						&:before {
							font-size: 1em;
						}
					}
				}
			}
		}

		@include media-breakpoint-up(lg) {
			flex: 1 1 auto;
			justify-content: flex-start;
			padding: 0 20px;

			.bat-navigation {
				margin: 0 auto;
				max-width: 700px;
				width: 94%;
			}
		}
	}

	.bat-header-menu-button {
		display: flex;
		color: $white;
		// padding: 20px 12px;
		order: 0;
		position: relative;

		button {
			border: 0;
			height: auto;
			width: auto;

			i {
				&:before {
					color: $white;
					font-size: $icon-size;
				}
			}
		}

		&.active {
			background-color: $brand-black;

			button {
				i {
					&:before {
						color: $white;
						font-size: 28px;
					}
				}
			}
		}

		&-open-label {
			color: $white;
			margin-top: 0;
		}

		&-close-label {
			margin-top: 0;
		}

		&-close-label,
		&-open-label {
			@include font-rem(11px, 11px);
		}

		@include media-breakpoint-down(md) {
			padding-left: 5px;
    		padding-right: 5px;
			margin-right: 30px;
		}
	}

	.bat-header-fixed-spacer {
		height: auto;
	}

	.bat-header-content {
		background-color: $white;

		.bat-text {
			background-color: $white;
			bottom: 0;
			display: flex;
			flex-wrap: wrap;
			height: $warning-height-mobile;
			justify-content: flex-start;
			left: 0;
			margin: 0 auto;
			max-width: none;
			position: fixed;
			right: 0;
			top: auto;
			z-index: $header-z-index;

			@include media-breakpoint-up(md) {
				height: $warning-height;
			}

			p {
				margin: 0;

				&:nth-child(1) {
					align-items: center;
					background-color: $white;
					border: solid 6px $black;
					color: $black;
					display: flex;
					font-family: $google-lato;
					font-size: 16px;
					font-weight: 700;
					height: $warning-height-mobile;
					justify-content: center;
					line-height: 16px;
					padding: 0 4%;
					text-align: center;
					width: 100%;

					@include media-breakpoint-up(md) {
						font-size: 26px;
						height: $warning-height;
						line-height: 26px;
					}
				}

				&:nth-child(2),
				&:nth-child(3) {
					border-top: solid 1px $black;
					color: $black;
					font-family: $open-sans;
					font-size: 10px;
					height: $warning-footer-height;
					letter-spacing: 1px;
					line-height: 10px;
					padding: 8px 10px 0;
					text-align: left;
					width: 50%;

					@include media-breakpoint-up(xs) {
						font-size: 11px;
						line-height: 11px;
					}

					br {
						@include media-breakpoint-up(lg) {
							content: " ";
							display: none;
						}
					}
				}

				&:nth-child(3) {
					margin-left: auto;
					text-align: right;
				}
			}
		}
	}

	.bat-header-stepper-message-bar {
		background-color: #3A3A3A;
		display: flex;
		align-items: center;
		justify-content: center;
		color: $white;
		height: 66px;

		@include media-breakpoint-up(sm) {
			height: 112px;
		}

		&-message {
			width: 315px;
			@include media-breakpoint-up(sm) {
				width: 500px;
			}
		}

		span p {
			font-weight: 700;
			font-size: 20px;
			line-height: 24px;
			text-transform: uppercase;
			margin: 0;

			@include media-breakpoint-up(sm) {
				width: 500px;
				font-size: 28px;
				line-height: 35px;
			}
		}
	}

	.bat-header-stepper-cart-info {
		height: 52px;
		background-color: #05A5D6;
		color: $white;
		font-weight: 700;
		font-size: 18px;
		line-height: 22px;
		text-transform: uppercase;

		@include media-breakpoint-up(sm) {
			height: 60px;
			font-size: 20px;
			line-height: 25px;
		}

		&-container {
			align-items: center;
			max-width: 1400px;
			margin: 0 auto;
			height: 100%;
		}

		&-first,
		&-second,
		&-third {
			justify-content: center;
		}

		&-second:before,
		&-second:after {
			content: "";
			border: 1px solid #fff;
			opacity: 0.3;
		}

		&-first {
			align-items: center;
			gap: 20px;

			@include media-breakpoint-down(sm) {
				justify-content: flex-start;
			}
			
			&-text {

				&-title {
					width: max-content;
				}

				&-description {
					font-weight: 500;
					font-size: 11px;
					line-height: 14px;
				}
			}
		}

		&-third {
			&-regular {
				gap: 11px;

				@include media-breakpoint-down(sm) {
					flex-direction: column;
					gap: 0;
					align-items: flex-end;
				}

				&-pricefull {
					font-weight: 500;
					font-size: 11px;
					line-height: 14px;
					text-decoration-line: line-through;

					@include media-breakpoint-up(sm) {
						font-size: 14px;
						line-height: 17px;
					}
				}
			}

			&-discounted {
				gap: 14px;
				align-items: center;

				@include media-breakpoint-down(sm) {
					flex-direction: column;
					gap: 0;
					align-items: flex-end;
					padding-right: 50px;
				}

				&-pricecontainer {
					gap: 5px;
					align-items: baseline;
				}

				&-pricefull {
					font-weight: 500;
					font-size: 11px;
					line-height: 14px;
					text-decoration-line: line-through;

					@include media-breakpoint-up(sm) {
						font-size: 14px;
						line-height: 17px;
					}
				}

				&-coupon {
					font-weight: 500;
					font-size: 9px;
					line-height: 11px;
					width: max-content;

					@include media-breakpoint-up(sm) {
						font-size: 10px;
						line-height: 12px;
						width: auto;
					}

					.coupon-name {
						font-weight: 700;
					}
				} 
			}
		}
	}
}

bat-header-gloitstepper.safari-fix .bat-header-menu.open {
	max-height: calc(100vh - #{$warning-height-mobile * 2.5});
}
