bat-image-default {
	&.list-text {
		.bat-image {
			max-width: 60%;
			max-height: 60%;
			margin-left: 0;
		}
	}
}

.bat-image {
	
	a {
		display: block;
		width: 100%;
	}

}

bat-image-default.unset-width {
	.bat-image {
		a {
			picture {
				img {
					width: unset;
				}
			}
		}
	}
}


.thr-images{
	picture{
		padding: 10%;
		@include media-breakpoint-down(sm){
			padding: 5%;
		}
	}
}




