.banner{
    display: flex;
    background-image: url('/content/dam/glo-it/images/lep/bg_gray.jpg');
    height: 680px;
	width: 100%;
    margin: auto;
    display: flex;
    flex-direction: row;
	background-size: 100% 100%;
}
.banner .top-side{
	display: none;
}
.banner .left-side{
	width: 50%;
    display: flex;
    align-items: center;
	overflow: hidden;
}
.banner .left-side img{
	max-width: 441px;
	max-height: 950px;
    align-self: center;
    flex-shrink: 0;
    margin-top: 20%;
	transform: rotate(75.921deg);
	padding-left: 36px;
}
.banner .right-side{
	width: 50%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
}

.banner .right-side h3{
    color: #FFF;
    text-shadow: 0px 4px 35px #000;
    font-size: 50px;
    font-style: normal;
    font-weight: 500;
    line-height: 60px; /* 120% */
    margin-bottom: 24px;
}
.banner .right-side img{
	max-height: 100px;
	max-width: 455px;
}
.banner .right-side a{
    display: flex;
    width: 179px;
    height: 40px;
    flex-shrink: 0;
    border-radius: 100px;
    background: var(--Glo-CTA-Gradient, linear-gradient(268deg, #CD4A04 0.65%, #ED7D30 100%));
    border: none;
    color: white;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

@media screen and (max-width:780px){
	.banner{
		background-image: url('/content/dam/glo-it/images/lep/bg_gray.jpg');
		width: 100%;
		display: flex;
		flex-direction: column;
		background-size: 100% 100%;
		height: 88dvh;
		max-height: 660px;
	}
	.banner .top-side{
		display: unset;
		padding-top: 48px;
		width: 80%;
		margin: auto;
	}
	.banner .left-side{
		width: 100%;
	}
	.banner .left-side img{
        align-self: center;
        flex-shrink: 0;
        transform: rotate(75.921deg);
        margin-left: -13%;
        width: 100%;
        max-width: 270px;
        max-height: 500px;
        margin-top: 7%;
	}
	.banner .right-side{
		width: 100%;
		align-items: center;
		justify-content: center;
		padding-bottom: 5%
	}
	.banner .right-side img{
		display: none;
	}
	.banner .right-side h3{
		color: #fff;
        text-align: center;
        font-feature-settings: 'liga' off, 'clig' off;
        font-family: Mont;
        font-size: 23px;
        font-style: normal;
        font-weight: 400;
        line-height: 25px;
	}
}