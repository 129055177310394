//FORMS
.bat {
	form {
		label {
			@include font-rem($font-sm, $font-sm + 6);
			font-family: $primary-font;
			font-weight: bold;
			letter-spacing: 1px;
		}

		.error-msg {
			@include font-rem(12px, 12px);
			color: $red;
			padding: 10px 0;
		}

		//INPUTS
		input {
			@include font-rem(15px, 15px);
			background-color: $concrete;
			border: 0;
			border-bottom: solid 2px transparent;
			color: $blackish;
			font-family: $primary-font;
			height: 40px;
			padding: 8px 20px;
			width: 100%;

			// sass-lint:disable no-important
			&:-webkit-autofill {
				-webkit-box-shadow: 0 0 0 30px $concrete inset !important;
				-webkit-text-fill-color: $blackish !important;

				&:active,
				&:focus,
				&:hover {
					-webkit-box-shadow: 0 0 0 30px $concrete inset !important;
					-webkit-text-fill-color: $blackish !important;
				}
			}

			&::-webkit-input-placeholder {
				color: $dark-gray;
			}

			&:-moz-placeholder {
				/* FF 4-18 */
				color: $dark-gray;
			}

			&::-moz-placeholder {
				/* FF 19+ */
				color: $dark-gray;
			}

			&:-ms-input-placeholder {
				/* IE 10+ */
				color: $dark-gray;
			}

			&::-ms-input-placeholder {
				/* Microsoft Edge */
				color: $dark-gray;
			}

			&::placeholder {
				/* modern browser */
				color: $dark-gray;
			}

			& + .error-msg {
				display: none;
			}

			&:focus {
				border: 0;
				border-bottom: solid 2px $light-blue;
				outline: none;
			}

			&.complete {
				border: 0;
				border-bottom: solid 2px $light-blue;
			}

			&.error {
				border: 0;
				border-bottom: solid 2px $red;

				& + .error-msg {
					display: inline-block;
				}
			}

			&:disabled {
				background-color: $light-gray;
				color: $blackish;
				opacity: 1;
				pointer-events: none;

				&::-webkit-input-placeholder {
					color: $blackish;
				}

				&:-moz-placeholder {
					/* FF 4-18 */
					color: $blackish;
				}

				&::-moz-placeholder {
					/* FF 19+ */
					color: $blackish;
				}

				&:-ms-input-placeholder {
					/* IE 10+ */
					color: $blackish;
				}

				&::-ms-input-placeholder {
					/* Microsoft Edge */
					color: $blackish;
				}

				&::placeholder {
					/* modern browser */
					color: $blackish;
				}
			}
		}

		//TEXTAREA
		textarea {
			@include font-rem(15px, 15px);
			background-color: $concrete;
			border: 0;
			border-bottom: solid 2px transparent;
			color: $blackish;
			font-family: $primary-font;
			height: 150px;
			padding: 8px 20px;
			width: 100%;

			&::-webkit-input-placeholder {
				color: $dark-gray;
			}

			&:-ms-input-placeholder {
				color: $dark-gray;
			}

			&::placeholder {
				color: $dark-gray;
			}

			& + .error-msg {
				visibility: hidden;
			}

			&:focus {
				border: 0;
				border-bottom: solid 2px $light-blue;
				outline: none;
			}

			&.complete {
				border: 0;
				border-bottom: solid 2px $light-blue;
			}

			&.error {
				border: 0;
				border-bottom: solid 2px $red;

				& + .error-msg {
					visibility: visible;
				}
			}

			&:disabled {
				background-color: $light-gray;
				color: $blackish;
				opacity: 1;
				pointer-events: none;

				&::-webkit-input-placeholder {
					color: $blackish;
				}

				&:-moz-placeholder {
					/* FF 4-18 */
					color: $blackish;
				}

				&::-moz-placeholder {
					/* FF 19+ */
					color: $blackish;
				}

				&:-ms-input-placeholder {
					/* IE 10+ */
					color: $blackish;
				}

				&::-ms-input-placeholder {
					/* Microsoft Edge */
					color: $blackish;
				}

				&::placeholder {
					/* modern browser */
					color: $blackish;
				}
			}
		}

		//SELECT MENUS
		.select-wrapper {
			position: relative;

			select {
				-moz-appearance: none;
				-webkit-appearance: none;
				appearance: none;

				@include font-rem(14px, 20px);
				background-color: $concrete;
				border: 0;
				color: $blackish;
				font-family: $primary-font;
				height: 40px;
				padding: 8px 20px;
				width: 100%;

				&.error {
					border: 0;
					border-bottom: solid 2px $red;
				}

				&.active {
					border: 0;
					border-bottom: solid 2px $light-blue;
				}

				&.complete {
					border: 0;
					border-bottom: solid 2px $light-blue;
				}

				&::-ms-expand {
					display: none;
				}

				&:disabled {
					background-color: $light-gray;
					opacity: 1;
				}
			}

			& + .error-msg {
				visibility: hidden;
			}

			&.complete {
				border: 0;
				border-bottom: solid 2px $light-blue;
			}

			&.disabled {
				color: $blackish;
				opacity: 1;
				pointer-events: none;

				select {
					background-color: $light-gray;
				}

				&::-webkit-input-placeholder {
					color: $blackish;
				}

				&:-moz-placeholder {
					/* FF 4-18 */
					color: $blackish;
				}

				&::-moz-placeholder {
					/* FF 19+ */
					color: $blackish;
				}

				&:-ms-input-placeholder {
					/* IE 10+ */
					color: $blackish;
				}

				&::-ms-input-placeholder {
					/* Microsoft Edge */
					color: $blackish;
				}

				&::placeholder {
					/* modern browser */
					color: $blackish;
				}
			}

			&.error {
				border: 0;

				& + .error-msg {
					visibility: visible;
				}
			}

			&:after {
				color: $brand-black;
				content: "\e920";
				font-family: "icomoon";
				font-size: 24px;
				height: 22px;
				pointer-events: none;
				position: absolute;
				right: 8px;
				top: 9px;
				width: 24px;
			}
		}

		//RADIO and CHECKBOX
		fieldset {
			border: 0;

			ul {
				padding: 0;
			}

			label {
				display: flex;
				margin-bottom: 15px;

				&.disabled {
					opacity: 1;
					pointer-events: none;

					input {
						&:before {
							color: $gray !important; // sass-lint:disable-line no-important
						}

						+ span {
							color: $gray !important; // sass-lint:disable-line no-important
						}
					}
				}

				& > input {
					&[type="checkbox"],
					&[type="radio"] {
						display: inline;
						height: 18px;
						margin-right: 20px;
						position: relative;
						width: auto;

						&:before {
							background-color: $white;
							color: $blackish;
							content: "";
							display: inline-block;
							font-family: "icomoon";
							font-size: 18px;
							height: 18px;
							left: 0;
							position: absolute;
							top: 0;
							width: 18px;
						}

						&:checked {
							&:before {
								color: $blackish;
								content: "";
								display: inline-block;
							}

							+ span {
								@include font-rem(15px, 15px);
								color: $dark-gray;
								letter-spacing: normal;
								padding: 1px 0 10px;
								text-transform: none;
							}
						}

						+ span {
							@include font-rem(15px, 15px);
							color: $dark-gray;
							font-weight: normal;
							letter-spacing: normal;
							padding: 1px 0 10px;
							text-transform: none;
						}
					}

					&[type="checkbox"] {
						&:before {
							content: "\e977";
							font-family: "icomoon";
						}

						&:checked {
							&:before {
								color: $blackish;
								content: "\e979";
								font-family: "icomoon";
							}
						}
					}

					&[type="radio"] {
						&:before {
							content: "\e97e";
							font-family: "icomoon";
						}

						&:checked {
							&:before {
								color: $blackish;
								content: "\e97f";
								font-family: "icomoon";
							}
						}
					}
				}
			}

			& + .error-msg {
				visibility: hidden;
			}

			&.error {
				& + .error-msg {
					visibility: visible;
				}
			}
		}
	}
}
