.bat-card {
	//-----------------------------------------------------------------| Default
	&--default {
		&-tile {
			margin-bottom: 20px;
			overflow: hidden;
			position: relative;

			&:after {
				bottom: 0;
				color: $dark-gray;
				content: "\e92c";
				font-family: "icomoon";
				@include font-rem(25px, 25px);
				position: absolute;
				right: 0;
				transition: all 0.3s ease;
				z-index: 3;
			}

			&:hover {
				&:after {
					color: $teal-dark;
					font-weight: 700;
				}
			}

			a {
				border: 2px solid $dark-gray;
				transform: translate3d(0, 0, 0);
				transition: border 0.3s ease 0s;

				&:before {
					border: 2px solid transparent;
					bottom: 0;
					content: "";
					left: 0;
					position: absolute;
					right: 0;
					top: 0;
					transition: border-top 0.3s ease;
					z-index: 1;
				}

				&:after {
					border-top: 2px solid $dark-gray;
					content: "";
					display: block;
					height: 90px;
					position: absolute;
					right: -10px;
					top: calc(100% - 38px);
					transform: skew(10deg, -25deg);
					transition: border-top 0.3s ease;
					width: 180px;
					z-index: 2;
				}

				@include media-breakpoint-up(lg) {
					&:hover {
						border-color: $teal-dark;

						&:before {
							border-color: $teal-dark;
						}

						&:after {
							border-top: 4px solid $teal-dark;
						}
					}
				}
			}

			&-headline {
				min-height: 74px;
				padding: 15px;

				h3 {
					@include font-rem(22px, 20px);
					font-weight: 700;
				}
			}
		}
	}

	

	//-----------------------------------------------------------------| Hero
	&--hero {
		&-content {
			&-headline {
				h2 {
					@include font-rem(44px, 41px);
					letter-spacing: -0.6px;

					@include media-breakpoint-up(lg) {
						@include font-rem(48px, 45px);
						letter-spacing: -1.4px;
					}
				}
			}

			&-cta-list {
				margin-top: 9px;

				@include media-breakpoint-up(lg) {
					margin-top: 5px;
				}
			}
		}
	}

	//-----------------------------------------------------------------| Category
	&--category {
		margin-bottom: 20px;

		&-content {
			&-headline {
				h4 {
					@include font-rem(24px, 24px);
					margin-bottom: 0;
				}
			}

			&-text {
				p {
					margin-bottom: 0;
				}
			}
		}
	}
	//-----------------------------------------------------------------| Step
	&--step {
		margin: 0 auto;
		padding-top: 240px;

		@include media-breakpoint-up(sm) {
			min-height: 30vh;
			padding-top: 246px;
		}

		@include media-breakpoint-up(md) {
			min-height: 325px;
			padding-top: 145px;
		}

		@include media-breakpoint-up(lg) {
			min-height: 450px;
			padding-top: 125px;
		}
	}
	//-----------------------------------------------------------------| Hustle
	&--hustle {
		margin: 0 auto;

		&-tile {
			&-headline {
				h3 {
					span {
						color: $teal-dark;
						display: block;
					}
				}
			}
		}
		button {
			&[data-cta-type="video"] {
				&:after {
					color: $white;
					content: "\f04b";
					font-family: "Font Awesome 5 Free";
					font-weight: 700;
					left: 50%;
					padding: 34px;
					position: absolute;
					top: 50%;
					transform: translate(-50%, -50%);
					@include font-rem(20px, 20px);
				}
				&:before {
					background-color: $dark-blue;
					border-radius: 50%;
					color: $white;
					content: "\f04b";
					font-family: "Font Awesome 5 Free";
					font-weight: 700;
					left: 50%;
					padding: 34px;
					position: absolute;
					top: 50%;
					transform: translate(-50%, -50%);
					@include font-rem(20px, 20px);
				}
			}
		}
	}

	//-----------------------------------------------------------------| Subscription
	&--subscription {
		background-color: $concrete;
		margin: 0 auto 20px;
		max-width: 275px;
		text-align: center;

		@include media-breakpoint-up(lg) {
			margin: 0 auto;
			max-width: none;
		}

		&-text {
			padding: 20px;

			> p {
				color: $dark-blue;
				margin: 0 auto;
				max-width: 186px;

				&:nth-child(1) {
					@include font-rem(20px, 22px);

					@include media-breakpoint-up(lg) {
						@include font-rem(23px, 27px);
					}
				}

				&:nth-child(2) {
					@include font-rem(14px, 16px);
					color: $dark-gray;
					margin-bottom: 12px;
					padding-bottom: 12px;

					@include media-breakpoint-up(lg) {
						@include font-rem(16px, 19px);
					}

					.gold & {
						border-bottom: solid 2px $gold;
					}

					.silver & {
						border-bottom: solid 2px $silver;
					}

					.bronze & {
						border-bottom: solid 2px $bronze;
					}
				}

				&:nth-child(3) {
					@include font-rem(30px, 32px);
					font-weight: 700;

					@include media-breakpoint-up(lg) {
						@include font-rem(39px, 47px);
					}
				}

				&:nth-child(4) {
					@include font-rem(17px, 19px);
					font-weight: 700;

					@include media-breakpoint-up(lg) {
						@include font-rem(20px, 24px);
					}
				}
			}
		}

		.gold & {
			.bat-label {
				@extend .bat-label--subscription-gold;
			}
		}

		.silver & {
			.bat-label {
				@extend .bat-label--subscription-silver;
			}
		}

		.bronze & {
			.bat-label {
				@extend .bat-label--subscription-bronze;
			}
		}

		//-----------------------------------------------------------------| Subscription small
		.small & {
			background-color: $white;
			max-width: 190px;

			.bat-label--subscription {
				border-radius: 10px 10px 0 0;
			}

			h3 {
				@include font-rem(30px, 36px);

				span {
					display: block;

					&:nth-child(1) {
						@include font-rem(14px, 14px);
						font-weight: 400;
					}

					&:nth-child(2) {
						color: $dark-gray;
						@include font-rem(12px, 12px);
						font-weight: 400;
					}
				}
			}

			&-text {
				padding: 7px 20px 14px;

				> p {
					&:nth-child(1) {
						@include font-rem(27px, 27px);
						font-weight: 700;
						margin-bottom: 2px;
					}

					&:nth-child(2) {
						border-bottom: 0;
						color: $dark-blue;
						@include font-rem(16px, 16px);
						font-weight: 700;
						margin-bottom: 0;
						padding-bottom: 0;
					}
				}
			}
		}

		//sass-lint:disable force-element-nesting
		.small.gold &,
		.small.silver &,
		.small.bronze & {
			border-radius: 10px;

			.bat-label--subscription-lg {
				&:after {
					right: -100px;
				}
			}
		}

		.small.gold & {
			border: solid 1px $gold;

			&-text {
				> p {
					&:nth-child(1) {
						color: $gold;
					}
				}
			}
		}

		.small.silver & {
			border: solid 1px $silver;

			&-text {
				> p {
					&:nth-child(1) {
						color: $silver;
					}
				}
			}
		}

		.small.bronze & {
			border: solid 1px $bronze;

			&-text {
				> p {
					&:nth-child(1) {
						color: $bronze;
					}
				}
			}
		}
	}

	//-----------------------------------------------------------------| 404 Error
	&--404-error {
		margin: 5% auto 40px auto;
		text-align: center;
		max-width: 600px;

		&-heading {
			margin: 20px 0 10px;
		}

		&-text {
			min-height: 6ch;
			text-align: left;

			a {
				text-decoration: underline;
			}
		}

		&-cta {
			@include media-breakpoint-down(md) {
				margin-left: auto;
				margin-right: auto;
				max-width: 400px;
			}
			a {
				margin: 0 10px;
			}
			@include media-breakpoint-down(lg) {
				& .bat-cta-style {
					&:last-of-type {
						margin-top: 15px;
					}
				}
			}
		}
	}

	//-----------------------------------------------------------------| Masthead
	&--masthead {
		overflow: hidden;
		position: relative;

		&-main {
			height: inherit;
			position: absolute;
			top: 0;
			width: 100%;

			@include media-breakpoint-up(md) {
				width: 45%;
			}

			span,
			p {
				&.dark-gray,
				.dark-gray {
					color: $dark-gray;
				}

				&.dark,
				.dark {
					color: $dark-blue;
				}

				&.light,
				.light {
					color: $white;
				}
			}
		}

		&-img {
			height: inherit;

			picture {
				height: 100%;
				width: initial;
			}
		}
	}

	//-----------------------------------------------------------------| Blurb
	&--blurb {
		height: 600px;
		overflow: hidden;
		position: relative;
		@include media-breakpoint-down(sm) {
			height: 495px;
		}

		&-text {
			h2,
			h3,
			h4 {
				@include font-rem(22px, 28px);
				margin-block-end: 8px;
			}

			.card-price {
				display: block;
				background: $orange;
				width: fit-content;
				padding-top: 6px;
				padding-bottom: 6px;
				padding-left: 9px;
				padding-right: 13px;
				margin-bottom: 20px;
				@include font-rem(30px, 32px);
				font-weight: bold;
				font-family: $primary-font;
			}
			.card-\&more {
				content: url("/content/dam/glo/images/glo_and-more-branding.svg");
			}

			.bat-headline-style {
				.headline2-color1 {
					@include font-rem(22px, 28px);
				}
			}

			@include media-breakpoint-down(sm) {
				h2,
				h3,
				h4 {
					@include font-rem(22px, 28px);
				}
			}

			p {
				@include font-rem(16px, 20px);
			}
		}

		&-main,
		&-img {
			position: absolute;
		}

		&-main {
			bottom: 0;
			left: 0;
			padding: 30px;
			color: $white;
		}

		&-img {
			bottom: 0;
			left: 0;
			right: 0;
			top: 0;

			picture,
			img {
				height: 100%;
				object-fit: cover;
				width: auto;
			}
			@include media-breakpoint-down(sm) {
				picture,
				img {
					height: 100%;
					object-fit: cover;
					width: 100%;
				}
			}
		}

		&-ctalist {
			margin-top: 20px;
			@include media-breakpoint-down(sm) {
				margin-top: 10px;
			}
			.button-secondary-light {
				&,
				&:hover,
				&:active {
					border-color: $white;
				}
				padding-top: 7px;
				padding-bottom: 7px;
				padding-left: 20px;
				padding-right: 20px;
			}
		}
	}

	//-----------------------------------------------------------------| Offer Card
	&--offer {
		&-wrapper {
			align-content: space-between;
			display: flex;
			flex-flow: row wrap;
			min-height: 320px;

			&-content {
				padding: 1rem 1.5rem;

				&-headline {
					margin-bottom: 1rem;
					.purple & {
						color: $purple;
					}
				}

				&-specialcta {
					align-items: center;
					background-color: $purple;
					color: $white;
					display: flex;
					padding: 0.5rem 1.25rem;

					a {
						align-items: center;
						display: flex;
					}

					.bat-headline-style {
						@include font-rem(20px, 20px);
						margin-left: 1rem;

						div {
							@extend p;
							@include font-rem(14px, 14px);
							font-weight: bold;
							margin-bottom: 0;
							margin-top: 3px;
						}
					}
				}

				&-text {
					margin-top: 1rem;
					p {
						@include font-rem(15px, 15px);
					}
				}
			}
			&-ctalist {
				margin-bottom: 1rem;
				margin-top: 1rem;
				padding: 1rem 1.5rem;
			}
		}
	}

	//-----------------------------------------------------------------| News Card
	&--news {
		margin-top: 8px;

		@include media-breakpoint-down(xs) {
			margin-bottom: 0;
			margin-top: 0;
			padding: 40px 0 30px;
		}

		&-img {
			height: 100%;
			max-height: 240px;
			overflow: hidden;

			a {
				display: block;
			}
		}

		&-text {
			margin: 15px 0;

			@include media-breakpoint-down(xs) {
				margin: 8px 0 5px;
			}

			h3 {
				font-size: 20px;
				line-height: 150%;
				padding-top: 15px;

				@include media-breakpoint-down(xs) {
					padding-top: 16px;
				}
			}
			p {
				font-size: 15px;
				line-height: 150%;
				margin-top: 5px;

				@include media-breakpoint-down(xs) {
					color: $dark-gray;
				}
			}
		}
		&-ctalist {
			.bat-cta-style {
				font-size: 15px;
				line-height: 150%;
				margin-top: 5px;

				@include media-breakpoint-down(sm) {
					margin-top: 0;
				}
			}
		}
	}

	//-----------------------------------------------------------------| Avalanche - Blog Card
	&--blog {
		@include media-breakpoint-down(md) {
			border-bottom: 2px solid $light-gray;
			margin-bottom: 30px;
			padding-bottom: 15px;
		}

		&-img {
			overflow: hidden;
		}

		&-main {
			padding: 0 20px 0 0;
		}

		&-text {
			p {
				&:first-of-type {
					margin-top: 30px;
				}

				.sm {
					// styleguide override
					@include font-rem(15px, 22.5px);
				}
			}

			h3 {
				// styleguide override
				@include font-rem(20px, 25px);
				margin: 10px 0;
				@include media-breakpoint-down(xs) {
					font-weight: 400;
				}
			}
		}

		&-ctalist {
			margin: 15px 0 0;

			// styleguide override - cta - link dark
			a {
				font-weight: 700;

				@include media-breakpoint-down(xs) {
					font-weight: 400;
				}

				// styleguide override - cta icon - link dark
				i {
					&:before {
						font-weight: 700;
					}
				}
			}

			.bat-cta-style {
				&.link-dark {
					i {
						&:before {
							font-size: 18px;
						}
					}
				}
			}
		}
	}
}

//Background Classes
bat-card-step {
	&.step1,
	&.step2,
	&.step3,
	&.step4 {
		position: relative;

		&:before {
			@extend h1;
			color: $off-white;
			font-size: 220px;
			line-height: 220px;
			position: absolute;
			top: 0;

			@include media-breakpoint-up(md) {
				font-size: 160px;
				line-height: 160px;
			}

			@include media-breakpoint-up(lg) {
				font-size: 220px;
				line-height: 220px;
			}
		}
	}
}

// Masthead Card
bat-card-masthead {
	&.masthead {
		&-full,
		&-full__reverse {
			.bat-card--masthead {
				height: 470px;

				&-main {
					box-sizing: border-box;
					padding: 5%;
				}

				&-img {
					bottom: 0;
					clip-path: none;
					overflow: hidden;
					position: absolute;
					top: 0;
					width: 100%;

					@include media-breakpoint-up(md) {
						width: 55%;
					}

					picture {
						height: inherit;
						width: initial;
					}

					img {
						height: inherit;
						width: auto;

						@include media-breakpoint-up(lg) {
							width: 100%;
						}
					}
				}

				&-ctalist {
					margin-top: 30px;
				}
			}
		}

		&-full {
			.bat-card--masthead {
				&-img {
					left: 0;

					@include media-breakpoint-up(md) {
						clip-path: polygon(0% 0%, 100% 0, 65% 100%, 0 100%);
					}
				}
				&-main {
					right: 0;
				}
			}
		}

		&-full__reverse {
			.bat-card--masthead {
				&-img {
					right: 0;
				}

				&-main {
					left: 0;
				}
			}
		}

		&-blurb {
			margin-bottom: 3%;

			@include media-breakpoint-up(md) {
				margin-bottom: 0;
			}

			.bat-card--masthead {
				height: 200px;
				@include media-breakpoint-up(sm) {
					height: 260px;
				}

				.button-secondary {
					&-dark,
					&-light {
						&:hover,
						&:active {
							background-color: transparent;
						}
					}
					&-dark {
						&,
						&:hover,
						&:active {
							border-color: $brand-black;
							&,
							span {
								color: $brand-black;
							}
						}
					}
					&-light {
						&,
						&:hover,
						&:active {
							border-color: $white;
							&,
							span {
								color: $white;
							}
						}
					}
				}

				&-main {
					box-sizing: border-box;
					height: 100%;
					padding: 2.5%;
					width: 60%;

					@include media-breakpoint-up(xxs) {
						width: 65%;
					}
				}

				img {
					object-fit: cover;
					@include media-breakpoint-up(xxs) {
						height: 100%;
						width: 100%;
					}

					@include media-breakpoint-up(xs) {
						height: 100%;
						width: 100%;
					}
				}
			}

			&__left,
			&__right,
			&__center {
				.bat-card--masthead {
					&-ctalist {
						margin-top: 15px;
						width: 100%;
					}
				}
			}

			&__left {
				.bat-card--masthead {
					&-main,
					&-ctalist {
						left: 15px;
					}

					@include media-breakpoint-down(sm) {
						&-img {
							picture,
							img {
								object-position: center right;
							}
						}
					}

					&-main {
						text-align: left;
					}
				}
			}

			// right content
			&__right {
				.bat-card--masthead {
					&-main,
					&-ctalist {
						right: 15px;
					}

					@include media-breakpoint-down(sm) {
						&-img {
							picture,
							img {
								object-position: center left;
							}
						}
					}

					&-main {
						text-align: right;
					}
				}
			}

			&__center {
				.bat-card--masthead {
					&-main,
					&-ctalist {
						left: 0;
						right: 0;
						text-align: center;
						width: auto;
					}

					@include media-breakpoint-down(sm) {
						&-img {
							picture,
							img {
								object-position: center center;
							}
						}
					}
				}
			}
		}
	}

	// Authorable class "cover" to make background image cover
	&.cover {
		.bat-card--masthead-img {
			img {
				height: 100%;
				object-fit: cover;
			}
		}
	}
}

bat-card-simpleproduct {
	background-color: $dark-blue;

	.bat-card {
		&--simpleproduct {
			display: flex;
			flex-wrap: wrap;

			@include media-breakpoint-up(lg) {
				flex-wrap: nowrap;
			}

			&-img {
				display: flex;
				justify-content: center;
				width: 100%;

				@include media-breakpoint-up(lg) {
					width: 33%;
				}

				picture {
					max-width: 500px;
				}
			}
			&-main {
				width: 100%;

				@include media-breakpoint-up(lg) {
					padding-left: 1rem;
					width: 67%;
				}

				&-title {
					color: $white;
					margin-bottom: 1rem;

					@include media-breakpoint-down(md) {
						display: flex;
						justify-content: center;
					}

					h3 {
						@include font-rem(26px, 26px);
					}
				}

				&-text {
					color: $white;
					margin-bottom: 1rem;

					@include media-breakpoint-down(md) {
						display: flex;
						justify-content: center;
					}
				}

				&-ctalist {
					color: $white;
					display: flex;

					@include media-breakpoint-down(md) {
						display: flex;
						justify-content: center;
					}

					&-button {
						border: 1px solid $white;
						padding: 0.5rem 1rem;

						@include media-breakpoint-up(xs) {
							padding: 0.5rem 1.75rem;
						}

						@include media-breakpoint-up(md) {
							padding: 0.5rem 2rem;
						}

						div {
							@include font-rem(12px, 8px);
							margin-top: 5px;
							text-align: center;
							width: 100%;
						}
						.bat-icon {
							display: inline-flex;
							width: 10px;

							&::before {
								font-size: 12px;
							}
						}
					}
				}
			}
		}
	}
}

//-----------------------------------------------------------------| News Card HP mobile
@include media-breakpoint-down(xs) {
	.news-card-first {
		.bat-card--news {
			padding-top: 18px;
		}
	}

	.news-card-last {
		.bat-card--news {
			border: 0;
			margin-bottom: 0;
			padding-bottom: 0;
		}
	}
}

//-----------------------------------------------------------------| BlogHub, overides to news card
.bat-blog-hub {
	.bat-card--news {
		@include media-breakpoint-down(xs) {
			padding-bottom: 16px;
			padding-top: 30px;
		}
	}
	.bat-card--news-text {
		p {
			&:first-of-type {
				margin-top: 26px;

				@include media-breakpoint-down(xs) {
					margin-top: 16px;
				}
			}
		}
		h3 {
			padding-top: 0;
		}
	}
	.bat-cta-style {
		@include media-breakpoint-down(xs) {
			margin-top: 10px;
		}
	}
}
