bat-card-gloitlepcarousel{
    .slide{
        display: flex;
        background-size: cover;
        overflow: hidden !important;
        width: 100%;
        overflow: hidden;
        background-position: center;
        background-repeat: no-repeat;
        min-width: 100%;
        height: 84vh !important;
        align-items: center;
        justify-content: center;
        @include media-breakpoint-up(md) {
            min-height: 750px;
        }
 
 
 
        /*@media (min-height: 974px) and (max-height: 1025px){
            height: 809px;
        }
        @media (min-height: 800px) and (max-height: 973px){
            height: 755px;
        }*/
 
        .card-container{
            width: 100%;
            padding-top: 100px;
            position: relative;
            display: flex;
            flex-direction: column;
            gap: 30px;
            align-items:center;
            justify-content: flex-start;
            max-width: 1440px;
            height: 100%;
            font-family: Mont;
            @include media-breakpoint-down(md) {
               padding-top: 10%;
            }
 
            h2{
                color: #FFF;
                text-align: center;
                font-feature-settings: 'liga' off, 'clig' off;
                font-family: Mont;
                font-size: 185px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px; /* 12.973% */
                @include media-breakpoint-down(md) {
                    font-size: 65px;
                    line-height: 19px;
                }
            }
 
            h5{
                color: #FFF;
                text-align: center;
                font-feature-settings: 'liga' off, 'clig' off;
                font-family: Mont;
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px; /* 120% */
                @include media-breakpoint-down(md) {
                    font-size: 14px;
                    line-height: 19px;
                    margin-top: 0px;
                }
            }
 
            a{
                display: flex;
                width: 179px;
                height: 40px;
                flex-shrink: 0;
                border-radius: 100px;
                background: var(--Glo-CTA-Gradient, linear-gradient(268deg, #CD4A04 0.65%, #ED7D30 100%));
                border: none;
                color: white;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                @include media-breakpoint-down(md) {
                    margin-top: -20px;
                    margin-inline: auto !important;
                }
                span{
                    text-wrap: nowrap;
                }
            }
 
            img{
                position: absolute;
                bottom: -3%;
                left: 12%;
                @include media-breakpoint-down(md) {
                    margin-top: unset;
                    margin-left: unset;
                    max-width: 270px;
                    left: -6px;
                    bottom: 0;
                }
                @media (min-height: 770px) and (max-height: 932px){
                    max-width: none;
                    @include media-breakpoint-down(lg) {
                        min-width: 465px;
                        left: -2%;
                    }
                }
 
 
            }
 
            .arrows{
                position: absolute;
                bottom: 8px;
                z-index: 3;
                transform: none;
                height: 44px;
                width: 62px;
                img{
                    margin-top: unset;
                    margin-left: unset;
                    bottom: 8px;
                    @include media-breakpoint-down(md) {
                        display: none;
                    }
                }
            }
 
        }
    }
 
    .hidden{
        display: none;
    }
 
    .slick-slide{
        border: none !important;
    }
 
}
 
.gloitlep bat-carousel {
    .slick-dots {
        position: absolute;
        top: 50%; /* Align vertically */
        right: 10px; /* Adjust the right position as per your layout */
        transform: translateY(-50%); /* Center the dots vertically */
        display: flex;
        flex-direction: column; /* Arrange the dots in a column (vertical) */
        justify-content: center;
    }
    
    .slick-dots li {
        margin: 10px 0; /* Space between dots */
    }
    
    .slick-dots li button {
        border-radius: 50%; /* Make sure the dots are round */
        width: 15px; /* Adjust the size as per your preference */
        height: 15px;
    }
    
    .slick-dots li.slick-active button {
        background-color: white; /* Active dot styling */
        opacity: 1;
    }
    
    .slick-dots li button {
        background-color: rgba(255, 255, 255, 0.5); /* Inactive dot styling */
        opacity: 0.5;
    }
 
    
}