
.bat-header-gloit .second-section {
    @include media-breakpoint-down(md){
        display: none !important;
    } 
}
.bat-card-gloitnewsletterbanner {
    @include media-breakpoint-down(md){
        height: 50px;   
        display: flex;
        align-items: center;
        .bat-card-gloitnewsletterbanner-title{
            margin-top: 6px;
            .title{
                font-size: 15px;
                line-height: 15px;
            }
        }
        .bat-card-gloitnewsletterbanner-cta{
            a{
                height: 27px;
            }
        }
        .bat-card-gloitnewsletterbanner-container{
            .icon{
                margin-top: 9px;
            }
        }
    }
}

.newside{
    .bat-hero-gloit-content{
        padding: 108% 20px 20px !important;

        .h1-new-side{
            font-family: 'Mont';
            font-style: normal;
            font-weight: 900;
            font-size: 24px;
            line-height: 28px;
        }
        .p-new-side{
            font-family: 'Mont';
            font-style: normal;
            font-size: 12px;
            line-height: 15px;
            /* identical to box height */

            letter-spacing: -0.3px;
        }

        @include media-breakpoint-up(md){
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            padding: 80px 20px 20px 50% !important;
            .h1-new-side{
                font-family: 'Mont';
                font-style: normal;
                font-weight: 900;
                font-size: 34px;
                line-height: 39px;
                /* or 115% */

                text-transform: uppercase;
            }
            .p-new-side{
                font-family: 'Mont';
                font-style: normal;
                font-size: 14px;
                line-height: 18px;
            }
            
        }
    }

    .bat-hero-gloit-contentfull{
        padding: 15% 0px 0px !important;
        @include media-breakpoint-down(md){
            padding: 0% 20px 0px !important;
            margin-top: -45px !important;
        }

        .h1-new-side{
            font-family: 'Mont';
            font-style: normal;
            font-weight: 900;
            font-size: 24px;
            line-height: 28px;
        }
        .p-new-side{
            font-family: 'Mont';
            font-style: normal;
            font-size: 12px;
            line-height: 15px;
            /* identical to box height */

            letter-spacing: -0.3px;
        }

        @include media-breakpoint-up(md){
            height: 100%;
            display: flex;
            flex-direction: column;
            //justify-content: flex-end;
            //padding: 80px 20px 20px 50% !important;
            .h1-new-side{
                font-family: 'Mont';
                font-style: normal;
                font-weight: 900;
                font-size: 34px;
                line-height: 39px;
                /* or 115% */

                text-transform: uppercase;
            }
            .p-new-side{
                font-family: 'Mont';
                font-style: normal;
                font-size: 14px;
                line-height: 18px;
            }
            
        }
    }

    .bat-hero-gloit-img-hero1{
        @include media-breakpoint-up(md){
            width: 78% !important;
        } 
        @include media-breakpoint-down(md){
            width: 89% !important;
            picture{
                width: 85% !important;
            }
        } 
    }
    .bat-hero-gloit-img-hero1full{
        @include media-breakpoint-up(md){
            width: 70% !important;
            margin-left: 80px;
        } 
        @include media-breakpoint-down(md){
            width: 89% !important;
            margin-left: 42px;
            picture{
                width: 85% !important;
            }
        } 
    }
    .bat-hero-gloit-content-container.left {
        @include media-breakpoint-up(md){
            padding-top: 60px;
        }
    }

    .bat-hero-gloit-content .bat-headline {
        @include media-breakpoint-down(md){
            margin-top: -110px;
        }
    }

    .bat-hero-gloit-content-container {
        @include media-breakpoint-down(md){
            margin-bottom: -8px;
        }
    }
    .bat-hero-gloit-content .bat-cta-style.button-dark{
        @include media-breakpoint-down(md){
            margin-top: -15px;
            margin-bottom: 5px;
        }
    }


    .bat-hero-gloit-content .bat-headline .bat-headline-style {
        @include media-breakpoint-down(md){
            width: 100% !important;
        }
    }

    .sidecard-container{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 40%;
        @include media-breakpoint-down(md){
            width: 100% !important;
        }
    }

    .hero-container{
        width: 60% !important;
        @include media-breakpoint-down(md){
            width: 100% !important;
        }
    }

    .herofull-container{
        width: 55% !important;
        @include media-breakpoint-down(md){
            width: 100% !important;
        }
    }


    .herobanner-container{
        display: flex !important;
        @include media-breakpoint-down(md){
            flex-direction: column;
        }
    }

    .herobannerfull-container{
        display: flex !important;
        margin-bottom: -15%;
        @include media-breakpoint-down(md){
            flex-direction: column;
            margin-bottom: 0%;
        }
    }

    .sidecard-container{
        .card{
            @include media-breakpoint-up(md){
                height: 100%;
            }
            .correct-height{
                @include media-breakpoint-up(md){
                    height: 100%;
                }
            }
        }
    }

    .bat-newsidecard-gloit-container-image{
        @include media-breakpoint-up(md){
            flex: 1;
        }
        picture{
            height: 100%;
            img{
                height: 100%;
                object-fit: cover;
            }
        }

    }

    .bat-newsidecard-gloit-container-subtitle{
        @include media-breakpoint-up(md){
            h3{
                margin-bottom: 5px;
            }
        }
    }
    


}
    


bat-card-gloitside {
    height: 100%;
    position: relative;
}

.bat-newsidecard-gloit{
    background-color: $concrete;
    //margin-left: 15px;
    justify-content: space-between;
    height: 100%;

    @include media-breakpoint-down(xl){
        margin: 0px 0px;
    }

    .zoom-animation {
        pointer-events: none;
        transition: transform 2s;
    }

    &:hover {
        .zoom-animation {
            transform: scale(1.2);
        }
    }

    &-cta-hover {
        position: absolute;
        height: 100%;
        width: 100%;
        &:hover~.bat-sidecard-gloit {
            .zoom-animation {
                transform: scale(1.2);
            }
        }
    }

    &-container{
        @include media-breakpoint-up(md){
            padding-left: 40px;
        }
        @include media-breakpoint-down(md){
            //padding: 15px 30px;
            width: 60%;
            padding-left: 22px;
        }

        &-title{
            @include font-rem(18px, 23px);
            text-transform: uppercase;
            font-weight: 800;
            margin-top: 20px;
            margin-bottom: 20px;
            
        }

        &-subtitle{
            @include media-breakpoint-down(md){
                //padding: 15px 30px;
                margin-right: 5px;
            }
        }
        &-image{
            @include media-breakpoint-down(md){
                //padding: 15px 30px;
                width: 40%
            }
        }

        h3 {
            @include font-rem(18px, 23px);
            text-transform: uppercase;
            font-weight: 800;
            margin-top: 20px;
            margin-bottom: 20px;
        }

        &-subtitle{
            @include font-rem(14px, 20px);

            @include media-breakpoint-down(xl){
                display: block
            }
        }

        &-cta{
            @include font-rem(15px, 28px);
            letter-spacing: 0.4px;
            margin-top: 20px;
        }

        &-price{
            .promo-price{
                @include font-rem(19px, 24px); 
                font-weight: 800;
                background-color: $orange;
                color: $white;
                padding: 8px;
                margin-right: 10px
            }

            .full-price{
                @include font-rem(13px, 17px);
                font-weight: 700;
                text-decoration: line-through;
            }
        }

        .price{
            @include font-rem(19px, 24px); 
            font-weight: 800;
            background-color: $orange;
            color: $white;
            padding: 8px;
            margin-right: 10px
        }

        .price-full{
            @include font-rem(13px, 17px);
            font-weight: 700;
            text-decoration: line-through;
        }

        &-ctas {
            padding: 20px 0px 20px 0px;
            /*
            @include media-breakpoint-up(sm){
                transition: transform .5s ease-out;
                &:hover {
                    transform: translateX(50px);
                }
            }	
            */		
        }
    }

    &.double-card{
        padding: 23px 15px;

        @include media-breakpoint-down(xl){
            //padding: 15px 30px;
        }
        
        .bat-sidecard-gloit-container{
            &-image{
                picture{
                    margin-left: auto;
                    width: 130px;

                    @include media-breakpoint-down(xl){
                        margin-left: auto;
                        max-width: none;
                    }
                }
            }
        }
    }

    &.single-card{
        
        //padding: 49px 15px;
        flex-direction: column-reverse;
    
        @include media-breakpoint-down(md){
            flex-direction: row;
            //padding: 15px 30px;
        }
    
        .bat-sidecard-gloit-container{
            @include media-breakpoint-down(md){
                width: 70%;
            }
            &-image{
                @include media-breakpoint-down(md){
                    display: flex !important;
                    //padding: 15px 30px;
                }
                picture{
                    margin-left: auto;
                    max-width: fit-content;

                    @include media-breakpoint-down(xl){
                        margin-left: auto;
                        max-width: none;
                    }
                }
            }
        }
    }
}