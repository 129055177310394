.bat-modal-content{
    margin: auto
}

.bat-agegate-gloit {
	
	//-----------------------------------------------------------------| Default
	&--default {
		
        .bat-modal {
            z-index: 10001;
			background-color: $blackish;
			background-image: url("/content/dam/glo-it/images/age-gate/sfondo-agegate-mobile.webp");
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
            @include media-breakpoint-up(sm) {
				background-image: url("/content/dam/glo-it/images/age-gate/sfondo-agegate.webp");
			}
		}

		&-content {
			padding: $gutter;

            width: 90%;
            margin: auto;
			
			@include media-breakpoint-down(sm) {
                width: 100%;
			}
			
			&-image {
				margin: auto;
				width: fit-content;
                img{
                    margin-bottom: 20px;
                    width: 90%;

                    @include media-breakpoint-up(sm) {
                        margin-bottom: 30px;
                    }
                }
			}
			
			&-greeting {
				
				&-headline {
					margin-bottom: $gutter;

                    .agegate-heading{
                        color: $brand-black;
                        @include font-rem(30px, 34px);
                        font-weight: 700;
                        font-family: $primary-font;
                        text-align: center;
                        padding-top: 20px;
                        padding-bottom: 20px;

                        @include media-breakpoint-down(sm){
                            @include font-rem(22px, 26px);
                        }
                    }
				}
				
				&-text {
                    color: $brand-black;
					margin-bottom: 30px;
                    @include font-rem(14px, 20px);
                    font-family: $primary-font;
                    text-align: center;
				}

                &-footnote{
                    .agegate-footnote{
                        color: $brand-black;
                        @include font-rem(12px, 17px);
                        font-family: $primary-font;
                        text-align: center;
                    }
                }
				
				&-cta-list {
                    width: fit-content;
                    margin: auto;
                    margin-bottom: 30px;

                    @include media-breakpoint-down(sm){
                        width: auto;
                    }

					.bat-cta-style {
                        &.button-dark{
                            margin-right: 10px;
                            @include media-breakpoint-down(sm){
                                margin-right: 0px;
                                margin: auto;
                                margin-bottom: 10px;
                            }
                        }

                        &.button-secondary-dark{
                            margin-left: 10px;
                            @include media-breakpoint-down(sm){
                                margin-left: 0px;
                                margin: auto;
                                margin-top: 10px;
                            }
                            &:hover {
                                color: $brand-black;
                                span {
                                    color: $brand-black;
                                }
                                span:hover {
                                    color: $brand-black;
                                }
                            }
                        }

						margin-bottom: 10px;

                        @include media-breakpoint-down(sm){
                            display: block;
                            width: 100%;
                        }
					}
				}
				
				&.in-active {
					display: none;
				}
			}
			
			&-error-message {
				display: none;
				
				&.active {
					display: block;
                    text-align: center;
				}
			}
		}
	}
}
