.aem-GridColumn{
	&.masthead-card{
		padding: 70px 55px;

		@include media-breakpoint-down(xl){
			padding: 0px;
			
			.aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--default--4 {
				width: 100%;
			}
		}
		@include media-breakpoint-down(sm){
			padding: 0px;
		}
	}
}
.bat-card-gloit {
	//-----------------------------------------------------------------| Default
	&--default {
		&-tile {
			margin-bottom: 20px;
			overflow: hidden;
			position: relative;

			&:after {
				bottom: 0;
				color: $dark-gray;
				content: "\e92c";
				font-family: "icomoon";
				@include font-rem(25px, 25px);
				position: absolute;
				right: 0;
				transition: all 0.3s ease;
				z-index: 3;
			}

			&:hover {
				&:after {
					color: $teal-dark;
					font-weight: 700;
				}
			}

			a {
				border: 2px solid $dark-gray;
				transform: translate3d(0, 0, 0);
				transition: border 0.3s ease 0s;

				&:before {
					border: 2px solid transparent;
					bottom: 0;
					content: "";
					left: 0;
					position: absolute;
					right: 0;
					top: 0;
					transition: border-top 0.3s ease;
					z-index: 1;
				}

				&:after {
					border-top: 2px solid $dark-gray;
					content: "";
					display: block;
					height: 90px;
					position: absolute;
					right: -10px;
					top: calc(100% - 38px);
					transform: skew(10deg, -25deg);
					transition: border-top 0.3s ease;
					width: 180px;
					z-index: 2;
				}

				@include media-breakpoint-up(lg) {
					&:hover {
						border-color: $teal-dark;

						&:before {
							border-color: $teal-dark;
						}

						&:after {
							border-top: 4px solid $teal-dark;
						}
					}
				}
			}

			&-headline {
				min-height: 74px;
				padding: 15px;

				h3 {
					@include font-rem(22px, 20px);
					font-weight: 700;
				}
			}
		}
	}

	//-----------------------------------------------------------------| Hero
	&--hero {
		&-content {
			&-headline {
				h2 {
					@include font-rem(44px, 41px);
					letter-spacing: -0.6px;

					@include media-breakpoint-up(lg) {
						@include font-rem(48px, 45px);
						letter-spacing: -1.4px;
					}
				}
			}

			&-cta-list {
				margin-top: 9px;

				@include media-breakpoint-up(lg) {
					margin-top: 5px;
				}
			}
		}
	}

	//-----------------------------------------------------------------| Category
	&--category {
		margin-bottom: 20px;

		&-content {
			&-headline {
				h4 {
					@include font-rem(24px, 24px);
					margin-bottom: 0;
				}
			}

			&-text {
				p {
					margin-bottom: 0;
				}
			}
		}
	}
	//-----------------------------------------------------------------| Step
	&--step {
		margin: 0 auto;
		padding-top: 240px;

		@include media-breakpoint-up(sm) {
			min-height: 30vh;
			padding-top: 246px;
		}

		@include media-breakpoint-up(md) {
			min-height: 325px;
			padding-top: 145px;
		}

		@include media-breakpoint-up(lg) {
			min-height: 450px;
			padding-top: 125px;
		}
	}
	//-----------------------------------------------------------------| Hustle
	&--hustle {
		margin: 0 auto;

		&-tile {
			&-headline {
				h3 {
					span {
						color: $teal-dark;
						display: block;
					}
				}
			}
		}
		button {
			&[data-cta-type="video"] {
				&:after {
					color: $white;
					content: "\f04b";
					font-family: "Font Awesome 5 Free";
					font-weight: 700;
					left: 50%;
					padding: 34px;
					position: absolute;
					top: 50%;
					transform: translate(-50%, -50%);
					@include font-rem(20px, 20px);
				}
				&:before {
					background-color: $dark-blue;
					border-radius: 50%;
					color: $white;
					content: "\f04b";
					font-family: "Font Awesome 5 Free";
					font-weight: 700;
					left: 50%;
					padding: 34px;
					position: absolute;
					top: 50%;
					transform: translate(-50%, -50%);
					@include font-rem(20px, 20px);
				}
			}
		}
	}

	//-----------------------------------------------------------------| Subscription
	&--subscription {
		background-color: $concrete;
		margin: 0 auto 20px;
		max-width: 275px;
		text-align: center;

		@include media-breakpoint-up(lg) {
			margin: 0 auto;
			max-width: none;
		}

		&-text {
			padding: 20px;

			> p {
				color: $dark-blue;
				margin: 0 auto;
				max-width: 186px;

				&:nth-child(1) {
					@include font-rem(20px, 22px);

					@include media-breakpoint-up(lg) {
						@include font-rem(23px, 27px);
					}
				}

				&:nth-child(2) {
					@include font-rem(14px, 16px);
					color: $dark-gray;
					margin-bottom: 12px;
					padding-bottom: 12px;

					@include media-breakpoint-up(lg) {
						@include font-rem(16px, 19px);
					}

					.gold & {
						border-bottom: solid 2px $gold;
					}

					.silver & {
						border-bottom: solid 2px $silver;
					}

					.bronze & {
						border-bottom: solid 2px $bronze;
					}
				}

				&:nth-child(3) {
					@include font-rem(30px, 32px);
					font-weight: 700;

					@include media-breakpoint-up(lg) {
						@include font-rem(39px, 47px);
					}
				}

				&:nth-child(4) {
					@include font-rem(17px, 19px);
					font-weight: 700;

					@include media-breakpoint-up(lg) {
						@include font-rem(20px, 24px);
					}
				}
			}
		}

		.gold & {
			.bat-label {
				@extend .bat-label--subscription-gold;
			}
		}

		.silver & {
			.bat-label {
				@extend .bat-label--subscription-silver;
			}
		}

		.bronze & {
			.bat-label {
				@extend .bat-label--subscription-bronze;
			}
		}

		//-----------------------------------------------------------------| Subscription small
		.small & {
			background-color: $white;
			max-width: 190px;

			.bat-label--subscription {
				border-radius: 10px 10px 0 0;
			}

			h3 {
				@include font-rem(30px, 36px);

				span {
					display: block;

					&:nth-child(1) {
						@include font-rem(14px, 14px);
						font-weight: 400;
					}

					&:nth-child(2) {
						color: $dark-gray;
						@include font-rem(12px, 12px);
						font-weight: 400;
					}
				}
			}

			&-text {
				padding: 7px 20px 14px;

				> p {
					&:nth-child(1) {
						@include font-rem(27px, 27px);
						font-weight: 700;
						margin-bottom: 2px;
					}

					&:nth-child(2) {
						border-bottom: 0;
						color: $dark-blue;
						@include font-rem(16px, 16px);
						font-weight: 700;
						margin-bottom: 0;
						padding-bottom: 0;
					}
				}
			}
		}

		//sass-lint:disable force-element-nesting
		.small.gold &,
		.small.silver &,
		.small.bronze & {
			border-radius: 10px;

			.bat-label--subscription-lg {
				&:after {
					right: -100px;
				}
			}
		}

		.small.gold & {
			border: solid 1px $gold;

			&-text {
				> p {
					&:nth-child(1) {
						color: $gold;
					}
				}
			}
		}

		.small.silver & {
			border: solid 1px $silver;

			&-text {
				> p {
					&:nth-child(1) {
						color: $silver;
					}
				}
			}
		}

		.small.bronze & {
			border: solid 1px $bronze;

			&-text {
				> p {
					&:nth-child(1) {
						color: $bronze;
					}
				}
			}
		}
	}

	//-----------------------------------------------------------------| 404 Error
	&--404-error {
		margin: 5% 0 40px;
		text-align: center;

		&-heading {
			margin: 20px 0 10px;
		}

		&-text {
			min-height: 6ch;
		}

		&-cta {
			@include media-breakpoint-down(md) {
				margin-left: auto;
				margin-right: auto;
				max-width: 400px;
			}
			a {
				margin: 0 10px;
			}
			@include media-breakpoint-down(lg) {
				& .bat-cta-style {
					&:last-of-type {
						margin-top: 15px;
					}
				}
			}
		}
	}

	//-----------------------------------------------------------------| Masthead

	@include media-breakpoint-down(sm){
		padding-bottom: 4px;
	}

	&--masthead {
		overflow: hidden;
		position: relative;

		&-main {
			height: 100%;
			position: absolute;
			top: 0;
			width: 90%;
			padding: 40px 30px 0px;
			pointer-events: none;
			a,button {
				pointer-events: all;
			}

			@include media-breakpoint-down(sm) {
				top: unset;
				bottom: 5px;
			}

			@include media-breakpoint-up(md) {
				width: 80%;
				padding: 40px 30px 0px
			}

			span,
			p {
				&.dark-gray,
				.dark-gray {
					color: $dark-gray;
				}

				&.dark,
				.dark {
					color: $dark-blue;
				}

				&.light,
				.light {
					color: $white;
				}

				&.sm,
				.sm {
					@include font-rem(16px, 18px);
					font-weight: 400;
					display: block;
				}
			}
		}

		&-text{
			@include font-rem(22px, 28px);
			font-family: $primary-font;
			font-weight: 800;
			color: $white;
			> h1,h2,h3,h4,h5,h6,p,span {
				font: inherit;
			}

			> h1,h2,h3,h4,h5,h6 {
				margin-top: $grid-gutter-size;
			}
		}

		&-ctalist{
			position: absolute;
			bottom: 15px;
			
			@media (max-width: 375px) {
				bottom: 0px;
			}
			@include media-breakpoint-down(sm) {
				padding-top: 13px;	
			}

			.bat-cta-style{
				padding: 7px 15px;
				border-color: $white;
				color: $white;
			}
			.button-secondary-dark{
				border-color: $black;
				color: $black;
			}
		}

		&-img {
			height: inherit;
			
			> a {
				display: block;
			}
			
			picture {
				height: 100%;
				width: initial;
			}
		}

		.zoom-animation {
			transition: transform 2s;
			&:hover {
				transform: scale(1.2);
			}
		}
	}

	//-----------------------------------------------------------------| Blurb
	&--blurb {
		height: 600px;
		overflow: hidden;
		position: relative;
		@include media-breakpoint-down(sm) {
			height: 495px;
		}

		&-text {
			h2, h3, h4 {
				@include font-rem(22px, 28px);
				margin-block-end: 8px;
			}

			.card-price {
				display: block;
				background: $orange;
				width: fit-content;
				padding-top: 6px;
				padding-bottom: 6px;
				padding-left: 9px;
				padding-right: 13px;
				margin-bottom: 20px;
				@include font-rem(30px, 32px);
				font-weight: bold;
				font-family: $primary-font;
			}
			.card-\&more {
				content: url('/content/dam/glo/images/glo_and-more-branding.svg');
			}

			.bat-headline-style {
				.headline2-color1 {
					@include font-rem(22px, 28px);
				}
			}

			@include media-breakpoint-down(sm) {
				h2, h3, h4 {
					@include font-rem(22px, 28px);
				}
			}

			p {
				@include font-rem(16px, 20px);
			}
		}

		&-main,
		&-img {
			position: absolute;
		}

		&-main {
			bottom: 0;
			left: 0;
			padding: 30px;
			color: $white;
		}

		&-img {
			bottom: 0;
			left: 0;
			right: 0;
			top: 0;

			picture,
			img {
				height: 100%;
				object-fit: cover;
				width: auto;
			}
			@include media-breakpoint-down(sm) {
				picture,
				img {
					height: 100%;
					object-fit: cover;
					width: 100%;
				}
			}
		}

		&-ctalist {
			margin-top: 20px;
			@include media-breakpoint-down(sm) {
				margin-top: 10px;
			}
			.button-secondary-light {
				&, &:hover, &:active {
					border-color: $white;
				}
				padding-top: 7px;
				padding-bottom: 7px;
				padding-left: 20px;
				padding-right: 20px;
			}
		}

		.zoom-animation {
			transition: transform 2s;
			&:hover {
				transform: scale(1.2);
			}
		}
	}

	//-----------------------------------------------------------------| Offer Card
	&--offer {
		&-wrapper {
			align-content: space-between;
			display: flex;
			flex-flow: row wrap;
			min-height: 320px;

			&-content {
				padding: 1rem 1.5rem;

				&-headline {
					margin-bottom: 1rem;
					.purple & {
						color: $purple;
					}
				}

				&-specialcta {
					align-items: center;
					background-color: $purple;
					color: $white;
					display: flex;
					padding: 0.5rem 1.25rem;

					a {
						align-items: center;
						display: flex;
					}

					.bat-headline-style {
						@include font-rem(20px, 20px);
						margin-left: 1rem;

						div {
							@extend p;
							@include font-rem(14px, 14px);
							font-weight: bold;
							margin-bottom: 0;
							margin-top: 3px;
						}
					}
				}

				&-text {
					margin-top: 1rem;
					p {
						@include font-rem(15px, 15px);
					}
				}
			}
			&-ctalist {
				margin-bottom: 1rem;
				margin-top: 1rem;
				padding: 1rem 1.5rem;
			}
		}
	}

	//-----------------------------------------------------------------| News Card
	&--news {
		margin-top: 8px;

		@include media-breakpoint-down(xs) {
			margin-bottom: 0;
			margin-top: 0;
			padding: 40px 0 30px;
		}

		&-img {
			height: 100%;
			max-height: 240px;
			overflow: hidden;

			a {
				display: block;
			}
		}

		&-text {
			margin: 15px 0;

			@include media-breakpoint-down(xs) {
				margin: 8px 0 5px;
			}

			h3 {
				font-size: 20px;
				line-height: 150%;
				padding-top: 15px;

				@include media-breakpoint-down(xs) {
					padding-top: 16px;
				}
			}
			p {
				font-size: 15px;
				line-height: 150%;
				margin-top: 5px;

				@include media-breakpoint-down(xs) {
					color: $dark-gray;
				}
			}
		}
		&-ctalist {
			.bat-cta-style {
				font-size: 15px;
				line-height: 150%;
				margin-top: 5px;

				@include media-breakpoint-down(sm) {
					margin-top: 0;
				}
			}
		}
	}

	//-----------------------------------------------------------------| Avalanche - Blog Card
	&--blog {
		@include media-breakpoint-down(md) {
			border-bottom: 2px solid $light-gray;
			margin-bottom: 30px;
			padding-bottom: 15px;
		}

		&-img {
			overflow: hidden;
		}

		&-main {
			padding: 0 20px 0 0;
		}

		&-text {
			p {
				&:first-of-type {
					margin-top: 30px;
				}

				.sm {
					// styleguide override
					@include font-rem(15px, 22.5px);
				}
			}

			h3 {
				// styleguide override
				@include font-rem(20px, 25px);
				margin: 10px 0;
				@include media-breakpoint-down(xs) {
					font-weight: 400;
				}
			}
		}

		&-ctalist {
			margin: 15px 0 0;

			// styleguide override - cta - link dark
			a {
				font-weight: 700;

				@include media-breakpoint-down(xs) {
					font-weight: 400;
				}

				// styleguide override - cta icon - link dark
				i {
					&:before {
						font-weight: 700;
					}
				}
			}

			.bat-cta-style {
				&.link-dark {
					i {
						&:before {
							font-size: 18px;
						}
					}
				}
			}
		}
	}
}

//Background Classes
bat-card-step {
	&.step1,
	&.step2,
	&.step3,
	&.step4 {
		position: relative;

		&:before {
			@extend h1;
			color: $off-white;
			font-size: 220px;
			line-height: 220px;
			position: absolute;
			top: 0;

			@include media-breakpoint-up(md) {
				font-size: 160px;
				line-height: 160px;
			}

			@include media-breakpoint-up(lg) {
				font-size: 220px;
				line-height: 220px;
			}
		}
	}
}

// Masthead Card
bat-card-gloit-masthead {
	&.masthead {
		&-full,
		&-full__reverse {
			.bat-card-gloit--masthead {
				height: 470px;

				&-main {
					box-sizing: border-box;
					padding: 5%;
				}

				&-img {
					bottom: 0;
					clip-path: none;
					overflow: hidden;
					position: absolute;
					top: 0;
					width: 100%;

					@include media-breakpoint-up(md) {
						width: 55%;
					}

					picture {
						height: inherit;
						width: initial;
					}

					img {
						height: inherit;
						width: auto;

						@include media-breakpoint-up(lg) {
							width: 100%;
						}
					}
				}

				&-ctalist {
					margin-top: 30px;
				}
			}
		}

		&-full {
			.bat-card-gloit--masthead {
				&-img {
					left: 0;

					@include media-breakpoint-up(md) {
						clip-path: polygon(0% 0%, 100% 0, 65% 100%, 0 100%);
					}
				}
				&-main {
					right: 0;
				}
			}
		}

		&-full__reverse {
			.bat-card-gloit--masthead {
				&-img {
					right: 0;
				}

				&-main {
					left: 0;
				}
			}
		}

		&-blurb {
			margin-bottom: 3%;

			@include media-breakpoint-up(md) {
				margin-bottom: 0;
			}

			.bat-card-gloit--masthead {
				height: 200px;
				@include media-breakpoint-up(sm) {
					height: 260px;
				}

				.button-secondary {
					&-dark, &-light {
						&:hover, &:active {
							background-color: transparent;
						}
					}
					&-dark {
						&, &:hover, &:active {
							border-color: $brand-black;
							&, span {
								color: $brand-black;
							}
						}
					}
					&-light {
						&, &:hover, &:active {
							border-color: $white;
							&, span {
								color: $white;
							}
						}
					}
				}

				&-main {
					box-sizing: border-box;
					height: 100%;
					padding: 2.5%;
					width: 60%;

					@include media-breakpoint-up(xxs) {
						width: 65%;
					}
				}

				img {
					object-fit: cover;
					@include media-breakpoint-up(xxs) {
						height: 100%;
						width: 100%;
					}

					@include media-breakpoint-up(xs) {
						height: 100%;
						width: 100%;
					}
				}
			}

			&__left,
			&__right,
			&__center {
				.bat-card-gloit--masthead {
					&-ctalist {
						margin-top: 15px;
						width: 100%;
					}
				}
			}

			&__left {
				.bat-card-gloit--masthead {
					&-main,
					&-ctalist {
						left: 15px;
					}

					@include media-breakpoint-down(sm) {
						&-img {
							picture, img {
								object-position: center right;
							}
						}
					}

					&-main {
						text-align: left;
					}
				}
			}

			// right content
			&__right {
				.bat-card-gloit--masthead {
					&-main,
					&-ctalist {
						right: 15px;
					}

					@include media-breakpoint-down(sm) {
						&-img {
							picture, img {
								object-position: center left;
							}
						}
					}

					&-main {
						text-align: right;
					}
				}
			}

			&__center {
				.bat-card-gloit--masthead {
					&-main,
					&-ctalist {
						left: 0;
						right: 0;
						text-align: center;
						width: auto;
					}

					@include media-breakpoint-down(sm) {
						&-img {
							picture, img {
								object-position: center center;
							}
						}
					}
				}
			}
		}
	}

	// Authorable class "cover" to make background image cover
	&.cover {
		.bat-card-gloit--masthead-img {
			img {
				height: 100%;
				object-fit: cover;
			}
		}
	}
}

//Thank you card style
bat-card-gloitmasthead {
	&.thank-you-card {
		border-radius: 40px;
		@include media-breakpoint-down(md) {
			margin: 20px;
		}
		.bat-card-gloit--masthead {
			display: flex;
			padding: 50px;
			@include media-breakpoint-down(md) {
				flex-direction: column;
				padding: 30px;
			}
			&-img {
				position: relative;
				@include media-breakpoint-down(md) {
					width: 93px;
					margin: auto;
					margin-bottom: 20px;
				}
			}
			&-main {
				position: relative;
				width: auto;
				padding: 0 0 0 50px;
				@include media-breakpoint-down(md) {
					padding: 0;
				}
			}
			&-ctalist {
				position: unset;
				@include media-breakpoint-down(md) {
					text-align: center;
				}
				.bat-cta-style {
					color: $black;
					padding: 13px 25px;
					font-size: 16px;
					background-color: $accent-orange;
					@include media-breakpoint-down(md) {
						padding: 13px 20px;
					}
					&.button-dark:hover span {
						color: $black;
					}
				}
			}
			&-footernotes {
				text-align: center;
				color: $accent-orange;
				font-size: 14px;
    			margin-top: 15px;
			}
			&-text {
				font-size: 34px;
    			line-height: 40px;
				margin-bottom: 30px;
				@include media-breakpoint-down(md) {
					font-size: 28px;
					line-height: 33px;
					text-align: center;
				}
				P {
					.dark {
						font-size: 20px;
						line-height: 28px;
						margin: 15px 0;
						@include media-breakpoint-down(md) {
							font-size: 18px;
							line-height: 25px;
						}
					}
				}

			}
		}
	}
}

bat-card-simpleproduct {
	background-color: $dark-blue;

	.bat-card {
		&--simpleproduct {
			display: flex;
			flex-wrap: wrap;

			@include media-breakpoint-up(lg) {
				flex-wrap: nowrap;
			}

			&-img {
				display: flex;
				justify-content: center;
				width: 100%;

				@include media-breakpoint-up(lg) {
					width: 33%;
				}

				picture {
					max-width: 500px;
				}
			}
			&-main {
				width: 100%;

				@include media-breakpoint-up(lg) {
					padding-left: 1rem;
					width: 67%;
				}

				&-title {
					color: $white;
					margin-bottom: 1rem;

					@include media-breakpoint-down(md) {
						display: flex;
						justify-content: center;
					}

					h3 {
						@include font-rem(26px, 26px);
					}
				}

				&-text {
					color: $white;
					margin-bottom: 1rem;

					@include media-breakpoint-down(md) {
						display: flex;
						justify-content: center;
					}
				}

				&-ctalist {
					color: $white;
					display: flex;

					@include media-breakpoint-down(md) {
						display: flex;
						justify-content: center;
					}

					&-button {
						border: 1px solid $white;
						padding: 0.5rem 1rem;

						@include media-breakpoint-up(xs) {
							padding: 0.5rem 1.75rem;
						}

						@include media-breakpoint-up(md) {
							padding: 0.5rem 2rem;
						}

						div {
							@include font-rem(12px, 8px);
							margin-top: 5px;
							text-align: center;
							width: 100%;
						}
						.bat-icon {
							display: inline-flex;
							width: 10px;

							&::before {
								font-size: 12px;
							}
						}
					}
				}
			}
		}
	}
}

//-----------------------------------------------------------------| News Card HP mobile
@include media-breakpoint-down(xs) {
	.news-card-first {
		.bat-card--news {
			padding-top: 18px;
		}
	}

	.news-card-last {
		.bat-card--news {
			border: 0;
			margin-bottom: 0;
			padding-bottom: 0;
		}
	}
}
//-----------------------------------------------------------------| Recommended
.bat-productcard-gloithome {
	width: fit-content;
	position: relative;

	.authorEditMode & {
		.bat-productcard-gloithome {
			max-width: 200px;
		}
	}

	.price-mobile{
		font-weight: 800;
	}
	.price-arrow{
		margin-top: 5px;
		margin-left: 10px;
	}
	.label {
		top: 0;
		background-color: $charcoal;
		color: $white;
		padding: 5px 10px;
		font-size: 10px;
		line-height: 10.5px;
		font-weight: 800;
	}
	.promo {
		position: absolute;
		bottom: 0;
		background-color: rgba($orange, 0.2);
		text-align: center;
		width: 100%;
		font-weight: 800;
		line-height: 13.75px;
		font-size: 11px;
		padding: 7px 0;
	}
	.logo {
		top: 0;
		right: 0;
	}

	.text {
		margin-top: 5px;
		font-size: 16px;
	}
	.cart-link-container {
		.button-cart{
			@include media-breakpoint-up(sm){
				&:hover {
					transform: translateX(50px);
				}
				transition: all .5s ease-out;
			}
		}
		
		margin-top: 10px;
		padding-top: 10px;
		border-top: 2px $light-gray solid;
		a {
			text-decoration: none;
			color: $brand-black;
			&:hover,
			a:active,
			a:focus {
				text-decoration: none;
				color: $brand-black;
			}
			span {
				@include font-rem(16px, 28px);
				letter-spacing: 1px;
				text-decoration: none;
				color: $brand-black;
				font-weight: 700;
				margin-right: 10px;
			}
		}
	}
	.price {
		margin-top: 5px;
		color: $brand-black;
		font-size: 16px;
		img {
			margin-left: 10px;
		}
	}
}


.bat-productcard-gloitblackfriday {
	width: fit-content;
	position: relative;
	padding-left: 17px;
    padding-right: 17px;

	.img-wrapper{
		picture{
			img{
				height: 290px;
				object-fit: cover;
				@include media-breakpoint-down(md) {
					height: 210px;
				}
			}
		}
	}

	.authorEditMode & {
		.bat-productcard-gloithome {
			max-width: 200px;
		}
	}

	.price-mobile{
		font-weight: 800;
	}
	.price-arrow{
		margin-top: 5px;
		margin-left: 10px;
	}
	.label {
		top: 0;
		background-color: $charcoal;
		color: $white;
		padding: 5px 10px;
		font-size: 10px;
		line-height: 10.5px;
		font-weight: 800;
	}
	.promo {
		position: absolute;
		bottom: 0;
		background-color: rgba($orange, 0.2);
		text-align: center;
		width: 100%;
		font-weight: 800;
		line-height: 13.75px;
		font-size: 11px;
		padding: 7px 0;
	}
	.logo {
		top: 0;
		right: 0;
	}

	.text {
		margin-top: 5px;
		font-size: 16px;
		a{
			font-size: 18px;
			font-style: normal;
			font-weight: 800;
			line-height: 18px;
			height: 2em;
		}
		.color-container{
			display: flex;
			align-items: center;
			gap: 3%;
			margin-top: 3%;
			span{
				color:#6B6B6B;
				font-family: Mont;
				font-size: 16px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
			}
			.color-circle{
				width: 15px;
				height: 15px;
				border-radius: 100%;
			}
		}
	}
	.cart-link-container {
		
		margin-top: 10px;
		padding-top: 10px;
		a, button {
			width: 100% !important;
			text-decoration: none;
			width: auto;
			padding: 12px 24px;
			justify-content: center;
			align-items: center;
			span {
				@include font-rem(16px, 28px);
				letter-spacing: 1px;
				text-decoration: none;
				color: white;
				font-weight: 700;
				margin-right: 10px;
				@include media-breakpoint-down(sm) {
					text-align: center;
					font-family: Mont;
					font-size: 11px;
					font-style: normal;
					font-weight: 800;
					line-height: 120%;
					text-transform: uppercase;
				}
			}
		}
	}
	.price {
		margin-top: 5px;
		color: #2A2C2C;
		font-size: 16px;
		font-style: normal;
		font-weight: 800;
		line-height: 24px; 
		
		img {
			margin-left: 10px;
		}
	}

	.price-regular{
		margin-top: 5px;
		color: #6B6B6B;
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: 24px;
		text-decoration-line: line-through;

		@include media-breakpoint-down(sm) {
			font-size: 14px;;
		}
	}

	.price-rating-container{
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-top: 2px #dedede solid;
		margin-top: 5px;
		.price-container{
			display: flex;
			flex-direction: row;
			margin-top: 5px;
			gap:10px;
		}
		.review-container{
			padding-bottom: 0;
			margin-bottom: 0;
			margin-top: 5px;
			height: 12px;
		
			.bat-icon{
				height: 13px;
				width: 13px;
				&::before{
					font-size: 13px;
				}
				
			}
			.number-review{
				margin-left: 5px;
				font-size: 12px;
				margin-top: -4px;

				@include media-breakpoint-down(sm) {
					font-size: 10px;
				}
			}
			.rating {
				display: inline-block;
				unicode-bidi: bidi-override;
				color: #dedede;
				font-size: 14px;
				letter-spacing: 1px;
				height: 23px;
				width: auto;
				margin: 0;
				margin-top: -4px;
				position: relative;
				padding: 0;
				font-family: FontAwesome;

				@include media-breakpoint-down(sm) {
					font-size: 10px;
				}
			}
			  
			.rating-upper {
				color: black;
				padding: 0;
				position: absolute;
				z-index: 1;
				display: flex;
				top: 0;
				left: 0;
				overflow: hidden;
				&::before {
					content: "\f005 \f005 \f005 \f005 \f005";
				}
			}
			  
			.rating-lower {
				padding: 0;
				display: flex;
				z-index: 0;
				&::before {
					content: "\f005 \f005 \f005 \f005 \f005";
				}
			}
		}
	}	
	
	
}
//-----------------------------------------------------------------| Recommended
.bat-productcard-gloithome {
	width: fit-content;
	position: relative;

	.authorEditMode & {
		.bat-productcard-gloithome {
			max-width: 200px;
		}
	}

	.price-mobile{
		font-weight: 800;
	}
	.price-arrow{
		margin-top: 5px;
		margin-left: 10px;
	}
	.label {
		top: 0;
		background-color: $charcoal;
		color: $white;
		padding: 5px 10px;
		font-size: 10px;
		line-height: 10.5px;
		font-weight: 800;
	}
	.promo {
		position: absolute;
		bottom: 0;
		background-color: rgba($orange, 0.2);
		text-align: center;
		width: 100%;
		font-weight: 800;
		line-height: 13.75px;
		font-size: 11px;
		padding: 7px 0;
	}
	.logo {
		top: 0;
		right: 0;
	}

	.text {
		margin-top: 5px;
		font-size: 16px;
	}
	.cart-link-container {
		.button-cart{
			@include media-breakpoint-up(sm){
				&:hover {
					transform: translateX(50px);
				}
				transition: all .5s ease-out;
			}
		}
		
		margin-top: 10px;
		padding-top: 10px;
		border-top: 2px $light-gray solid;
		a {
			text-decoration: none;
			color: $brand-black;
			&:hover,
			a:active,
			a:focus {
				text-decoration: none;
				color: $brand-black;
			}
			span {
				@include font-rem(16px, 28px);
				letter-spacing: 1px;
				text-decoration: none;
				color: $brand-black;
				font-weight: 700;
				margin-right: 10px;
			}
		}
	}
	.price {
		margin-top: 5px;
		color: $brand-black;
		font-size: 16px;

		@include media-breakpoint-down(sm) {
			font-size: 15px;;
		}

		img {
			margin-left: 10px;
		}
	}
}
//-----------------------------------------------------------------| glotiHomeNew
.bat-productcard-gloithomenew {
	width: 90%;
	position: relative;

	.img-wrapper{
		picture{
			img{
				height: 220px;
				@include media-breakpoint-down(md) {
					height: 190px;
				}
			}
		}
	}

	.authorEditMode & {
		.bat-productcard-gloithome {
			max-width: 200px;
		}
	}
	.button-cart-card{
		width: 100%;
		
		a{
			width: 100%;
			padding: 10px;
			justify-content: center !important;
		}
		
	}
	.price-mobile{
		font-weight: 800;
	}
	.price-arrow{
		margin-top: 5px;
		margin-left: 10px;
	}
	.label {
		top: 0;
		background-color: $charcoal;
		color: $white;
		padding: 5px 10px;
		font-size: 10px;
		line-height: 10.5px;
		font-weight: 800;
	}
	.promo {
		position: absolute;
		bottom: 0;
		background-color: rgba($orange, 0.2);
		text-align: center;
		width: 100%;
		font-weight: 800;
		line-height: 13.75px;
		font-size: 11px;
		padding: 7px 0;
	}
	.logo {
		top: 0;
		right: 0;
	}

	.text {
		margin-top: 5px;
		font-size: 16px;
		.title-card{
			color: #1D1D1B;
			font-size: 16px;
			font-style: normal;
			font-weight: 800;
			line-height: 18px;
		}
	}
	.cart-link-container {
		/*
		.button-cart{
			@include media-breakpoint-up(sm){
				&:hover {
					transform: translateX(50px);
				}
				transition: all .5s ease-out;
			}
		}
		
		margin-top: 10px;
		padding-top: 10px;
		border-top: 2px $light-gray solid;
		a {
			text-decoration: none;
			color: $brand-black;
			&:hover,
			a:active,
			a:focus {
				text-decoration: none;
				color: $brand-black;
			}
			span {
				@include font-rem(16px, 28px);
				letter-spacing: 1px;
				text-decoration: none;
				color: $brand-black;
				font-weight: 700;
				margin-right: 10px;
			}
		}
		*/
	}

	.review-stars{
		width: 100%;
		padding: 15px 0px 15px 0px;
		border-top: 2px solid #dedede;
		margin-top: 15px
	}
	.price {
		color: var(--glo-brand-black, #2A2C2C);
		font-family: Mont;
		font-size: 16px;
		font-style: normal;
		font-weight: 800;
		line-height: 24px;
		img {
			margin-left: 10px;
		}
	}
	.price-sale {
		color: var(--glo-text-colour-on-concrete, #6B6B6B);
		font-size: 16px;
		font-weight: 500;
		line-height: 24px; /* 150% */
		margin-left: 15px;
		margin-right: 30px;
		@include media-breakpoint-down(md) {
			margin-left: 6px;
			margin-right: 5px;
		}
		img {
			margin-left: 10px;
		}
	}

	.rating {
		display: inline-block;
		unicode-bidi: bidi-override;
		color: #dedede;
		font-size: 10px;
		letter-spacing: 2px;
		height: 23px;
		width: auto;
		margin: 0;
		position: relative;
		padding: 0;
		font-family: FontAwesome;
	}
	.totalReviews{
		color: var(--glo-brand-black, #2A2C2C);
		text-align: right;
		font-family: Mont;
		font-size: 10px;
		font-style: normal;
		font-weight: 500;
		line-height: 24px;
		margin-left: 5px;
	}
	  
	.rating-upper {
		color: black;
		padding: 0;
		position: absolute;
		z-index: 1;
		display: flex;
		top: 0;
		left: 0;
		overflow: hidden;
		&::before {
			content: "\f005 \f005 \f005 \f005 \f005";
		}
	}
	  
	.rating-lower {
		padding: 0;
		display: flex;
		z-index: 0;
		&::before {
			content: "\f005 \f005 \f005 \f005 \f005";
		}
	}

	.bat-producthero-configurator {
		display: flex;
		flex-direction: column;
		padding: 0;
		width: 100%;

		& > * {
			padding-left: 20px;
			padding-right: 20px;
		}
		.bat-producthero-strengths-indicator-line, .bat-producthero-strengths-group {
			margin-left: 20px;
			margin-right: 20px;
		}

		&-bottom {
			flex-direction: column;
			justify-content: flex-start;
			margin-bottom: 43px;
			padding: 0 20px;
			text-align: left;
		}

		&-cta-list {
			display: flex;
			flex-direction: column;
			flex: 1 1 auto;
			margin-left: auto;
			align-items: center;
			width: 100%;
			button, a {
				max-width: 300px;
				padding: 11px 50px;
				width: fit-content;
			}
			button {
				display: none;
				&.active {
					display: flex;
				}
			}
			a {
				display: flex;
			}
			button, a {
				&:not(:last-child) {
					margin-bottom: 0.5em;
				}
			}
		}

		&-price {
			flex: 0 1 auto;
			margin-top: 10px;
			margin-bottom: 10px;
			text-align: left;
		}

		&-subheading {
			@include font-rem(20px, 28px);
			font-weight: 500;
			letter-spacing: 1.5px;
			margin-bottom: 8px;
			padding-bottom: 0;
			padding-top: 0;
			text-align: left;
			text-transform: capitalize;
		}

		&-short-description {
			h6 {
				@include font-rem(14px, 14px);
				font-weight: 700;
				margin-bottom: 15px;
			}
		}

		&-description {
			@include font-rem(14px, 20px);
			color: $dark-gray;

			h1,
			h2,
			h3,
			h4,
			h5,
			h6 {
				color: inherit;
				font-size: inherit;
				line-height: inherit;
				margin-bottom: 7px;
			}

			p {
				color: inherit;
				font-size: inherit;
				line-height: inherit;
				margin-bottom: 0;
				&:not(:last-of-type) {
					margin-bottom: 5px;
				}
			}

			ul {
				margin-bottom: 15px;

				li {
					color: inherit;
					font-size: inherit;
					line-height: inherit;
				}
			}
		}

		&-stock {
			display: block;
			font-weight: 700;
			margin-bottom: 20px;
		}

		&-subscription {
			background-color: $concrete;
			margin: 0 auto;
			max-width: 325px;

			&-text {
				@include font-rem(12px, 12px);
				margin-top: 2px;

				.formatted-price {
					font-weight: normal;
				}

				&-note {
					@include font-rem(12px, 12px);
					display: inline-block;
					font-weight: normal;
					margin: 7px 0 0;
					text-transform: none;
				}

				.discount-savings {
					@include font-rem(12px, 12px);
					background-color: $dark-blue;
					color: $white;
					display: inline-block;
					padding: 2px 5px;
				}
			}

			&-max-message {
				margin-top: 0;
			}

			&-details {
				button {
					color: $dark-blue;
					font-weight: 700;
				}
			}

			fieldset {
				padding: 0;

				label {
					align-content: flex-start;
					align-items: flex-start;
					height: auto;
					min-height: 29px;

					input {
						margin-top: 2px;
					}
				}
			}
		}
		&-configurable-options {
			display: flex;
			flex-direction: row;
			text-align: left;
			width: auto;

			.size-container{
				border-radius: 100%;
				margin-right: 2px;
				margin-top: 5px;
				border: 1px solid transparent;


				

				.size{
					border-radius: 100%;
					width: 30px;
					position: relative;
					height: 30px;
					background-color: $concrete;
					color: $dark-gray;
					font-weight: 600;
					font-size: 12px;
					font-family: $primary-font;
					margin: 2px;
	
					&:hover{
						border: 1px solid $brand-black;
						color: $brand-black;
					}
		
					&:active{
						background-color: $white;
						box-shadow: 0 0 3px #1d1d1d;
						border: 1px solid $white;
						background-color: $white;
						color: $brand-black;
						
					}
					&:disabled{
						&:hover{
							border: 0;
							color: $dark-gray;
						}
						&:active{
							background-color: $concrete;
							color: $brand-black;
							box-shadow: 0 0 0 #1d1d1d;
							color: $dark-gray;
						}
						&:after {
							height:23px;
							width:26px;
							background: linear-gradient(to left top,rgba(255,255,255,0) 0%,rgba(255,255,255,0) 42%,#fff 43%,#fff 46%,#ff5216 47%,#ff5216 53%,#fff 54%,#fff 57%,rgba(255,255,255,0) 58%,rgba(255,255,255,0) 100%);
							background: -moz-linear-gradient(to left top,rgba(255,255,255,0) 0%,rgba(255,255,255,0) 42%,#fff 43%,#fff 46%,#ff5216 47%,#ff5216 53%,#fff 54%,#fff 57%,rgba(255,255,255,0) 58%,rgba(255,255,255,0) 100%);
							background: -ms-linear-gradient(to left top,rgba(255,255,255,0) 0%,rgba(255,255,255,0) 42%,#fff 43%,#fff 46%,#ff5216 47%,#ff5216 53%,#fff 54%,#fff 57%,rgba(255,255,255,0) 58%,rgba(255,255,255,0) 100%);
							background: -o-linear-gradient(to left top,rgba(255,255,255,0) 0%,rgba(255,255,255,0) 42%,#fff 43%,#fff 46%,#ff5216 47%,#ff5216 53%,#fff 54%,#fff 57%,rgba(255,255,255,0) 58%,rgba(255,255,255,0) 100%);
							background: -webkit-gradient(linear,left top,right bottom,color-stop(0%,rgba(255,255,255,0)),color-stop(42%,rgba(255,255,255,0)),color-stop(43%,#fff),color-stop(46%,#fff),color-stop(47%,#ff5216),color-stop(53%,#ff5216),color-stop(54%,#fff),color-stop(57%,#fff),color-stop(58%,rgba(255,255,255,0)),color-stop(100%,rgba(255,255,255,0)));
							background: -webkit-linear-gradient(to left top,rgba(255,255,255,0) 0%,rgba(255,255,255,0) 42%,#fff 43%,#fff 46%,#ff5216 47%,#ff5216 53%,#fff 54%,#fff 57%,rgba(255,255,255,0) 58%,rgba(255,255,255,0) 100%);
							bottom: 0;
							content: '';
							
							left: 0;
							position: absolute;
							right: 0;
							top: 0;
						}
					}
				}

				&.active{
					.size{
						border: 1px solid $brand-black;
						color: $brand-black;
					}
				}

			}

			&-title {
				@include font-rem(12px, 16px);
				font-weight: 700;
				letter-spacing: 0;
				margin-top: 5px;
				margin-bottom: 5px;
			}
			
			&-variants-button {
				$button-size: 24px;
				height: $button-size;
				width: $button-size;
				border-radius: 99999999px;
				padding: 4px;
				position:relative;
				&:after {
					content: "";
					background-color: var(--button-variant-color);
					display: block;
					width: 100%;
					height: 100%;
					border-radius: 99999999px;
				}
				&:active,
				&.active {
					border: 1px solid $brand-black;
				}
				&:disabled{
					
					background-color: var(--button-variant-color);
					border: 4px solid $white;
					&:active{
						border: 0;
					}
					&:after {
						background: linear-gradient(to left top,rgba(255,255,255,0) 0%,rgba(255,255,255,0) 42%,#fff 43%,#fff 46%,#ff5216 47%,#ff5216 53%,#fff 54%,#fff 57%,rgba(255,255,255,0) 58%,rgba(255,255,255,0) 100%);
						background: -moz-linear-gradient(to left top,rgba(255,255,255,0) 0%,rgba(255,255,255,0) 42%,#fff 43%,#fff 46%,#ff5216 47%,#ff5216 53%,#fff 54%,#fff 57%,rgba(255,255,255,0) 58%,rgba(255,255,255,0) 100%);
						background: -ms-linear-gradient(to left top,rgba(255,255,255,0) 0%,rgba(255,255,255,0) 42%,#fff 43%,#fff 46%,#ff5216 47%,#ff5216 53%,#fff 54%,#fff 57%,rgba(255,255,255,0) 58%,rgba(255,255,255,0) 100%);
						background: -o-linear-gradient(to left top,rgba(255,255,255,0) 0%,rgba(255,255,255,0) 42%,#fff 43%,#fff 46%,#ff5216 47%,#ff5216 53%,#fff 54%,#fff 57%,rgba(255,255,255,0) 58%,rgba(255,255,255,0) 100%);
						background: -webkit-gradient(linear,left top,right bottom,color-stop(0%,rgba(255,255,255,0)),color-stop(42%,rgba(255,255,255,0)),color-stop(43%,#fff),color-stop(46%,#fff),color-stop(47%,#ff5216),color-stop(53%,#ff5216),color-stop(54%,#fff),color-stop(57%,#fff),color-stop(58%,rgba(255,255,255,0)),color-stop(100%,rgba(255,255,255,0)));
						background: -webkit-linear-gradient(to left top,rgba(255,255,255,0) 0%,rgba(255,255,255,0) 42%,#fff 43%,#fff 46%,#ff5216 47%,#ff5216 53%,#fff 54%,#fff 57%,rgba(255,255,255,0) 58%,rgba(255,255,255,0) 100%);
						bottom: 0;
						content: '';
						
						left: 0;
						position: absolute;
						right: 0;
						top: 0;
					}
				}
			}

			p {
				display: none;
			}
		}

		&-title {
			@extend h3;
			@include font-rem(32px, 32px);
			letter-spacing: -1.25px;
			margin-bottom: 6px;
			max-width: unset;
			padding-bottom: 0;
			padding-top: 0;
		}

		&-options {
			z-index: 1;

			&-option {
				flex: 0 1 auto;
				margin-right: 32px;
				padding: 0 0 10px;
				text-align: center;

				p {
					@include font-rem(14px, 14px);
					font-weight: 700;
					margin-bottom: 5px;
				}

				&-input {
					.select-wrapper {
						display: inline-block;

						select {
							@include font-rem(13px, 13px);
							font-weight: 700;
						}
					}

					fieldset {
						label {
							cursor: pointer;
							display: inline-flex;
							margin-bottom: 0;
							margin-right: 5px;

							input {
								&[type="radio"] {
									display: none;

									+ span {
										@include font-rem(13px, 13px);
										background-color: $concrete;
										color: $dark-blue;
										display: block;
										padding: 14px;
									}

									&:checked {
										+ span {
											@include font-rem(
												13px,
												13px
											);
											background: $dark-blue;
											color: $white;
											display: block;
											font-weight: 700;
											padding: 14px;
										}
									}

									&:disabled {
										+ span {
											position: relative;

											&::after {
												background: $dark-blue;
												content: "";
												height: 2px;
												left: -5px;
												position: absolute;
												top: 46%;
												transform: rotate(
													-34deg
												);
												width: 114%;
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}

		.bat-quantity {
			margin-bottom: 26px;
			padding-bottom: 0;
			padding-top: 0;
			&-label {
				@extend h5;
				@extend .headline5-small;
				@include font-rem(12px, 15px);
				flex: 0 1 140px;
				letter-spacing: 1px;
			}
			.bat-quantity-button {
				flex: 0 0 32;
				height: 32px;
				min-width: 32px;
			}
		}

		.bat-producthero-deliveryinfo {
			align-items: center;
			background-color: $white;
			display: flex;
			padding: 24px 20px;
			margin-top: auto;
			margin-bottom: 23px;
			&-icon {
				padding-left: 4px;
				padding-right: 14px;
				.bat-icon {
					height: 34px;
					width: 34px;
					&::before {
						font-size: 34px;
					}
				}
			}
			&-text {
				.heading {
					@extend h5;
					@extend .headline5-small;
					@include font-rem(12px, 15px);
					display: block;
					letter-spacing: 1px;
					margin-bottom: 4px;
				}
				p {
					@include font-rem(12px, 14.4px);
					color: $dark-gray;
					letter-spacing: 0.25px;
					margin: 0;
				}
			}
		}

		.bat-producthero-desktop {
			display: none;
			padding-bottom: 0;
			padding-top: 0;
		}

		.bat-producthero-strengths {
			&-group {
				margin-left: 10%;
				margin-right: 10%;
				margin-bottom: 12px;
				padding: 0;
				&-indicators {
					display: flex;
					flex-direction: column;
					> .bat-producthero-strengths-indicator-line:not(:last-child) {
						margin-right: 40px;
					}
				}
				.bat-producthero-strengths-indicator-line {
					margin: 0;
				}
				&-top-bar {
					display: flex;
					flex-direction: row;
					align-items: center;
					margin-bottom: 12px;

					span {
						margin-right: 13px;
						font-family: $primary-font;
						@include font-rem(12px, 115%);
						letter-spacing: 1.2px;
						font-weight: bold;
						width: 14ch;
						flex-shrink: 0;
					}

					hr {
						width: 100%;
						height: 0;
						background-color: $light-gray;
						border: none;
						border-top: 2px solid $light-gray;
						margin-top: 0;
						margin-bottom: 0;
					}
				}
			}
			
			&-indicator-line {
				display: flex;
				flex-direction: row;
				align-items: center;
				flex-wrap: wrap;
				margin-bottom: 25px;
				margin-left: 10%;
				margin-right: 10%;
				padding: 0;
			}

			&-container {
				display: flex;
				flex-wrap: wrap;
				
				.bat-indicator {
					@include variable(--indicator-active-color, $brand-black);
					@include variable(--indicator-active-last-color, $brand-black);
					border: none;
					align-items: baseline;
					flex-direction: row;
					font-family: $primary-font;
					justify-content: center;
					margin: 0;
					padding: 0;
					width: auto;
					$size: 13px;
					ul {
						li {
							width: $size;
							height: $size;
							line-height: $size;
							font-size: $size;
							border-color: $brand-black;
						}
					}

					&.strength-4mg,
					&.strength-mild,
					&.strength-1 {
						li {
							&:nth-child(1) {
								background: var(--indicator-active-color);
							}
						}

						&.active {
							li {
								&:nth-child(1) {
									background: $white;
								}
							}
						}
					}

					&.strength-6mg,
					&.strength-medium,
					&.strength-2 {
						li {
							&:nth-child(-n + 2) {
								background: var(--indicator-active-color);
							}
							&:nth-child(2) {
								background: var(--indicator-active-last-color);
								border: none;
							}
						}

						&.active {
							li {
								&:nth-child(-n + 2) {
									background: $white;
								}
							}
						}
					}

					&.strength-10mg,
					&.strength-strong,
					&.strength-3 {
						li {
							&:nth-child(-n + 3) {
								background: var(--indicator-active-color);
							}
							&:nth-child(3) {
								background: var(--indicator-active-last-color);
								border: none;
							}
						}

						&.active {
							li {
								&:nth-child(-n + 3) {
									background: $white;
								}
							}
						}
					}

					&.strength-16mg,
					&.strength-x-strong,
					&.strength-4 {
						li {
							&:nth-child(-n + 4) {
								background: var(--indicator-active-color);
							}
							&:nth-child(4) {
								background: var(--indicator-active-last-color);
								border: none;
							}
						}

						&.active {
							li {
								&:nth-child(-n + 4) {
									background: $white;
								}
							}
						}
					}

					&.strength-18mg,
					&.strength-ultra,
					&.strength-5 {
						li {
							&:nth-child(-n + 5) {
								background: var(--indicator-active-color);
							}
							&:nth-child(5) {
								background: var(--indicator-active-last-color);
								border: none;
							}
						}

						&.active {
							li {
								&:nth-child(-n + 5) {
									background: $white;
								}
							}
						}
					}

					&.strength-20mg,
					&.strength-max,
					&.strength-6 {
						li {
							&:nth-child(-n + 6) {
								background: var(--indicator-active-color);
							}
							&:nth-child(6) {
								background: var(--indicator-active-color);
								border: none;
							}
						}

						&.active {
							li {
								&:nth-child(-n + 6) {
									background: $white;
								}
							}
						}
					}
					span {
						@include font-rem(12px, 12px);
						color: $brand-black;
						font-weight: 700;
						margin-left: 10px;
						margin-bottom: 0;
					}
				}
			}
			&-label {
				display: inline-flex;
				flex-direction: row;
				align-items: center;
				margin: 0;
				margin-inline-end: 10px;
				font-weight: normal;
				@include font-rem(13px, 185%);
				width: fit-content;
				&--bold {
					font-weight: bold;
				}
				&-popover-button {
					.bat-icon:before {
						@include font-rem(19px, 100%);
					} 
				}
				&-popover-content {
					position: absolute;
					background-color: $charcoal;
					color: $white;
					padding: 15px;
					transform: translateX(50%);
					width: 200px;
					$triangle-size: 10px;
					display: none;
					margin-top: $triangle-size;
					z-index: 999;
					font-family: $primary-font;
					font-weight: normal;
					@include font-rem(10px, 150%);

					&[data-show] {
						display: block;
					}

					&-arrow {
						visibility: hidden;

						&, &::before {
							position: absolute;
							width: $triangle-size;
							height: $triangle-size;
							background: inherit;
						}

						&::before {
							visibility: visible;
							content: '';
							transform: rotate(45deg);
						}
					}

					&[data-popper-placement^='top'] > .bat-producthero-strengths-label-popover-content-arrow {
						bottom: #{-($triangle-size / 2)};
					}
				
					&[data-popper-placement^='bottom'] > .bat-producthero-strengths-label-popover-content-arrow {
						top: #{-($triangle-size / 2)};
					}
			
					&[data-popper-placement^='left'] > .bat-producthero-strengths-label-popover-content-arrow {
						right: #{-($triangle-size / 2)};
					}
			
					&[data-popper-placement^='right'] > .bat-producthero-strengths-label-popover-content-arrow {
						left: #{-($triangle-size / 2)};
					}

					p {
						display: block;
						font-size: inherit;
						line-height: inherit;
						font-family: inherit;
						font-weight: inherit;
					}
				}
			}
		}
	}
}
//-----------------------------------------------------------------| BlogHub, overides to news card
.bat-blog-hub {
	.bat-card--news {
		@include media-breakpoint-down(xs) {
			padding-bottom: 16px;
			padding-top: 30px;
		}
	}
	.bat-card--news-text {
		p {
			&:first-of-type {
				margin-top: 26px;

				@include media-breakpoint-down(xs) {
					margin-top: 16px;
				}
			}
		}
		h3 {
			padding-top: 0;
		}
	}
	.bat-cta-style {
		@include media-breakpoint-down(xs) {
			margin-top: 10px;
		}
	}
}
