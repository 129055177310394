#blog{
	.bat-headline{
		text-align: center;
	}
}

.bat-section {
	
	&-content {
		
		@include media-breakpoint-up(lg) {
			margin: 0 auto;
			max-width: $max-width-xxl;
        }
		
		//AEM author mode 
		.authorEditMode & { // sass-lint:disable-line class-name-format
			display: block;
		}
		
		.responsivegrid {
			width: 100%;
		}
		
		&-style { 
			
			&.left {
				align-items: flex-start;
				display: flex;
				flex-direction: column;
				text-align: left;
			}
			
			&.right {
				align-items: flex-end;
				display: flex;
				flex-direction: column;
				text-align: right;
			}
			
			&.center {
				align-items: center;
				display: flex;
				flex-direction: column;
				text-align: center;
			}
		}
	}
	
	//Waypoint example for viewport in-view event
	.bat-waypoint & {
		opacity: 0;
		transition: opacity .5s ease-in .2s;
	}
	
	.bat-waypoint--in-view & {
		opacity: 1;
	}
}
