.black-friday-hero{
	height: 100%;
	.bat-hero{
		position: relative;
		min-height: 537px;
		height: 100% !important;
		@media (min-width: 2200px) {
			min-height: 660px;
		}

		picture{
			img{
				min-width: 100%;
				height: auto !important;
				@include media-breakpoint-down(md) {
					height: 453px !important;
				}
			}
		}
		padding-bottom: 5%;

		@include media-breakpoint-down(md) {
			height: 100% !important;
			margin-bottom: 0px;
			min-height: 423px;
			padding-bottom: 0px;
		}
		

		.bat-hero-content-container {
			a{
				display: inline-flex;
				height: 47px;
				padding: 12.5px 34px;
				justify-content: center;
				align-items: center;
				gap: 10px;
				flex-shrink: 0;
				background: #FE5000;
				border: solid 2px transparent;
				background-origin: border-box;
				color: black;
				transition: none;
				text-transform: none;
				font-style: normal;
				font-weight: 600;
				line-height: normal;
				font-size: 15px;
				@include media-breakpoint-down(sm) {
					margin-block: 10px;
				}
			} 
			a:hover{
				color:black;
			}
			
		}
	}

	.title-banner-blackfriday{
		font-family: Mont;
		font-size: 62.0px;
		font-style: normal;
		font-weight: 400;
		line-height: 110.0%;
	}
	.subtitle-banner-blackfriday{
		font-family: Mont;
		font-size: 28.0px;
		font-style: normal;
		line-height: 140.0%;
		@include media-breakpoint-down(sm) {
			margin-top: 6px;
		}
		.bold-span-banner-blackfriday{
			text-shadow: 0px 0px 15px rgba(255, 255, 255, 0.95);
			font-family: Mont;
			font-size: 28px;
			font-style: normal;
			font-weight: 600;
			line-height: 140%;
		}
		
	}

	.disclaimer-banner-blackfriday{
		color: #FFF;
		font-family: Mont;
		font-size: 14px !important;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
	}
	
	@include media-breakpoint-down(sm) {
		margin-bottom: 0px !important;
		.subtitle-banner-blackfriday{
			font-size: 16.5px;
		}
		.bold-span-banner-blackfriday{
			font-size: 16.5px !important;
		}

		.title-banner-blackfriday{
			font-size: 32px;
		}

		.disclaimer-banner-blackfriday{
			font-size: 12px !important
		}

		
	}

}

body {
	background-color: #1C1C1C;
	color: #FFFFFF;
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 100vh;
}


bat-card-gloitblackfridayfirstsection{
	padding: 5%;
	@include media-breakpoint-up(md) {
		margin-top: 8%;
	}
}

.gloitblackfriday-first-section-container {
    max-width: 1200px;
    margin-inline: auto;
	
	.info-section {
		padding: 20px;
		background-color: #333333;
		border-radius: 40px;
		height: 498px !important;
		position: relative;

		@include media-breakpoint-down(sm) {
			height: 788px !important;
		}

		&-container{
			display: flex;
			flex-direction: row;
			gap: 0px;
			height: 80%;
			align-content: space-around;

			@include media-breakpoint-down(sm) {
				flex-direction: column;
			}

			h2{
				color: white;
				font-feature-settings: 'liga' off, 'clig' off;
				-webkit-text-stroke-width: 1;
				-webkit-text-stroke-color: #000;
				font-family: Mont;
				font-size: 28px;
				font-style: normal;
				font-weight: 600;
				line-height: 120%;
			}


			p{
				color: #FFF;
                -webkit-text-stroke-width: 1;
                -webkit-text-stroke-color: #000;
                font-family: Mont;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%;
                margin-top: 2%;
			}
		}

		&-leftside{
			margin-top: 6%;
			margin-left: 5%;
			height: 100%;
			display: flex;
			flex-direction: column;
			width: 50%;
			@include media-breakpoint-down(sm) {
				width: 100%;
				p{
					width: 90%;
				}
			}

			img{
				width: 35%;
				@include media-breakpoint-down(sm) {
					width: 50%;
				}
			}
		}

		&-rightside{
			width: 58%;

			@include media-breakpoint-down(sm) {
				width: 100%;

				bat-card-gloitroundedvideo{
					display: block !important;
				}
			}

			.bat-card-gloitroundedvideo{
				width: 550px;
				@include media-breakpoint-down(sm) {
					width: 100%;
					video{
						background: black;
					}
				}
			}
		}
	}

	.text-section h2 {
		font-size: 24px;
		color: #E5E5E5;
		margin-bottom: 10px;
	}

	.text-section p {
		font-size: 16px;
		line-height: 1.5;
		color: #AAAAAA;
	}

	.product-cards {
		display: flex;
    	gap: 20px;
		width: 100%;
		margin-top: -10%;
		position: relative;
		height: 187px;
		@include media-breakpoint-down(sm) {
			height: 700px;
			margin-top: 7%;
			flex-direction: column;
			margin-top: -38%;
			align-items: center;
		}
	}

	.product-card {
		cursor: pointer;
		flex: 1 1 calc(50% - 20px);
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
		width: 334px;
		height: 334px;


		&.hyperpro{
			background-image: url("/content/dam/glo-it/images/black-friday/hyperpro_microcard.svg");
			@include media-breakpoint-down(sm) {
				background-image: url("/content/dam/glo-it/images/black-friday/hyperpro_microcard-mobile.png");
				margin-left:0px;
			}
			background-position: center;
    		background-repeat: no-repeat;
			background-size: contain;
   			margin-left: 3%;
			&-content{
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				width: 100%;
				margin-left: 77%;
				margin-top: 25%;

				@include media-breakpoint-down(sm) {
					display: flex;
					flex-direction: column;
					align-items: flex-start;
					width: 100%;
					margin-left: 0%;
					margin-top: 25%;
					padding-inline: 10%;
				}

				p{
					font-feature-settings: 'liga' off, 'clig' off;
					font-family: Mont;
					font-size: 16px;
					font-style: normal;
					font-weight: 400;
					line-height: 140%;
					color: white;
					text-align: left;
				}

				span{
					font-size: 13px;
				}
			}
		}

		&.hyper{
			background-image: url("/content/dam/glo-it/images/black-friday/hyper_microcard.svg");
			@include media-breakpoint-down(sm) {
				background-image: url("/content/dam/glo-it/images/black-friday/hyper_microcard-mobile.png");
				margin-right:0px;
			}
			background-position: center;
    		background-repeat: no-repeat;
			background-size: contain;
			margin-right: 3%;
			&-content{
				display: flex;
                flex-direction: column;
                align-items: flex-start;
				width: 100%;
				margin-left: 17%;
				margin-top: 25%;

				@include media-breakpoint-down(sm) {
					display: flex;
					flex-direction: column;
					align-items: flex-start;
					width: 100%;
					margin-left: 0%;
					margin-top: 24%;
					padding-inline: 10%;
				}

				p{
					font-feature-settings: 'liga' off, 'clig' off;
					font-family: Mont;
					font-size: 16px;
					font-style: normal;
					font-weight: 400;
					line-height: 140%;
					color: white;
					text-align: left;
				}

				span{
					font-size: 13px;
				}
			}
		}
	}

	.product-card img {
		max-width: 100%;
		border-radius: 8px;
		margin-bottom: 10px;
	}

	.product-card h3 {
		font-size: 18px;
		margin-bottom: 10px;
	}

	.product-card p {
		font-size: 14px;
		color: #CCCCCC;
		margin-bottom: 15px;
	}

	.cta-link {
		font-size: 14px;
		color: #FFFFFF;
		text-decoration: none;
		background-color: #FF0055;
		padding: 10px 20px;
		border-radius: 5px;
		transition: background-color 0.3s;
	}

	.cta-link:hover {
		background-color: #FF3366;
	}
}



bat-productlisting-gloitblackfriday{

	#productListingContainer{
		max-width: 1138px !important;
		.slick-slide{
			width: auto !important;
		}
		
		@include media-breakpoint-down(sm) {
			padding: 6%;
		}

			.arrows-container{
				height: 50px;
				display: flex;
				flex-direction: row;
				justify-content: flex-end;
				width: 50%;
				margin-left: 50%;
				gap: 5%;
				padding-right: 2%;
			}

			.slick-arrow{
				position:relative !important;
				margin-left: -3%;
			}

			.slick-disabled{
				opacity: 0.5;
			}

			.slick-dots{
				margin-left: 2%;
				button{
					height: 12px !important;
    				width: 12px !important;
					background-color: #A5A5A5 !important;
					border: 1px solid #A5A5A5 !important;
				}

				.slick-active{
					button{
						background-color: #FFF !important;
						border: 1px solid #FFF !important;
					}
				  }
				  
			}


	}
	
	.bat-productlistings--glo-brochureware{
		background-color: #232321 !important;

		.bat-productlistings-card.hog-accessory {

			height: 480px !important;
			min-height: 470px !important;
			min-width: 230px !important;
			width: 262px !important;

			border-radius: 30px;
			background: linear-gradient(103deg, #3980BE 19.09%, #30338C 80.91%), #FFF;
			box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.12);

			.bat-productlistings-card-details{
				height: 100% !important;
			}

			@include media-breakpoint-down(sm) {
				height: 470px !important;
				padding: 5px !important;
				min-height: 404px !important;
				min-width: 230px !important;
				width: 230px !important;
				margin-right: 20px !important;
				
				.bat-productlistings-card-details{
					flex-direction: column;
					height: 100% !important;
				}
			}
		}
		.bat-productlistings-hogaccessory-description{
			font-family: Mont;
			font-size: 29px;
			font-style: normal;
			font-weight: 600;
			line-height: 120%;
			color: white;
			margin-bottom: 2%;
			margin-left: 1%;
	
		}
		
	}
	.bat-productlistings-card.hog-accessory{
		border-radius: 30px;
		background: linear-gradient(103deg, #3980BE 19.09%, #30338C 80.91%), #FFF !important;
		box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.12) !important;

		picture{
			img{
				border-radius: 20px;
			}		
		}
		@include media-breakpoint-down(sm) {
			height: 100%;
			border-radius: 30px;
			background: linear-gradient(103deg, #3980BE 19.09%, #30338C 80.91%);
			box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.12);
		}

		.bat-productlistings-card-details-image{

			@include media-breakpoint-down(sm) {

				padding-bottom: 0px !important;
				
				a{
					width: 100% !important;
					padding: 6% !important;
					display: flex !important;
					flex-direction: column !important;
				}

				img{
					width: auto !important;
					height: auto !important;
				}

				picture{
					width: 100% !important;
					height: 100% !important;
					padding: 0px;
					display: flex;
					flex-direction: column;
					align-items: center;
					
				}	
			}
			
			.house-of-gloers{
				border-radius: 0px 0px 20px 20px;
				background: #5482C2;
				color: #FFF;
				text-align: center;
				font-feature-settings: 'liga' off, 'clig' off;
				font-family: Mont;
				font-size: 11px !important;
				font-style: normal;
				font-weight: 600 !important; 
				line-height: normal; 
				text-transform: uppercase;
				height: 38px;
				display: flex;
				align-items: center;
				flex-direction: column;
				justify-content: center;

				@include media-breakpoint-down(sm) {
					position: relative !important;
   					margin-top: -10%;
					padding: 5% !important;
					display: block !important;
				}
			}
		}

		.bat-productlistings-card-details-text{
			color: #FFF;
			font-family: Mont;
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: 110%;

			@include media-breakpoint-down(sm) {
				padding: 6% !important;

				.hog-product-bottom{
					img{
						display: block !important;
						width: auto;
					}
					div:first-child{
						align-items: flex-end !important;
					}
				}
			}
			.hog-product-bottom{
				.button-dark{
					background: white !important;
					color: black !important;
				}
				img{
					width: 25%;

					&.icon-coin{
						max-width: 31px;
					}
				}
			}
		}
		
	}

	.bat-productlistings-cards.desktop{
		@include media-breakpoint-down(sm) {
			display: none;
		}
	}
	
}

.black-friday24-margin-left{
	margin-left: 1.3% !important;
}

.black-friday-sortfilter{
	.bat-plp-button-wrapper{
		display: none !important;
	}
	.filter-section{
		display: none !important;
	}
}