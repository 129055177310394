//Colors
$white: #fff;
$off-white: #f4f4f4;
$black: #000;
$gray: #888;
$outline: #a9a9a9;
$error: #db0032;
$red: #c02b1f;
$blue: #000080;
$blue-light-glo: #5482C2;
$red-10: rgba(192, 43, 31, .1);
$green: #007955;
$green-10: rgba(0, 121, 85, .1);
$yellow: #f9d602;
$yellow-10: rgba(249, 214, 2, .1);
$blue-gradient: linear-gradient(45deg, $blue-light-glo 0%, #353174 99.26%);

//Opacity Light
$light-10: rgba(255, 255, 255, .1);
$light-20: rgba(255, 255, 255, .2);
$light-30: rgba(255, 255, 255, .3);
$light-40: rgba(255, 255, 255, .4);
$light-50: rgba(255, 255, 255, .5);
$light-60: rgba(255, 255, 255, .6);
$light-70: rgba(255, 255, 255, .7);
$light-80: rgba(255, 255, 255, .8);
$light-90: rgba(255, 255, 255, .9);

//Opacity Dark
$dark-10: rgba(0, 0, 0, .1);
$dark-20: rgba(0, 0, 0, .2);
$dark-30: rgba(0, 0, 0, .3);
$dark-40: rgba(0, 0, 0, .4);
$dark-50: rgba(0, 0, 0, .5);
$dark-60: rgba(0, 0, 0, .6);
$dark-70: rgba(0, 0, 0, .7);
$dark-80: rgba(0, 0, 0, .8);
$dark-90: rgba(0, 0, 0, .9);

// Typography
$base-font: 16px;

//Grid
$gutter: $site-gutter-size;

// used with margin-bottom modifier creation in _spacing.scss
$sizes: (
	xlarge: $gutter * 9,
	large: $gutter * 6,
	medium: $gutter * 3,
	small: $gutter,
	xsmall: $gutter / 2,
	zero: 0
);

// Viewport breakpoints
$grid-breakpoints: (
	xxs: 320px,
	xs: 376px,
	sm: 577px,
	md: 769px,
	lg: 1025px,
	xl: 1201px,
	xxl: $site-max-width
);

$breakpoint-xxs: map-get($grid-breakpoints, 'xxs');
$breakpoint-xs: map-get($grid-breakpoints, 'xs');
$breakpoint-sm: map-get($grid-breakpoints, 'sm');
$breakpoint-md: map-get($grid-breakpoints, 'md');
$breakpoint-lg: map-get($grid-breakpoints, 'lg');
$breakpoint-xl: map-get($grid-breakpoints, 'xl');
$breakpoint-xxl: map-get($grid-breakpoints, 'xxl');

//Max-widths
$max-widths: (
	xs: 375px,
	sm: 576px,
	md: 768px,
	lg: 1024px,
	xl: 1200px,
	xxl: $site-max-width
);

$max-width-xs: map-get($max-widths, 'xs');
$max-width-sm: map-get($max-widths, 'sm');
$max-width-md: map-get($max-widths, 'md');
$max-width-lg: map-get($max-widths, 'lg');
$max-width-xl: map-get($max-widths, 'xl');
$max-width-xxl: map-get($max-widths, 'xxl');

//Z-indexs
$mini-cart: 99;
$mobile-menu: 999;
$account: $mobile-menu + 1;
$header-z-index: 9999;
$modal-zindex: 99999;

// Typography
$font-xlg: 24px;
$font-lg: 18px;
$font-md: $base-font; //16px
$font-sm: 14px;
$primary-font: Arial, Helvetica Neue, Helvetica, sans-serif;
