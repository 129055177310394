// File:           _headline.scss
// Description:    Headline Styles

.bat-headline {
	font-family: $heading-font;

	span {
		color: $white;
		display: inline;
		font-size: inherit;
		font-weight: inherit;
		line-height: inherit;
	}

	&-style {
		&.headline1-large-color1,
		&.headline1-color1,
		&.headline2-color1,
		&.headline3-color1,
		&.headline4-color1,
		&.headline5-color1,
		&.headline6-color1 {
			color: $white;

			span {
				color: $white;
			}
		}
		&.headline1-large-color2,
		&.headline1-color2,
		&.headline2-color2,
		&.headline3-color2,
		&.headline4-color2,
		&.headline5-color2,
		&.headline6-color2 {
			color: $white;

			span {
				color: $white;
			}
		}
		&.headline1-large-color3,
		&.headline1-color3,
		&.headline2-color3,
		&.headline3-color3,
		&.headline4-color3,
		&.headline5-color3,
		&.headline6-color3 {
			color: $white;

			span {
				color: $white;
			}
		}
		&.headline1-large-color4,
		&.headline1-color4,
		&.headline2-color4,
		&.headline3-color4,
		&.headline4-color4,
		&.headline5-color4,
		&.headline6-color4 {
			color: $white;

			span {
				color: $white;
			}
		}
		&.headline1-bold{
			font-weight: 800;
		}
		&.headline1-light-bold{
			color: $white;
			font-weight: 800;

			span {
				color: $white;
				
			}
		}
	}
}

// Alignment Classes
bat-headline-default {
	&.m-align-left {
		text-align: left;
	}

	&.m-align-right {
		text-align: right;

		@include media-breakpoint-up(md) {
			text-align: left;
		}
	}

	&.m-align-center {
		text-align: center;

		@include media-breakpoint-up(md) {
			text-align: left;
		}
	}

	&.d-align-left {
		@include media-breakpoint-up(md) {
			text-align: left;
		}
	}

	&.d-align-right {
		@include media-breakpoint-up(md) {
			text-align: right;
		}
	}

	&.d-align-center {
		@include media-breakpoint-up(md) {
			text-align: center;
		}
	}

	&.underLineT{
		h1,h2,h3{
			font-size: 38px;
			line-height: 36px;
			font-weight: 800;
			&::after{
				content: '';
				display: block;
				width: 150px;
				height: 3px;
				background-color: #2a2c2c;
				position: relative;
				top: 15px;	
			}
		}
	}
}

// Headline for News section homepage
.bat-headline-news {
	margin-bottom: 24px;
	@include media-breakpoint-down(xs) {
		margin-bottom: 18px;
		text-align: center;
	}
}
.bat-headline-sub {
	padding-right: 24px;
	text-align: right;
	@include media-breakpoint-down(xs) {
		margin-left: auto;
		margin-right: auto;
		padding-right: 0;
		text-align: center;
	}
}

.text-right {
	padding-right: 24px;
	text-align: right;
}

// Headline for BlogHub interior page
.bat-headline-blog {
	font-size: 40px;
	line-height: 40px;
	margin-top: 30px;
	padding-bottom: 20px;
}
