.maps-container{
    margin: 70px;

    @include media-breakpoint-down(xl){
        display: block;
        max-width: fit-content;
        margin: auto; 
    }

    @include media-breakpoint-down(sm){
        display: block;
        max-width: none;
        margin: 0px;
    }
}

.bat-map-gloit{

    margin-bottom: 20px;

    @include media-breakpoint-down(xl){
        display: block;
        max-width: fit-content;
        margin: auto; 
    }

    @include media-breakpoint-down(sm){  
        max-width: fit-content;
        margin: auto; 
        margin-bottom: 20px;        
    }

    .title{
        margin-bottom: 10px;
        @include font-rem(22px, 34px);

        @include media-breakpoint-down(sm){
            @include font-rem(17px, 34px);          
        }
    }

    .mapouter {
        position: relative;
        text-align: right;
        height: 500px;
        width: 600px;
        
        @include media-breakpoint-down(sm){
            width: auto;
            height: auto;
        }
    }

    .gmap_canvas {
        overflow: hidden;
        background: none !important;
        height: 500px;
        width: 600px;

        @include media-breakpoint-down(sm){
            width: auto;
            height: auto;
        }

        iframe{
            @include media-breakpoint-down(sm){
                width: auto;
                height: auto;
            }
        }
    }
}