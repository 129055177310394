bat-card-gloitlep3{
	.hero{
		background-image: url('/content/dam/glo-it/images/lep/bg_desktop.jpg');
		width: 100%;
		background-size: 100% 100%;
		background-repeat: no-repeat;
		margin: auto;
		display: flex;
		flex-direction: row;
		height: 50vw;
	}
	.hero .bottom-side{
		display: none;
	}
	.hero .left-side{
		display: flex;
		flex-direction: column;
		align-content: center;
		width: 51% !important;
		height: 100%;
		width: 27%;
		flex-shrink: 0;
		padding-left: 50px;
		margin-top: 8%;
	}
	.hero .left-side a span{
		text-wrap: nowrap;
	}
	.hero .left-side a{

			display: flex;
			width: 179px;
			height: 40px;
			flex-shrink: 0;
			border-radius: 100px;
			background: var(--Glo-CTA-Gradient, linear-gradient(268deg, #CD4A04 0.65%, #ED7D30 100%));
			border: none;
			color: white;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			margin-left: 9%;
		
	}
	.hero .left-side img{
		width: 70%;
		margin-bottom: 24px;
	}
	.hero .left-side p{
		color: #FFF;
		font-feature-settings: 'liga' off, 'clig' off;
		font-family: Mont;
		font-size: 20px;
		font-style: normal;
		font-weight: 400;
		line-height: 24px;
		margin-left: 9%;
	}
	.hero .left-side p b{
		color: #FFF;
		font-feature-settings: 'liga' off, 'clig' off;
		font-family: Mont;
		font-size: 20px;
		font-style: normal;
		font-weight: 800;
		line-height: 24px; /* 120% */
	}


	/* Existing styles */
	.hero .right-side {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 0px;
		justify-content: start; 
		padding-right: 5%;
		margin-bottom: 5%;
	}

	.hero .right-side .ecig {
		width: fit-content;
		height: fit-content;
		transform-origin: center; 
		transition: transform 1s; 
		margin-right: -43px;
		margin-left: -60px;
	}
	.hero .right-side .ecig img{
		max-width: 100%;
		height: auto;
		box-sizing: border-box;
		rotate: 3deg;
	}

	@media screen and (max-width: 780px){

		.hero{
			background-image: url('/content/dam/glo-it/images/lep/bg_mobile.jpg');
			display: flex;
			flex-direction: column;
			height: 77vh;
		}
		.hero .left-side{
			width: 100% !important;
			display: flex;
			height: 34%;
			padding: 0;
			margin-top: 0;
		}

		.hero .left-side a{
			display: none;
		}
		.hero .left-side p{
			display: none;
		}
		.hero .left-side img{
			align-self: center;
			width: 100%;
		}
		.hero .right-side{
			padding: 0% 6%;
		}
		.hero .right-side .ecig{
			margin-left: -20px;
			margin-right: -20px;
		}
		.hero .bottom-side{
			display: flex;
			flex-direction: column;
			text-align: center;
			margin-top: 15%;
		}
		.hero .bottom-side p{
			color: #FFF;
			font-feature-settings: 'liga' off, 'clig' off;
			font-family: Mont;
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: 19px;
		}
		.hero .bottom-side p b{
			color: #FFF;
			font-feature-settings: 'liga' off, 'clig' off;
			font-family: Mont;
			font-size: 14px;
			font-style: normal;
			font-weight: 800;
			line-height: 19px; /* 120% */
		}
		.hero .bottom-side a{

			display: flex;
			width: 179px;
			height: 40px;
			flex-shrink: 0;
			border-radius: 100px;
			background: var(--Glo-CTA-Gradient, linear-gradient(268deg, #CD4A04 0.65%, #ED7D30 100%));
			border: none;
			color: white;
			align-items: center;
			justify-content: center;
			align-self: center;
			cursor: pointer;
		
		}
		.hero .bottom-side a span{
			text-wrap: nowrap;
		}
		.hero .right-side .ecig img{
			max-width: 100%;
			height: auto;
			box-sizing: border-box;
			rotate: -3deg;
		}
	}
	@media screen and (max-width: 375px){
		.hero .bottom-side{
			display: flex;
			flex-direction: column;
			text-align: center;
			margin-top: 15%;
		}
		.hero .right-side .ecig img{
			max-width: 85%;
			height: auto;
			box-sizing: border-box;
			rotate: 3deg;
		}
	}

}