.bat-modal {
	align-items: flex-start;
	background-color: $dark-gray-50;
	height: 100%;
	overflow-y: auto;
	z-index: $header-z-index + 100;
	position: absolute;

	@include media-breakpoint-up(lg) {
		height: 100%;
	}

	.authorEditMode &.no-edit {
		display: none;
	}

	&--newsletter &-content {
		@include media-breakpoint-down(md) {
			padding-left: 20px;
			padding-right: 20px;
		}
	}

	&-content {
		max-height: none;
		overflow: hidden;
		position: relative;
		top: 30px;

		//AEM author mode
		.authorEditMode & {
			// sass-lint:disable-line class-name-format
			left: 0;
			margin: auto;
			right: 0;
			top: 0;
		}

		&.left {
			align-items: flex-start;
			display: flex;
			flex-direction: column;
			text-align: left;
		}

		&.right {
			align-items: flex-end;
			display: flex;
			flex-direction: column;
			text-align: right;
		}

		&.center {
			align-items: center;
			display: flex;
			flex-direction: column;
			text-align: center;
		}

		&-video {
			max-height: calc(79vh - 100px);
		}
	}

	&-close {
		border: 0;
		right: 10px;
		top: 10px;
		width: 34px;
		z-index: 99999;

		// Magento remote navigation overrides
		&:hover {
			border: 0;
		}

		i {
			color: $white;
			font-size: 12px;

			&:before {
				color: $dark-blue;
				content: "\e930";
				font-family: "icomoon";
				font-size: 34px;
				font-weight: 400;
			}
		}
	}
}
