footer {
	&.bat-footer {
		// main content
		&-main {
			align-items: flex-start;
			background-color: $dark-blue;
			color: $white;
			justify-content: flex-start;
			margin-left: auto;
			margin-right: auto;
			max-height: 396px;
			max-width: $max-width-xxl;
			overflow: hidden;
			padding-bottom: 60px;
			position: relative;

			@include media-breakpoint-up(lg) {
				padding-bottom: 0;
			}
		}

		&-copyright {
			background-color: $extra-dark-blue;
			color: $white;
			justify-content: center;
			max-width: none;

			p {
				margin: 0;
			}
		}
	}
}
.bat-footer {
	background-color: $dark-blue;

	footer {
		padding: 40px 20px 154px;

		@include media-breakpoint-up(lg) {
			padding: 42px 0 40px;
		}

		&.bat-footer-copyright {
			padding: 16px 0;
		}
	}

	&-logo {
		bottom: 45px;
		left: 0;
		margin: 0 auto;
		max-width: 65px;
		padding: 0;
		position: absolute;
		right: 0;
		z-index: 1;

		@include media-breakpoint-up(lg) {
			left: 20px;
			margin: 0;
			top: 45px;
		}
	}

	&-nav {
		flex: none;
		flex-direction: column;
		flex-wrap: wrap;
		justify-content: flex-start;
		order: 0;
		padding: 0;
		position: relative;
		width: 100%;
		z-index: 1;

		@include media-breakpoint-up(sm) {
			flex-direction: row;
		}

		@include media-breakpoint-up(lg) {
			margin-left: 105px;
			padding: 0 0 0 20px;
			width: 63%;
		}

		@include media-breakpoint-up(xxl) {
			width: 72%;
		}

		@include media-breakpoint-down(xxs) {
			flex-direction: column;
		}

		&-menu {
			flex-wrap: wrap;
			width: 100%;

			@include media-breakpoint-up(sm) {
				min-width: 282px;
				padding-right: 45px;
				width: 33.3%;
			}

			@include media-breakpoint-up(lg) {
				padding-right: 83px;
			}

			&-title {
				color: $white;
				display: flex;
				font-family: $primary-font;
				font-size: $font-xlg;
				font-weight: 800;
				margin-bottom: 15px;
				padding: 10px $gutter 5px 0;
				position: relative;
				text-align: left;

				&:after {
					background-color: $gray;
					bottom: 0;
					content: "";
					height: 1.5px;
					left: 0;
					position: absolute;
					width: 100%;
				}
			}
		}

		ul {
			margin-bottom: 30px;

			@include media-breakpoint-up(lg) {
				margin-bottom: 8px;
			}

			&:first-child {
				font-weight: 700;
			}

			li {
				display: block;
				list-style-type: none;
				margin-bottom: 15px;
				text-align: left;

				a,
				.no-link {
					@include font-rem($font-sm, $font-md);

					color: $white;
					font-family: $primary-font;
					font-weight: 400;
					padding: 0;
					text-transform: capitalize;
					transition: color 0.1s ease-in;

					&:hover {
						color: $teal-light;
						text-decoration: underline;
					}
				}
			}
		}
	}

	&-social-nav {
		padding: 0;
		position: relative;
		z-index: 1;

		ul {
			flex-wrap: wrap;
			justify-content: flex-start;

			li {
				&:first-child {
					margin-bottom: 15px;
					padding: 10px 7px 5px 0;
					position: relative;
					width: 100%;

					span {
						display: block;
						font-size: $font-xlg;
						font-weight: 800;
						line-height: $font-xlg;
						text-transform: uppercase;
					}

					i {
						display: none;
					}

					&:after {
						background-color: $gray;
						bottom: 0;
						content: "";
						height: 1.5px;
						left: 0;
						position: absolute;
						width: 100%;
					}
				}

				a {
					font-family: $primary-font;
					padding: 0 5px;

					i {
						font-size: 30px;
						height: 40px;
						width: 22px;

						&:before {
							color: $white;
							font-size: 22px;
							transition: color 0.1s ease-in;
						}

						@include media-breakpoint-up(lg) {
							height: 20px;
							width: 40px;

							&:before {
								font-size: 30px;
							}
						}
					}

					@include media-breakpoint-up(lg) {
						&:hover {
							i {
								&:before {
									color: $teal-light;
								}
							}
						}
					}
				}
			}
		}

		// social-navtitle
		&title {
			display: none;
		}
	}

	&-fixed-spacer {
		height: $warning-height-mobile;

		@include media-breakpoint-up(md) {
			height: $warning-height;
		}
	}

	&-disclaimers {
		background-color: $extra-dark-blue;
		padding: 17px 20px;

		// @include media-breakpoint-up(lg) {
		// 	padding: 17px 20px 17px;
		// }

		p {
			color: $white;
			margin: 0 auto;
			max-width: $max-width-xxl;
			@include font-rem(12px, 16px);
		}
	}
}

#unyco_minified_chat {
	z-index: 9999 !important;
}
