// sass-lint:disable no-important
.bat-header {
	background-color: $brand-black;
	font-size: 16px;

	header {
		height: $header-height-mobile;
		padding: 0 0 0 20px;
		position: relative;
		z-index: $header-z-index - 2;

		@include media-breakpoint-up(lg) {
			height: auto;
			margin: 0 auto;
			max-width: $max-width-xl;
			padding: 0 20px;
		}
	}

	&-logo{
		display: flex;
		flex: 0 0 94px;
	}

	&-also-visit {
		margin-right: auto;

		.also-visit-label{
			span{
				padding: 0px 15px;
				font-size: 10px;
				font-family: $primary-font;
				color: $gray;
			}	
		}

		.also-visit-logo-vype{
			a{
				padding-top: 5px
			}	
		}
	}

	&-search {
		flex: 0;
		margin: 0 auto;
		padding: 20px 20px 0;

		@include media-breakpoint-up(lg) {
			background-color: transparent;
			flex: 0 0 auto;
			max-width: 28px;
			padding: 27px 10px 34px;
			position: absolute;
			right: 2px;
			transition: all 0.1s ease-in;
			z-index: 1;

			form {
				input {
					visibility: hidden;
				}
			}

			&:focus-within,
			&:hover {
				background-color: $white;
				max-width: 206px;

				form {
					input {
						background-color: $white;
						border-bottom: solid 1px $charcoal;
						color: $charcoal;
						visibility: visible;

						&:-webkit-autofill {
							-webkit-box-shadow: 0 0 0 30px $white inset !important;
							-webkit-text-fill-color: $charcoal !important;
							box-shadow: 0 0 0 30px $white inset !important;

							&:active,
							&:focus,
							&:hover {
								-webkit-text-fill-color: $charcoal !important;
								box-shadow: 0 0 0 30px $white inset !important;
							}
						}

						&::-webkit-input-placeholder {
							color: $charcoal;
						}

						&:-moz-placeholder {
							/* FF 4-18 */
							color: $charcoal;
						}

						&::-moz-placeholder {
							/* FF 19+ */
							color: $charcoal;
						}

						&:-ms-input-placeholder {
							/* IE 10+ */
							color: $charcoal;
						}

						&::-ms-input-placeholder {
							/* Microsoft Edge */
							color: $charcoal;
						}

						&::placeholder {
							/* modern browser */
							color: $charcoal;
						}
					}

					.bat-header-search-button {
						i {
							&:before {
								color: $charcoal;
							}
						}

						span {
							display: none;
						}
					}
				}
			}
		}

		form {
			input {
				background-color: $charcoal;
				border-bottom: solid 1px $white;
				color: $white;
				font-size: 12px;
				line-height: 12px;
				padding: 7px 28px 7px 0;
				transition: all 0.1s ease-in;

				&:-webkit-autofill {
					-webkit-box-shadow: 0 0 0 30px $charcoal inset !important;
					-webkit-text-fill-color: $white !important;
					box-shadow: 0 0 0 30px $charcoal inset !important;

					&:active,
					&:focus,
					&:hover {
						-webkit-box-shadow: 0 0 0 30px $charcoal inset !important;
						-webkit-text-fill-color: $white !important;
						box-shadow: 0 0 0 30px $charcoal inset !important;
					}
				}

				&::-webkit-input-placeholder {
					color: $concrete;
				}

				&:-moz-placeholder {
					/* FF 4-18 */
					color: $concrete;
				}

				&::-moz-placeholder {
					/* FF 19+ */
					color: $concrete;
				}

				&:-ms-input-placeholder {
					/* IE 10+ */
					color: $concrete;
				}

				&::-ms-input-placeholder {
					/* Microsoft Edge */
					color: $concrete;
				}

				&::placeholder {
					/* modern browser */
					color: $concrete;
				}

				&:focus {
					border-bottom: solid 1px $light-blue;
				}

				@include media-breakpoint-up(lg) {
					border-bottom: solid 1px transparent;
				}
			}
		}

		&-button {
			height: 28px;
			padding: 0;
			right: 0;
			top: -1px;
			width: 28px;

			i {
				&:before {
					color: $white;
					font-size: $icon-size;
					transition: all 0.1s ease-in;
				}
			}

			@include media-breakpoint-up(lg) {
				span {
					color: $white;
					display: block;
					font-size: 10px;
					line-height: 10px;
				}

				&:hover {
					i {
						&:before {
							color: $light-blue;
						}
					}
				}
			}
		}
	}

	&-support,
	&-stores,
	&-cart,
	&-account {
		height: auto;
		order: unset;

		@include media-breakpoint-up(sm) {
			padding: 14px;
		}

		a,
		button {
			display: flex;
			flex-direction: column;

			&:hover {
				border: 0;
			}

			i {
				height: 28px;
				width: 28px;

				&:before {
					color: $white;
					font-size: $icon-size;
					transition: background-color 0.1s ease-in;
				}
			}

			span {
				color: $white;
				font-size: 10px;
				line-height: 10px;
			}

			@include media-breakpoint-up(lg) {
				&:hover {
					i {
						&:before {
							color: $white;
						}
					}

					span {
						color: $white;
						text-decoration: none;
					}
				}
			}
		}
	}

	&-account {
		&-menu {
			background-color: $concrete;

			a {
				span {
					color: $white;
				}

				&:hover {
					span {
						color: $white;
					}
				}
			}
		}

		&.open {
			a {
				i {
					&:before {
						color: $white;
					}
				}
			}
		}
	}

	&-cart {
		// sass-lint:disable class-name-format
		&.cartEmpty {
			button {
				i {
					&:before {
						.bat {
							&:not(bat-header-limited) & {
								content: "\e91a";
							}
						}
					}
				}
			}
		}

		&-label {
			font-size: 10px;
			font-weight: normal;
			letter-spacing: 0;
			line-height: 10px;
			margin: 0 auto;
			text-transform: capitalize;
		}

		&-count {
			align-items: center;
			background-color: transparent;
			border: solid 2px transparent;
			border-radius: 25px;
			color: $white;
			display: flex;
			font-size: 10px;
			font-weight: 700;
			justify-content: center;
			line-height: 10px;
			margin: auto;
			opacity: 0;
			transform: rotate(0deg);
			transition: all 0.2s ease-in;

			&.loading {
				animation: spin 2s linear infinite;
				border-bottom: 2px solid $light-blue;
				border-top: 2px solid $light-blue;
				visibility: visible;

				&.small,
				&.medium &.large {
					span {
						opacity: 0;
					}
				}

				& ~ span {
					visibility: hidden;
				}
			}

			@keyframes spin {
				0% {
					transform: rotate(0deg);
				}
				100% {
					transform: rotate(360deg);
				}
			}

			&.small,
			&.medium,
			&.large {
				bottom: 8px;
				height: 15px;
				left: 0;
				margin: auto;
				position: absolute;
				right: 0;
				width: 15px;

				span {
					opacity: 1;
					transition: opacity 0.2s ease-in;
				}
			}

			&.active {
				opacity: 1;
			}
		}

		span {
			font-size: 10px;
			line-height: 10px;
		}
	}

	&-social-nav {
		ul {
			li {
				color: $white;
			}
		}
	}

	&-menu {
		background-color: $charcoal;
		height: 0;
		//transition: height .2s ease;

		@include media-breakpoint-up(lg) {
			height: auto;
			position: absolute;
			top: auto;
		}

		&.open {
			height: auto;
			overflow-y: auto;
			top: 70px !important;
			max-height: calc(100vh - #{$warning-height-mobile * 2});

			@include media-breakpoint-up(lg) {
				height: auto;
				overflow: hidden;
			}
		}
		&-button {
			padding-right: 20px;
		}

		.uk-search{
			border-bottom: 1px solid $gray;
			margin-right: 5px;
			position: relative;

			&-input {
				font-size: 10px;
				background-color: $brand-black;
				height: 35px;
				padding-left: 0;
			}
		}
	}

	&-desktop-nav {
		background-color: $charcoal;
		display: flex;
		flex: 0;

		@include media-breakpoint-down(xl){
			display: none;
		}

		padding: 20px 0;
		width: 100%;

		.bat-navigation {
			margin: 0 auto;
			// max-width: 500px;
			&-group-list-item--velo-mobile {
				@include media-breakpoint-up(lg) {
					display: none;
				}
				a {
					i {
						margin-left: 0;
						margin-right: 2em;
						transform: scale(2);
						transform-origin: left center;
						&:before {
							font-size: 1em;
						}
					}
				}
			}
		}

		@include media-breakpoint-up(lg) {
			flex: 1 1 auto;
			justify-content: flex-start;
			padding: 0 20px;

			.bat-navigation {
				margin: 0 auto;
				max-width: $max-width-xl;
				width: 100%;
			}
		}
	}

	&-nav {
		display: none;
		@media (max-width: 1024px) {
			display: block;
		}
		flex: 0;
		padding: 20px 0;

		.bat-navigation {
			margin: 0 auto;
			max-width: 500px;
			&-group-list-item--velo-mobile {
				@include media-breakpoint-up(lg) {
					display: none;
				}
				a {
					i {
						margin-left: 0;
						margin-right: 2em;
						transform: scale(2);
						transform-origin: left center;
						&:before {
							font-size: 1em;
						}
					}
				}
			}
		}

		@include media-breakpoint-up(lg) {
			flex: 1 1 auto;
			justify-content: flex-start;
			padding: 0 20px;

			.bat-navigation {
				margin: 0 auto;
				max-width: 700px;
				width: 94%;
			}
		}
	}

	&-menu-button {
		color: $white;
		// padding: 20px 12px;
		order: 0;
		position: relative;

		button {
			border: 0;
			height: auto;
			width: auto;

			i {
				&:before {
					color: $white;
					font-size: $icon-size;
				}
			}
		}

		&.active {
			background-color: $brand-black;

			button {
				i {
					&:before {
						color: $white;
						font-size: 28px;
					}
				}
			}
		}

		&-open-label {
			color: $white;
			margin-top: 0;
		}

		&-close-label {
			margin-top: 0;
		}

		&-close-label,
		&-open-label {
			@include font-rem(11px, 11px);
		}
	}

	&-fixed-spacer {
		height: auto;
	}

	&-content {
		background-color: $white;

		.bat-text {
			background-color: #e5e5e5;
			bottom: 0;
			display: flex;
			flex-wrap: wrap;
			height: unset;
			min-height: $warning-height-mobile;
			justify-content: flex-start;
			left: 0;
			margin: 0 auto;
			max-width: none;
			position: fixed;
			right: 0;
			top: auto;
			z-index: $header-z-index;

			@include media-breakpoint-up(md) {
				min-height: $warning-height;
			}

			p {
				margin: 0;

				&:nth-child(1) {
					align-items: center;
					background-color: #e5e5e5;
					color: $black;
					display: flex;
					font-family: $google-lato;
					font-size: 11px;
					font-weight: 700;
					height: unset;
					min-height: $warning-height-mobile;
					justify-content: center;
					line-height: 14px;
					padding: 0 4%;
					text-align: center;
					width: 100%;

					@include media-breakpoint-up(md) {
						min-height: $warning-height;
						font-size: 15px;
						line-height: 19.5px;
					}
				}

				&:nth-child(2),
				&:nth-child(3) {
					border-top: solid 1px $black;
					color: $black;
					font-family: $open-sans;
					font-size: 10px;
					height: $warning-footer-height;
					letter-spacing: 1px;
					line-height: 10px;
					padding: 8px 10px 0;
					text-align: left;
					width: 50%;

					@include media-breakpoint-up(xs) {
						font-size: 11px;
						line-height: 11px;
					}

					br {
						@include media-breakpoint-up(lg) {
							content: " ";
							display: none;
						}
					}
				}

				&:nth-child(3) {
					margin-left: auto;
					text-align: right;
				}
			}
		}
	}

	.second-section {
		background-color: $charcoal;

		.second-navbar {
			.uk {
				&-navbar {
					&-center {
						background-color: $charcoal;
					}

					&-container {
						background-color: $charcoal;
					}
				}
				&-dropdown {
					left: 0;
				}
				&-grid {
					margin-left: 0;
				}
				&-first-column {
					padding-left: 0;
				}
			}

			li {
				&:not(:last-child, :nth-child(5n)) {
					width: 80%;
				}

				&:last-child {
					width: 50%;
				}

				&:nth-child(5n) {
					width: 120%;
				}
			}

			a {
				padding: 0;

				&:hover {
					color: $orange;
				}

				&:active {
					color: $orange;
				}

				min-height: 60px;
				min-width: 100%;
				font-size: 12px;
				color: $white;
				font-weight: 800;
				@include media-breakpoint-up(md) {
					font-size: 14px;
				}
			}
		}

		.uk {
			&-container {
				max-width: 1400px;
			}

			&-dropdown {
				margin: 0;
				padding: 0;
			}
		}

		.description-text {
			max-width: 135px;

			&-caption {
				margin-top: 0;
				font-size: 13px;
				line-height: 18px;
				font-weight: 500;
			}

			&-title {
				margin-bottom: 10px;
				max-width: 50px;
				font-size: 18px;
				line-height: 20px;
				font-weight: 700;
			}
		}

		.left-section {
			padding: 40px 15px 15px 15px;
			@include media-breakpoint-up(md) {
				padding: 60px 30px 30px 30px;
			}
		}

		
		.container-slider {
			padding: 10px;
			background-color: $charcoal;

			.slider-menu {
				.container-button {
					border: 0.5px solid $white;
					line-height: 26px;
					min-width: 13px;
					border-radius: 13px;
					padding-top: 5px;
					padding-bottom: 5px;

					.button-text-size {
						font-size: 10px;
						font-weight: 800;
						line-height: 15px;
						text-align: center;
					}
				}
			}

			.uk-grid > * {
				&:not(:first-child) {
					padding-left: 5px;
				}
				@include media-breakpoint-up(md) {
					&:not(:first-child) {
						padding-left: 20px;
					}
				}
				@include media-breakpoint-up(md) {
					&:not(:first-child) {
						padding-left: 25px;
					}
				}
			}

			.slidenav-previous{
				background: linear-gradient(90deg,#3a3a3a,#3a3a3a,#3a3a3a, transparent);
			}
			.slidenav-next{
				background: linear-gradient(90deg, transparent,#3a3a3a,#3a3a3a,#3a3a3a);
			}
		}
	}

	.third-section{
		.uk-container{
			max-width: 100%;
		}
	}
	&-message-bar {
		background-color: $banner-background;
		color: $white;
		padding: 5px 20px;
		width: 100%;
		
		@include media-breakpoint-up(xl){
			&:first-child{
				justify-content: flex-end;
			}
	
			&:last-child{
				justify-content: flex-start;
			}
		}
		
		&-message {
			align-content: center;
			align-items: center;
			display: flex;
			justify-content: center;

			i {
				margin-right: 10px;
			}

			span {
				p {
					@include font-rem(12px, 15px);
					margin-bottom: 0;
				}
			}
		}
	}
	&--velo-desktop {
		@include media-breakpoint-down(md) {
			display: none;
		}
	}
	[class*="flag-circle"] {
		svg {
			border-radius: 50%;
			transform: scale(0.8);
		}
	}
}

// Compact Header
bat-header-limited {
	.bat-header {
		header {
			height: 66px;
			padding: 0 20px;
			@include media-breakpoint-up(lg) {
				height: 66px;
			}
		}

		&-logo {
			flex: 0 0 106px;
			margin-inline: auto;
			@include media-breakpoint-up(lg) {
				flex: 0 0 85px;
			}
			picture img {
				height: 100%;
				margin: 0 auto;
				width: auto;
			}
		}

		&-cart,
		&-back {
			align-items: center;
			height: auto;
			padding: 0;
			@include media-breakpoint-up(sm) {
				padding: 0;
			}
			button {
				color: $white;
				display: flex;
				height: 32px;
				width: 32px;

				&:active {
					color: $light-blue;
				}

				.bat-icon {
					height: 32px;
					width: 32px;

					&:before {
						font-size: 32px;
					}
					&:hover {
						border: 0;

						&:before {
							color: $light-blue;
						}
					}
				}

				&:hover {
					border: 0;
					color: $light-blue;

					&:disabled {
						color: $white; // no hover on lock icon
						cursor: default;

						.bat-icon {
							&:before {
								color: $white;
							}
						}
					}
				}
			}
		}
	}
}

bat-header-glo.safari-fix .bat-header-menu.open {
	max-height: calc(100vh - #{$warning-height-mobile * 2.5});
}
