footer {
	&.bat-footer-limited {
		// main content
		&-main {
			background-color: $dark-blue;
			background-position: top left;
			background-repeat: no-repeat;
			color: $white;
			padding: 40px 20px 154px;
			position: relative;

			@include media-breakpoint-down(md) {
				padding: 110px 20px 25px;
			}

			@include media-breakpoint-down(sm) {
				overflow: hidden;
			}

			@include media-breakpoint-up(xxs) {
				background-position: -480px 95%;
			}

			@include media-breakpoint-up(md) {
				background-position: -335px 95%;
			}

			@include media-breakpoint-up(lg) {
				background-position: -230px 0%;
				max-height: 396px;
				padding: 42px 0 40px;
			}

			@include media-breakpoint-up(xl) {
				background-position: top left;
			}
		}
	}
}

.bat-footer-limited {
	background-color: $extra-dark-blue;

	&--wrapper {
		align-items: flex-end;
		display: flex;
		flex-direction: column;
		margin: 0 auto;
		max-width: 1440px;

		@include media-breakpoint-down(md) {
			align-items: center;
		}
	}

	&-header {
		margin-bottom: 15px;

		@include media-breakpoint-down(md) {
			text-align: center;
		}

		@include media-breakpoint-up(md) {
			width: 40%;
		}

		span {
			color: $light-blue;

			@include media-breakpoint-down(md) {
				display: block;
			}
		}
	}

	&-nav {
		align-content: flex-end;
		display: flex;
		flex: none;
		flex-direction: column;
		flex-wrap: wrap;
		padding: 0;
		position: relative;
		width: 100%;
		z-index: 1;

		@include media-breakpoint-down(md) {
			align-content: center;
			justify-content: center;
		}

		// .bat-footer-limited-nav-menu
		&-menu {
			flex-wrap: wrap;
			padding-bottom: 0.5%;
			width: 40%;

			@include media-breakpoint-down(sm) {
				min-width: unset;
				width: 100%;
			}

			@include media-breakpoint-up(md) {
				width: 40%;
			}

			a,
			.no-link {
				color: $white;
				font-family: $primary-font;
				font-size: 12px;
				font-weight: 400;
				line-height: 15px;
				padding: 0;
			}
			.opening-hours {
				font-weight: 700;
				margin-top: 10px;

				i {
					display: inline-block;
					margin-left: 10px;
				}
			}
			a {
				&:hover {
					color: $teal-light;
					text-decoration: underline;
				}
			}

			.submenu {
				display: none;
				position: relative;

				@include media-breakpoint-down(md) {
					display: block;
				}

				ul {
					padding-left: 0;
					position: absolute;
					top: 5px;

					@include media-breakpoint-down(md) {
						position: relative;
					}
				}

				li {
					list-style-type: none;

					@include media-breakpoint-down(md) {
						text-align: center;
					}

					a,
					.no-link {
						color: $white;
						font-family: $primary-font;
						font-size: 12px;
						font-weight: 400;
						line-height: 15px;
						padding: 0;
					}
					a {
						&:hover {
							color: $teal-light;
							text-decoration: underline;
						}
					}
				}
			}

			// .bat-footer-limited-nav-menu-title
			&-title {
				align-items: center;
				display: flex;

				@include media-breakpoint-down(md) {
					justify-content: center;
				}

				a {
					color: $white;
					font-family: $primary-font;
					font-size: 12px;
					font-weight: 400;
					line-height: 15px;
					padding: 0;

					&:hover {
						color: $teal-light;
						text-decoration: underline;
					}
				}

				i {
					&:before {
						font-size: 15px;
						line-height: 15px;
					}
				}
			}
		}
	}

	&-fixed-spacer {
		height: $warning-height-mobile;

		@include media-breakpoint-up(md) {
			height: $warning-height;
		}
	}

	&-txt {
		@include media-breakpoint-down(md) {
			margin-top: 3%;
			padding: 0 20px;
		}

		@include media-breakpoint-down(sm) {
			margin-top: 10%;
		}

		@include media-breakpoint-down(xxs) {
			margin-top: 8%;
		}

		@include media-breakpoint-up(lg) {
			margin-top: 2.5%;
			padding: 0 20px;
		}

		p {
			color: $white;
			font-size: 12px;
			line-height: 16px;
			margin: 0 auto 15px;
			max-width: $max-width-xxl;
		}
	}
}
